import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ArrowLeft, ArrowRight, Clock, FileText } from 'lucide-react';
import { FilledButton, SoftButton, DropdownInput } from 'core';
import Quote from '../../../models/Quote';
import CoreEntityType from '../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import Company from '../../../models/Company';
import { UserProfileContext } from '../../../App';
import QuoteStep1 from './create/QuoteStep1';
import QuoteStep2 from './create/QuoteStep2';
import QuoteStep3 from './create/QuoteStep3';

const CreateQuote = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const companyId = searchParams.get('company');
  const { userProfile } = useContext(UserProfileContext);

  const [currentStep, setCurrentStep] = useState(0);
  const [quoteData, setQuoteData] = useState({
    company: null,
    courseName: '',
    owner: userProfile,
    status: { id: 187 }, // Default status
    delegates: '',
    locations: '',
    datePrefs: '',
    expLevel: null,
    furtherInfo: '',
    deliveryMethods: [],
    accreditations: [],
    bookers: [],
    requestedDate: null,
    expiryDays: null,
    fullProposal: null
  });

  const [options, setOptions] = useState({
    company: [],
    expLevel: [],
    deliveryMethods: [],
    accreditations: [],
    expiryDays: [
      { value: 7, label: '7 Days' },
      { value: 14, label: '14 Days' },
      { value: 30, label: '30 Days' }
    ]
  });

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [{ data: expLevels }, { data: deliveryTypes }] =
          await Promise.all([
            CoreEntityType.getAll({
              entity_id: {
                operator: 'eq',
                value: EntityTypeEnum.QuoteExperienceLevel
              }
            }),
            CoreEntityType.getAll({
              entity_id: {
                operator: 'eq',
                value: EntityTypeEnum.CourseDelivery
              }
            })
          ]);

        setOptions(prevOptions => ({
          ...prevOptions,
          expLevel: expLevels.map(level => ({
            value: level.id,
            label: level.type
          })),
          deliveryMethods: deliveryTypes.map(type => ({
            value: type.id,
            label: type.type,
            order: type.metadata.order
          }))
        }));

        if (companyId) {
          const company = await Company.getById(companyId);
          setQuoteData(prev => ({
            ...prev,
            company: { value: company.id, label: company.name }
          }));
        }
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, [companyId]);

  const handleInputChange = useCallback((field, value) => {
    setQuoteData(prev => ({ ...prev, [field]: value }));
  }, []);

  const handleSubmit = async (navigateToQuote = false) => {
    try {
      const expiryDate = new Date(Date.now());
      expiryDate.setDate(expiryDate.getDate() + Number(quoteData.expiryDays));

      const newQuote = new Quote({
        company: { id: quoteData.company?.value },
        courseName: quoteData.courseName,
        owner: userProfile,
        status: quoteData.status,
        delegates: parseInt(quoteData.delegates),
        locations: quoteData.locations,
        datePrefs: quoteData.datePrefs,
        expLevel: quoteData.expLevel ? { id: quoteData.expLevel } : null,
        furtherInfo: quoteData.furtherInfo,
        deliveryMethods: quoteData.deliveryMethods.map(method => method.value),
        accreditations: quoteData.accreditations.map(
          accreditation => accreditation.value
        ),
        bookers: quoteData.bookers.map(booker => booker.value),
        requestedDate: quoteData.requestedDate,
        expiryDate: expiryDate.toISOString(),
        fullProposal: quoteData.fullProposal
      });

      const insertedQuote = await newQuote.insert();

      if (navigateToQuote) {
        navigate(`/quotes/${insertedQuote.id}`);
      } else {
        navigate(companyId ? `/companies/${companyId}/quotes` : '/quotes');
      }
    } catch (error) {
      console.error('Error creating quote:', error);
    }
  };

  const steps = [
    {
      title: 'Quote Type',
      render: () => (
        <div className='space-y-6'>
          <h3 className='text-lg font-semibold'>Select Quote Type</h3>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <button
              onClick={() => handleInputChange('fullProposal', false)}
              className={`p-6 rounded-lg border-2 transition-all ${
                quoteData.fullProposal === false
                  ? 'border-brand-600 bg-brand-50'
                  : 'border-gray-200 hover:border-brand-300'
              }`}
            >
              <div className='flex flex-col items-center text-center space-y-3'>
                <div className='w-12 h-12 flex items-center justify-center rounded-full bg-brand-100'>
                  <Clock className='w-6 h-6 text-brand-600' />
                </div>
                <h4 className='text-lg font-medium'>Price Check</h4>
                <p className='text-sm text-gray-600'>
                  A quick price estimate for specific courses
                </p>
                <span className='text-sm font-medium text-brand-600'>
                  ~15 minutes
                </span>
              </div>
            </button>

            <button
              onClick={() => handleInputChange('fullProposal', true)}
              className={`p-6 rounded-lg border-2 transition-all ${
                quoteData.fullProposal === true
                  ? 'border-brand-600 bg-brand-50'
                  : 'border-gray-200 hover:border-brand-300'
              }`}
            >
              <div className='flex flex-col items-center text-center space-y-3'>
                <div className='w-12 h-12 flex items-center justify-center rounded-full bg-brand-100'>
                  <FileText className='w-6 h-6 text-brand-600' />
                </div>
                <h4 className='text-lg font-medium'>Full Proposal</h4>
                <p className='text-sm text-gray-600'>
                  Detailed quote with comprehensive course information
                </p>
                <span className='text-sm font-medium text-brand-600'>
                  ~45 minutes
                </span>
              </div>
            </button>
          </div>

          <div className='mt-6'>
            <DropdownInput
              value={quoteData.expiryDays ?? 30}
              onChange={e => handleInputChange('expiryDays', e.target.value)}
              options={options.expiryDays}
              placeholder='Select quote expiry period...'
              className='w-full'
              label='Quote Expiry'
              required
            />
          </div>
        </div>
      )
    },
    {
      title: 'Company Details',
      render: () => (
        <QuoteStep1
          quoteData={quoteData}
          handleInputChange={handleInputChange}
          companyId={companyId}
        />
      )
    },
    {
      title: 'Course Details',
      render: () => (
        <QuoteStep2
          quoteData={quoteData}
          handleInputChange={handleInputChange}
          options={options}
        />
      )
    },
    {
      title: 'Quote Details',
      render: () => (
        <QuoteStep3
          quoteData={quoteData}
          handleInputChange={handleInputChange}
        />
      )
    }
  ];

  return (
    <div className='bg-base-100 h-full overflow-y-auto'>
      <div className='max-w-4xl mx-auto px-4 py-8'>
        <SoftButton
          colour='primary'
          size='md'
          leftIcon={<ArrowLeft size={18} />}
          onClick={() =>
            navigate(companyId ? `/companies/${companyId}` : '/quotes')
          }
          className='mb-8'
        >
          {companyId ? 'Back to Company' : 'Back to Quotes'}
        </SoftButton>

        <div className='bg-white shadow-md rounded-lg overflow-visible'>
          <div className='px-6 py-4'>
            <h2 className='text-2xl font-bold'>Create New Quote</h2>
          </div>

          <div className='p-6'>
            <div className='mb-8'>
              <div className='flex justify-between'>
                {steps.map((step, index) => (
                  <div
                    key={index}
                    className={`flex items-center ${
                      index <= currentStep ? 'text-brand-700' : 'text-base-400'
                    }`}
                  >
                    <div
                      className={`w-8 h-8 rounded-full flex items-center justify-center ${
                        index <= currentStep
                          ? 'bg-brand-700 text-white'
                          : 'bg-base-200'
                      }`}
                    >
                      {index + 1}
                    </div>
                    <span className='ml-2 text-sm font-medium'>
                      {step.title}
                    </span>
                  </div>
                ))}
              </div>
              <div className='mt-4 h-2 bg-base-200 rounded-full'>
                <div
                  className='h-full bg-brand-700 rounded-full transition-all duration-300 ease-in-out'
                  style={{
                    width: `${((currentStep + 1) / steps.length) * 100}%`
                  }}
                ></div>
              </div>
            </div>

            {steps[currentStep].render()}

            <div className='mt-8 flex justify-between'>
              <SoftButton
                colour='primary'
                size='lg'
                leftIcon={<ArrowLeft size={18} />}
                onClick={() => setCurrentStep(prev => Math.max(0, prev - 1))}
                disabled={currentStep === 0}
              >
                Previous
              </SoftButton>
              {currentStep < steps.length - 1 ? (
                <FilledButton
                  colour='primary'
                  size='lg'
                  rightIcon={<ArrowRight size={18} />}
                  onClick={() =>
                    setCurrentStep(prev => Math.min(steps.length - 1, prev + 1))
                  }
                >
                  Next
                </FilledButton>
              ) : (
                <div className='flex flex-row space-x-4'>
                  <FilledButton
                    colour='base'
                    size='lg'
                    onClick={() => handleSubmit(false)}
                  >
                    Finish
                  </FilledButton>
                  <FilledButton
                    colour='primary'
                    size='lg'
                    onClick={() => handleSubmit(true)}
                  >
                    Finish and Open
                  </FilledButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateQuote;
