import React, { useState, useEffect, useCallback } from 'react';
import {
  Input,
  FileInput,
  FilledButton,
  SoftButton,
  Popup,
  TextButton,
  Modal
} from 'core';
import { LoaderCircle, Plus, X, UserPlus } from 'lucide-react';
import UserProfile from '../../../models/UserProfile';
import { FilterOperatorEnum } from '../../../utilities/Enumerables';
import Quote from '../../../models/Quote';
import debounce from 'lodash/debounce';

const BookQuote = ({ isOpen, onClose, onConfirm, isLoading, quoteOption }) => {
  const [formData, setFormData] = useState({
    delegates: [],
    startDate: null,
    endDate: null,
    clientPo: '',
    attachments: [],
    comments: ''
  });

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [parentQuote, setParentQuote] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [closeOtherOptions, setCloseOtherOptions] = useState(true);
  const [newDelegateForm, setNewDelegateForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  });

  useEffect(() => {
    const fetchQuote = async () => {
      if (quoteOption?.quoteId) {
        const quote = await Quote.getById(quoteOption.quoteId);
        setParentQuote(quote);
      }
    };
    fetchQuote();
  }, [quoteOption]);

  const searchDelegates = async query => {
    if (!query || !parentQuote) {
      setSearchResults([]);
      return;
    }

    setIsSearching(true);
    try {
      const results = await UserProfile.getAll({
        fts: {
          value: query,
          operator: FilterOperatorEnum.TEXTSEARCH
        },
        company_id: { value: parentQuote.company.id }
      });

      setSearchResults(
        (results.data || []).map(delegate => ({
          id: delegate.id,
          label: `${delegate.firstName} ${delegate.lastName}`,
          value: delegate,
          secondaryLabel: delegate.email
        }))
      );
    } catch (error) {
      console.error('Error searching delegates:', error);
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  };

  const debouncedSearch = useCallback(
    debounce(query => searchDelegates(query), 300),
    [parentQuote]
  );

  const handleChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleNewDelegateChange = (field, value) => {
    setNewDelegateForm(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const addDelegate = async delegate => {
    if (!formData.delegates.find(d => d.id === delegate.value.id)) {
      setFormData(prev => ({
        ...prev,
        delegates: [...prev.delegates, delegate]
      }));
      setSearchQuery('');
      setSearchResults([]);
    }
  };

  const createNewDelegate = async () => {
    try {
      const newDelegate = await new UserProfile({
        ...newDelegateForm,
        company_id: parentQuote.company.id
      }).insert();

      const delegateData = {
        id: newDelegate.id,
        label: `${newDelegate.firstName} ${newDelegate.lastName}`,
        value: newDelegate,
        secondaryLabel: newDelegate.email
      };
      addDelegate(delegateData);
      setNewDelegateForm({
        firstName: '',
        lastName: '',
        email: ''
      });
      setShowCreateForm(false);
    } catch (error) {
      console.error('Error creating delegate:', error);
    }
  };

  const removeDelegate = index => {
    setFormData(prev => ({
      ...prev,
      delegates: prev.delegates.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = () => {
    setShowConfirmModal(true);
  };

  const handleConfirmBooking = () => {
    onConfirm({
      ...formData,
      quoteOption,
      closeOtherOptions
    });
    setShowConfirmModal(false);
  };

  useEffect(() => {
    debouncedSearch(searchQuery);
    return () => debouncedSearch.cancel();
  }, [searchQuery, debouncedSearch]);

  return (
    <>
      <Modal
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        title='Confirm Booking'
        style='primary'
        actions={[
          {
            label: 'Cancel',
            onClick: () => setShowConfirmModal(false),
            style: 'base'
          },
          {
            label: 'Confirm Booking',
            onClick: handleConfirmBooking,
            style: 'primary'
          }
        ]}
      >
        <div className='space-y-4'>
          <div className='text-sm text-neutral-600'>
            <p className='font-medium mb-2'>
              Please review the booking details:
            </p>
            <div className='grid grid-cols-2 gap-2 text-sm'>
              <div className='font-medium'>Course:</div>
              <div>{quoteOption?.course?.name}</div>

              <div className='font-medium'>Start Date:</div>
              <div>{formData.startDate}</div>

              <div className='font-medium'>End Date:</div>
              <div>{formData.endDate}</div>

              <div className='font-medium'>Delegates:</div>
              <div>{formData.delegates.length}</div>

              <div className='font-medium'>Client PO:</div>
              <div>{formData.clientPo || 'Not provided'}</div>

              <div className='font-medium'>Quote Value:</div>
              <div>£{quoteOption?.quoteValue}</div>
            </div>
          </div>

          <div className='flex items-center space-x-2'>
            <input
              type='checkbox'
              id='closeOptions'
              checked={closeOtherOptions}
              onChange={e => setCloseOtherOptions(e.target.checked)}
              className='rounded border-neutral-300 text-primary-600 focus:ring-primary-500'
            />
            <label htmlFor='closeOptions' className='text-sm text-neutral-700'>
              Close all other quote options
            </label>
          </div>
        </div>
      </Modal>

      <Popup
        isOpen={isOpen}
        onClose={onClose}
        title='Book Quote Option'
        size='md'
        footer={
          <div className='flex justify-end space-x-2'>
            <SoftButton onClick={onClose}>Cancel</SoftButton>
            <FilledButton onClick={handleSubmit} disabled={isLoading}>
              {isLoading ? (
                <LoaderCircle className='animate-spin mr-2' />
              ) : null}
              Create Booking
            </FilledButton>
          </div>
        }
      >
        <div className='space-y-4'>
          <div>
            <div className='flex justify-between items-center mb-1'>
              <label className='block text-sm font-medium text-neutral-700'>
                Delegates
              </label>
              <span className='text-sm text-neutral-500'>
                {formData.delegates.length} delegate
                {formData.delegates.length !== 1 ? 's' : ''} added
              </span>
            </div>

            <div className='space-y-2'>
              <div>
                <div className='relative'>
                  <Input
                    placeholder='Search delegates...'
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                  />
                  {isSearching && (
                    <div className='absolute right-3 top-1/2 -translate-y-1/2'>
                      <LoaderCircle
                        size={16}
                        className='animate-spin text-neutral-400'
                      />
                    </div>
                  )}
                </div>

                {searchQuery && !isSearching && (
                  <div className='mt-1 border rounded-md shadow-sm'>
                    {searchResults.length > 0 ? (
                      <div className='max-h-48 overflow-y-auto'>
                        {searchResults.map(delegate => (
                          <button
                            key={delegate.id}
                            className='w-full text-left px-3 py-2 hover:bg-neutral-50 focus:bg-neutral-50 focus:outline-none'
                            onClick={() => addDelegate(delegate)}
                          >
                            <div className='text-sm font-medium'>
                              {delegate.label}
                            </div>
                            <div className='text-xs text-neutral-500'>
                              {delegate.secondaryLabel}
                            </div>
                          </button>
                        ))}
                      </div>
                    ) : showCreateForm ? (
                      <div className='p-4 space-y-3 bg-base-100'>
                        <h4 className='font-medium'>Create New Delegate</h4>
                        <div className='grid grid-cols-2 gap-2'>
                          <Input
                            label='First Name'
                            placeholder='First Name'
                            value={newDelegateForm.firstName}
                            onChange={e =>
                              handleNewDelegateChange(
                                'firstName',
                                e.target.value
                              )
                            }
                            required
                            size='md'
                          />
                          <Input
                            label='Last Name'
                            placeholder='Last Name'
                            value={newDelegateForm.lastName}
                            onChange={e =>
                              handleNewDelegateChange(
                                'lastName',
                                e.target.value
                              )
                            }
                            required
                            size='md'
                          />
                        </div>
                        <Input
                          label='Email'
                          placeholder='Email'
                          type='email'
                          value={newDelegateForm.email}
                          onChange={e =>
                            handleNewDelegateChange('email', e.target.value)
                          }
                          required
                          size='md'
                        />
                        <div className='flex justify-end gap-2'>
                          <SoftButton onClick={() => setShowCreateForm(false)}>
                            Cancel
                          </SoftButton>
                          <FilledButton
                            onClick={createNewDelegate}
                            disabled={
                              !newDelegateForm.firstName ||
                              !newDelegateForm.lastName ||
                              !newDelegateForm.email
                            }
                          >
                            Create Delegate
                          </FilledButton>
                        </div>
                      </div>
                    ) : (
                      <div className='flex flex-col items-center justify-center py-6 px-4 text-center bg-neutral-50'>
                        <p className='text-sm text-neutral-600 mb-3'>
                          No delegates found matching your search
                        </p>
                        <TextButton
                          onClick={() => setShowCreateForm(true)}
                          className='inline-flex items-center text-sm font-medium text-blue-600 hover:text-blue-700'
                        >
                          <UserPlus size={16} className='mr-2' />
                          Create New Delegate
                        </TextButton>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {formData.delegates.length > 0 && (
                <div className='space-y-2 mt-2'>
                  {formData.delegates.map((delegate, index) => (
                    <div
                      key={delegate.value.id || index}
                      className='flex items-center justify-between bg-neutral-50 p-2 rounded'
                    >
                      <span className='text-sm'>
                        {delegate.label} [{delegate.secondaryLabel}]
                      </span>
                      <button
                        onClick={() => removeDelegate(index)}
                        className='text-neutral-500 hover:text-neutral-700'
                      >
                        <X size={16} />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className='grid grid-cols-2 gap-4'>
            <div>
              <label className='block text-sm font-medium text-neutral-700 mb-1'>
                Start Date
              </label>
              <Input
                type='date'
                value={formData.startDate}
                onChange={e => handleChange('startDate', e.target.value)}
              />
            </div>

            <div>
              <label className='block text-sm font-medium text-neutral-700 mb-1'>
                End Date
              </label>
              <Input
                type='date'
                value={formData.endDate}
                onChange={e => handleChange('endDate', e.target.value)}
              />
            </div>
          </div>

          <div>
            <label className='block text-sm font-medium text-neutral-700 mb-1'>
              Client PO
            </label>
            <Input
              placeholder='Enter client PO number'
              value={formData.clientPo}
              onChange={e => handleChange('clientPo', e.target.value)}
            />
          </div>

          <div>
            <label className='block text-sm font-medium text-neutral-700 mb-1'>
              Attachments
            </label>
            <FileInput
              multiple
              onChange={files => handleChange('attachments', files)}
            />
            {formData.attachments?.length > 0 && (
              <div className='mt-2 space-y-2'>
                {formData.attachments.map((file, index) => (
                  <div
                    key={index}
                    className='flex items-center justify-between p-2 bg-neutral-50 rounded'
                  >
                    <span className='text-sm text-neutral-600 truncate'>
                      {file.name}
                    </span>
                    <button
                      onClick={() => {
                        const newAttachments = [...formData.attachments];
                        newAttachments.splice(index, 1);
                        handleChange('attachments', newAttachments);
                      }}
                      className='text-neutral-400 hover:text-danger-500'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='h-4 w-4'
                        viewBox='0 0 20 20'
                        fill='currentColor'
                      >
                        <path
                          fillRule='evenodd'
                          d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                          clipRule='evenodd'
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div>
            <label className='block text-sm font-medium text-neutral-700 mb-1'>
              Comments
            </label>
            <Input
              as='textarea'
              placeholder='Add any additional comments'
              value={formData.comments}
              onChange={e => handleChange('comments', e.target.value)}
              rows={4}
            />
          </div>

          {quoteOption && (
            <div className='mt-4 p-4 bg-neutral-50 rounded-lg'>
              <h4 className='font-medium mb-2'>Quote Option Details</h4>
              <div className='text-sm text-neutral-600 space-y-1'>
                <p>Course: {quoteOption.course?.name}</p>
                <p>Trainer: {quoteOption.trainer?.name}</p>
                <p>
                  Location:{' '}
                  {quoteOption.location?.fullAddress ||
                    quoteOption.obfuscatedLocation}
                </p>
                <p>Delivery: {quoteOption.courseDelivery?.name}</p>
              </div>
            </div>
          )}
        </div>
      </Popup>
    </>
  );
};

export default BookQuote;
