import React from 'react';

/**
 * DropdownInput Component
 *
 * @param {Array} props.options - Array of options for the dropdown
 * @param {string} props.value - The current selected value
 * @param {function} props.onChange - Function to handle value change
 * @param {string} [props.placeholder='Select an option'] - Placeholder text
 * @param {string} [props.label] - The label text for the dropdown
 * @param {string} [props.labelHint] - Optional text to display next to the label
 * @param {string} [props.className] - Additional CSS classes for the dropdown
 * @param {string} [props.hint] - Hint text to display below the dropdown
 * @param {string} [props.size='md'] - Size of the dropdown ('xs', 'sm', 'md', 'lg', 'xl')
 * @param {function} [props.getKey] - Function to generate a custom key for each option
 * @param {function} [props.onClick] - Function to handle click events
 * @returns {React.ReactElement} A dropdown input element with label and optional hint
 */
const DropdownInput = ({
  options,
  value,
  onChange,
  placeholder = 'Select an option',
  disablePlaceholder = false,
  label,
  labelHint,
  className = '',
  hint,
  size = 'md',
  getKey = option => option.value, // Default key function
  disabled,
  required,
  onClick,
  ...props
}) => {
  const sizeClasses = {
    xs: 'px-2 py-1 text-xs',
    sm: 'px-2 py-1 text-sm',
    md: 'px-2.5 py-1.5 text-sm',
    lg: 'px-3 py-2 text-sm',
    xl: 'px-3.5 py-2.5 text-sm'
  };
  return (
    <div className='w-full'>
      {label && (
        <div className='flex justify-between'>
          <label className='block text-sm font-medium leading-6 text-gray-900'>
            {label}
            {required && <span className='text-red-500 ml-1'>*</span>}
          </label>
          {labelHint && (
            <span className='text-sm leading-6 text-gray-500'>{labelHint}</span>
          )}
        </div>
      )}
      <div className='relative'>
        <select
          disabled={disabled}
          value={value}
          onChange={onChange}
          onClick={onClick}
          className={`pr-10 truncate block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset
            ${disabled ? 'bg-base-100 text-base-500 cursor-not-allowed' : ''}
            ${
              required && !value
                ? 'ring-danger-300 focus:ring-danger-600'
                : 'ring-gray-300 focus:ring-primary-600'
            }
            focus:ring-2 ${sizeClasses[size]} sm:leading-6 ${className}`}
          {...props}
        >
          {!disablePlaceholder && <option value=''>{placeholder}</option>}
          {options.map(option => (
            <option
              key={getKey(option)}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {hint && <p className='mt-1 text-sm text-gray-500'>{hint}</p>}
    </div>
  );
};

export { DropdownInput };
