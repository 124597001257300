import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  ArrowLeft,
  TriangleAlert,
  CheckCircle,
  LoaderCircle,
  XCircle
} from 'lucide-react';
import {
  FilledButton,
  SoftButton,
  Input,
  TextButton,
  useNotification
} from 'core';
import Trainer from '../../../models/Trainer';
import { supabase } from '../../../utilities/supabase';

const CreateTrainer = () => {
  const navigate = useNavigate();
  const { addNotification } = useNotification();

  const initialTrainerData = {
    name: '',
    capsuleId: null,
    accountNumber: '',
    creditLimit: 0,
    terms: {
      days: 0,
      type: { id: 90 }
    },
    contacts: [{}],
    status: { id: 101 },
    approved: false,
    rating: null,
    options: {},
    xeroId: ''
  };

  const [trainerData, setTrainerData] = useState(initialTrainerData);
  const [xeroContacts, setXeroContacts] = useState([]);
  const [showXeroResults, setShowXeroResults] = useState(false);
  const [matchingTrainers, setMatchingTrainers] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchingXero, setIsSearchingXero] = useState(false);
  const [isCheckingXero, setIsCheckingXero] = useState(false);
  const [xeroError, setXeroError] = useState(null);
  const [accountNumberXeroStatus, setAccountNumberXeroStatus] = useState(null);
  const [accountNumberDbStatus, setAccountNumberDbStatus] = useState(null);
  const [xeroMatchingContact, setXeroMatchingContact] = useState(null);

  const searchTrainers = useCallback(async () => {
    if (trainerData.name.length >= 3) {
      setIsSearching(true);
      try {
        const { data: results } = await supabase.rpc(
          'search_similar_trainers',
          {
            p_name: trainerData.name,
            p_threshold: 0.3,
            max_results: 5
          }
        );
        setMatchingTrainers(results || []);
      } catch (error) {
        console.error('Error searching trainers:', error);
      } finally {
        setIsSearching(false);
      }
    } else {
      setMatchingTrainers([]);
    }
  }, [trainerData.name]);

  const checkDbAccountNumber = useCallback(async () => {
    if (trainerData.accountNumber) {
      try {
        const { data: dbData, error: dbError } = await supabase.rpc(
          'check_account_availability',
          {
            p_account_num: trainerData.accountNumber
          }
        );

        if (dbError) throw dbError;

        const isInvalidInDb = dbData && dbData > 0;
        setAccountNumberDbStatus(isInvalidInDb ? 'invalid' : 'available');
      } catch (error) {
        console.error('Error checking account number in DB:', error);
      }
    } else {
      setAccountNumberDbStatus(null);
    }
  }, [trainerData.accountNumber]);

  useEffect(() => {
    const debounceTimer = setTimeout(searchTrainers, 300);
    return () => clearTimeout(debounceTimer);
  }, [searchTrainers]);

  useEffect(() => {
    const debounceTimer = setTimeout(checkDbAccountNumber, 300);
    return () => clearTimeout(debounceTimer);
  }, [checkDbAccountNumber]);

  const handleInputChange = (field, value) => {
    setTrainerData(prev => ({ ...prev, [field]: value }));
    if (field === 'accountNumber') {
      setAccountNumberXeroStatus(null);
      setXeroMatchingContact(null);
    }
  };

  const searchXeroContacts = async () => {
    try {
      setIsSearchingXero(true);
      setXeroError(null);
      const { data, error } = await supabase.functions.invoke(
        'xero/searchContacts',
        {
          body: { searchTerm: trainerData.name }
        }
      );

      if (error) throw error;

      if (data.result) {
        setXeroError(data.result);
        setXeroContacts([]);
      } else {
        setXeroContacts(data || []);
        setShowXeroResults(true);
      }
    } catch (error) {
      console.error('Error fetching Xero contacts:', error);
      addNotification({
        type: 'error',
        title: 'Error Searching Xero',
        description:
          error.message ||
          'Failed to search Xero contacts. Please try again later.'
      });
    } finally {
      setIsSearchingXero(false);
    }
  };

  const checkXeroAccountNumber = async () => {
    try {
      setIsCheckingXero(true);

      const { data: xeroData, error: xeroError } =
        await supabase.functions.invoke('xero/searchContacts', {
          body: { searchTerm: trainerData.accountNumber }
        });

      if (xeroError) throw xeroError;

      const isInvalidInXero = xeroData && xeroData.length > 0;
      setAccountNumberXeroStatus(isInvalidInXero ? 'invalid' : 'available');
      setXeroMatchingContact(isInvalidInXero ? xeroData[0] : null);
    } catch (error) {
      console.error('Error checking account number:', error);
      addNotification({
        type: 'error',
        title: 'Error Checking Account Number',
        description: 'Failed to verify account number availability.'
      });
    } finally {
      setIsCheckingXero(false);
    }
  };

  const selectXeroContact = contact => {
    handleInputChange('xeroId', contact.ContactID);
    if (contact.AccountNumber) {
      handleInputChange('accountNumber', contact.AccountNumber);
    }
    if (contact.Name) {
      handleInputChange('name', contact.Name);
    }
    setShowXeroResults(false);
  };

  const handleSubmit = async (navigateToTrainer = false) => {
    if (
      accountNumberXeroStatus === 'invalid' ||
      accountNumberDbStatus === 'invalid'
    ) {
      addNotification({
        type: 'error',
        title: 'Invalid Account Number',
        description:
          'Please choose a different account number that is not already in use.'
      });
      return;
    }

    try {
      //If we are creating a brand new record call the xero api to create the supplier
      if (!trainerData.xeroId) {
        const { data: xeroData, error: xeroError } =
          await supabase.functions.invoke('xero/createContact', {
            body: {
              Name: trainerData.name,
              AccountNumber: trainerData.accountNumber,
              IsSupplier: true
            }
          });

        if (xeroError) {
          throw xeroError;
        }

        //Insert the returned xero id into the trainerData before we insert
        trainerData.xeroId = xeroData.ContactID;
      }

      const newTrainer = new Trainer(trainerData);
      const insertedTrainer = await newTrainer.insert();

      navigate(
        navigateToTrainer ? `/trainers/${insertedTrainer.id}` : '/trainers'
      );
    } catch (error) {
      console.error('Error creating trainer:', error);
      addNotification({
        type: 'error',
        title: 'Error Creating Trainer',
        description: 'Failed to create trainer. Please try again.'
      });
    }
  };

  return (
    <div className='bg-base-100 h-full overflow-y-auto'>
      <div className='max-w-4xl mx-auto px-4 py-8'>
        <SoftButton
          colour='primary'
          size='md'
          leftIcon={<ArrowLeft size={18} />}
          onClick={() => navigate('/trainers')}
          className='mb-8'
        >
          Back to Trainers
        </SoftButton>

        <div className='bg-white shadow-md rounded-lg overflow-visible'>
          <div className='px-6 py-4'>
            <h2 className='text-2xl font-bold'>Create New Trainer</h2>
          </div>
          <div className='px-6'>
            <p>
              Creating this trainer record will{' '}
              {trainerData.xeroId
                ? 'link it to the existing record'
                : 'also create a corresponding record'}{' '}
              in Xero.
            </p>
          </div>

          <div className='p-6'>
            <div className='space-y-4'>
              <div className='flex gap-x-2 items-center'>
                <div className='flex-1'>
                  <Input
                    label='Name'
                    value={trainerData.name}
                    onChange={e => handleInputChange('name', e.target.value)}
                    required
                    disabled={!!trainerData.xeroId}
                  />
                </div>
                <TextButton
                  className='pt-7 min-w-36 justify-end'
                  size='sm'
                  onClick={searchXeroContacts}
                  disabled={!trainerData.name || isSearchingXero}
                >
                  {isSearchingXero && (
                    <LoaderCircle className='animate-spin mr-2' size={16} />
                  )}
                  Search Xero
                </TextButton>
              </div>
              {xeroError && (
                <div className='mt-2 flex items-center gap-2'>
                  <CheckCircle className='h-4 w-4 text-success-500' />
                  <p className='text-success-600 text-sm'>{xeroError}</p>
                </div>
              )}
              {showXeroResults && xeroContacts.length > 0 && (
                <div className='mt-2 p-2 border border-base-200 rounded-lg max-h-40 overflow-y-auto'>
                  {xeroContacts.map(contact => (
                    <div
                      key={contact.ContactID}
                      className='p-2 hover:bg-base-100 cursor-pointer text-sm'
                      onClick={() => selectXeroContact(contact)}
                    >
                      {contact.Name} - {contact.AccountNumber}
                    </div>
                  ))}
                </div>
              )}

              {isSearching && (
                <div className='mt-2 text-base-500 flex items-center gap-2'>
                  <LoaderCircle className='animate-spin' size={16} />
                  Searching for matches...
                </div>
              )}
              {matchingTrainers.length > 0 ? (
                <div className='mt-2'>
                  <div className='flex items-center gap-2'>
                    <TriangleAlert className='h-4 w-4 text-warning-500' />
                    <p className='text-warning-600 text-sm'>
                      Possible duplicate trainers found
                    </p>
                  </div>
                  <ul className='mt-2 space-y-1 pl-6'>
                    {matchingTrainers.map(trainer => (
                      <Link
                        key={trainer.id}
                        to={`/trainers/${trainer.id}`}
                        className='text-warning-700 text-sm hover:text-warning-800 transition-colors duration-200 cursor-pointer flex items-center gap-2'
                      >
                        <span className='w-1.5 h-1.5 rounded-full bg-warning-400'></span>
                        {trainer.name} (#{trainer.id})
                      </Link>
                    ))}
                  </ul>
                </div>
              ) : (
                trainerData.name && (
                  <div className='mt-2 flex items-center gap-2'>
                    <CheckCircle className='h-4 w-4 text-success-500' />
                    <p className='text-success-600 text-sm'>
                      No duplicate trainers found
                    </p>
                  </div>
                )
              )}

              <div>
                <div className='flex gap-x-2 items-center'>
                  <div className='flex-1'>
                    <Input
                      label='Account Number'
                      value={trainerData.accountNumber}
                      onChange={e =>
                        handleInputChange('accountNumber', e.target.value)
                      }
                      disabled={!!trainerData.xeroId}
                      required
                    />
                  </div>
                  <div>
                    {!trainerData.xeroId && (
                      <TextButton
                        className='pt-7 min-w-36 justify-end'
                        size='sm'
                        onClick={checkXeroAccountNumber}
                        disabled={!trainerData.accountNumber || isCheckingXero}
                      >
                        {isCheckingXero && (
                          <LoaderCircle
                            className='animate-spin mr-2'
                            size={16}
                          />
                        )}
                        Check xero availability
                      </TextButton>
                    )}
                  </div>
                </div>
                {(accountNumberXeroStatus || accountNumberDbStatus) && (
                  <div className='mt-2 space-y-2'>
                    {accountNumberXeroStatus === 'invalid' ? (
                      <div className='flex items-center gap-2'>
                        <XCircle className='h-4 w-4 text-danger-500' />
                        <p className='text-danger-600 text-sm'>
                          {xeroMatchingContact
                            ? `Already in use in Xero - ${xeroMatchingContact.Name}`
                            : 'Account number already exists in Xero'}
                        </p>
                      </div>
                    ) : (
                      accountNumberXeroStatus === 'available' && (
                        <div className='flex items-center gap-2'>
                          <CheckCircle className='h-4 w-4 text-success-500' />
                          <p className='text-success-600 text-sm'>
                            Account number is available in Xero
                          </p>
                        </div>
                      )
                    )}
                    {accountNumberDbStatus === 'invalid' ? (
                      <div className='flex items-center gap-2'>
                        <XCircle className='h-4 w-4 text-danger-500' />
                        <p className='text-danger-600 text-sm'>
                          Account number already exists in database
                        </p>
                      </div>
                    ) : (
                      accountNumberDbStatus === 'available' && (
                        <div className='flex items-center gap-2'>
                          <CheckCircle className='h-4 w-4 text-success-500' />
                          <p className='text-success-600 text-sm'>
                            Account number is available in database
                          </p>
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>

              <div>
                <Input
                  label='Capsule ID'
                  value={trainerData.capsuleId}
                  onChange={e => handleInputChange('capsuleId', e.target.value)}
                  className='mt-1 block w-full'
                />
              </div>

              <div>
                <Input
                  label='Credit Limit'
                  type='number'
                  value={trainerData.creditLimit}
                  onChange={e =>
                    handleInputChange('creditLimit', e.target.value)
                  }
                  className='mt-1 block w-full'
                />
              </div>
            </div>

            <div className='mt-8 flex justify-end space-x-4'>
              <TextButton
                onClick={() => {
                  setTrainerData(initialTrainerData);
                  setXeroContacts([]);
                  setShowXeroResults(false);
                  setXeroError(null);
                  setAccountNumberDbStatus(null);
                  setAccountNumberXeroStatus(null);
                }}
              >
                Reset Form
              </TextButton>
              <SoftButton
                colour='base'
                size='lg'
                onClick={() => handleSubmit(false)}
                disabled={
                  !trainerData.name ||
                  !trainerData.accountNumber ||
                  (!trainerData.xeroId &&
                    (accountNumberXeroStatus !== 'available' ||
                      accountNumberDbStatus !== 'available')) ||
                  (trainerData.xeroId && accountNumberDbStatus !== 'available')
                }
              >
                Create
              </SoftButton>
              <FilledButton
                colour='primary'
                size='lg'
                onClick={() => handleSubmit(true)}
                disabled={
                  !trainerData.name ||
                  !trainerData.accountNumber ||
                  (!trainerData.xeroId &&
                    (accountNumberXeroStatus !== 'available' ||
                      accountNumberDbStatus !== 'available')) ||
                  (trainerData.xeroId && accountNumberDbStatus !== 'available')
                }
              >
                Create and Open
              </FilledButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTrainer;
