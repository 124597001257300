import React from 'react';
import { AutoComplete, DropdownInput, Input, MultiSelectBox } from 'core';
import {
  MapPin,
  Building2,
  Monitor,
  Video as VideoIcon,
  Award
} from 'lucide-react';
import Course from '../../../../models/Course';
import CoreEntityType from '../../../../models/CoreEntityType';
import {
  FilterOperatorEnum,
  EntityTypeEnum
} from '../../../../utilities/Enumerables';

const QuoteStep2 = ({ quoteData, handleInputChange, options }) => {
  const loadCourseOptions = async searchQuery => {
    try {
      const { data: courses } = await Course.getAll({
        fts: {
          operator: FilterOperatorEnum.TEXTSEARCH,
          value: searchQuery
        }
      });

      return courses.map(course => ({
        value: course.id,
        label: course.name
      }));
    } catch (error) {
      console.error('Error loading course options:', error);
      return [];
    }
  };

  const loadAccreditationOptions = async searchQuery => {
    try {
      const { data: accreditations } = await CoreEntityType.getAll({
        entity_id: {
          operator: FilterOperatorEnum.EQUALS,
          value: EntityTypeEnum.AccreditationBody
        },
        type: {
          operator: FilterOperatorEnum.ILIKE,
          value: searchQuery
        }
      });

      return accreditations.map(accreditation => ({
        value: accreditation.id,
        label: accreditation.type
      }));
    } catch (error) {
      console.error('Error loading accreditation options:', error);
      return [];
    }
  };

  const deliveryTypeIcons = {
    Classroom: <MapPin className='w-6 h-6 text-brand-600' />,
    Onsite: <Building2 className='w-6 h-6 text-brand-600' />,
    'E-Learning': <Monitor className='w-6 h-6 text-brand-600' />,
    'Remote Learning': <VideoIcon className='w-6 h-6 text-brand-600' />,
    NVQ: <Award className='w-6 h-6 text-brand-600' />
  };

  return (
    <div className='space-y-6'>
      <div>
        <AutoComplete
          value={quoteData.courseName}
          onChange={value => handleInputChange('courseName', value)}
          loadOptions={loadCourseOptions}
          placeholder='Enter or search for a course...'
          className='w-full'
          required
          label='Course Name'
        />
      </div>

      <div>
        <DropdownInput
          value={quoteData.expLevel}
          onChange={e => handleInputChange('expLevel', e.target.value)}
          options={options.expLevel}
          placeholder='Select experience level...'
          className='w-full'
          label='Experience Level'
        />
      </div>

      <div>
        <Input
          type='number'
          value={quoteData.delegates}
          onChange={e => handleInputChange('delegates', e.target.value)}
          placeholder='Enter number of delegates...'
          className='w-full'
          label='Number of Spaces'
          min={1}
        />
      </div>

      <div>
        <label className='block text-sm font-medium text-gray-700 mb-1'>
          Delivery Methods
        </label>
        <div className='flex justify-center gap-4 flex-wrap'>
          {options.deliveryMethods
            .sort((a, b) => a.order - b.order)
            .map(method => (
              <button
                key={method.value}
                onClick={() => {
                  const isSelected = quoteData.deliveryMethods.some(
                    m => m.value === method.value
                  );
                  const newMethods = isSelected
                    ? quoteData.deliveryMethods.filter(
                        m => m.value !== method.value
                      )
                    : [...quoteData.deliveryMethods, method];
                  handleInputChange('deliveryMethods', newMethods);
                }}
                className={`p-4 rounded-lg border-2 transition-all w-[calc(20%-1rem)] min-w-[150px] ${
                  quoteData.deliveryMethods.some(m => m.value === method.value)
                    ? 'border-brand-600 bg-brand-50'
                    : 'border-gray-200 hover:border-brand-300'
                }`}
              >
                <div className='flex flex-col items-center text-center space-y-2'>
                  <div className='w-12 h-12 flex items-center justify-center rounded-full bg-brand-100'>
                    {deliveryTypeIcons[method.label]}
                  </div>
                  <h4 className='text-sm font-medium'>{method.label}</h4>
                </div>
              </button>
            ))}
        </div>
      </div>

      <div>
        <MultiSelectBox
          selectedOptions={quoteData.accreditations}
          onChange={value => handleInputChange('accreditations', value)}
          loadOptions={loadAccreditationOptions}
          placeholder='Search for accreditations...'
          className='w-full'
          label='Accreditations'
        />
      </div>
    </div>
  );
};

export default QuoteStep2;
