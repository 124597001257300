import React from 'react';

/**
 * Base Radio Group Component
 *
 * @param {Object[]} props.options - Array of radio options
 * @param {string} props.options[].value - Value of the radio option
 * @param {string} props.options[].label - Label text for the radio option
 * @param {string} [props.options[].description] - Optional description text
 * @param {string} props.value - Currently selected value
 * @param {function} props.onChange - Change handler function
 * @param {string} [props.name] - Name attribute for the radio group
 * @param {string} [props.label] - Label for the entire radio group
 * @param {string} [props.colour='primary'] - The colour theme ('brand', 'primary', 'info', 'success', 'warning', 'danger', 'base')
 * @param {string} [props.alignment='left'] - Alignment of radio buttons ('left' or 'right')
 * @param {string} [props.className] - Additional CSS classes
 * @param {Object} props - The properties passed to the component
 * @returns {React.ReactElement} A radio group with the specified properties
 */
const BaseRadioGroup = ({
  options,
  value,
  onChange,
  name,
  label,
  colour = 'primary',
  alignment = 'left',
  className = '',
  variantClasses,
  ...props
}) => {
  const baseClasses = ' focus:outline-none';

  const colourClasses = {
    brand: 'text-brand-600 focus:ring-brand-600',
    primary: 'text-primary-600 focus:ring-primary-600',
    info: 'text-info-600 focus:ring-info-600',
    success: 'text-success-600 focus:ring-success-600',
    warning: 'text-warning-600 focus:ring-warning-600',
    danger: 'text-danger-600 focus:ring-danger-600',
    base: 'text-base-600 focus:ring-base-600'
  };

  return (
    <div className={`${baseClasses} ${className}`} {...props}>
      {label && (
        <label className='block text-sm font-medium text-gray-900 mb-1'>
          {label}
        </label>
      )}
      <div role='radiogroup' className={variantClasses.group}>
        {options.map(option => (
          <div
            key={option.value}
            className={`relative flex ${
              alignment === 'right' ? 'flex-row-reverse justify-between' : ''
            } ${variantClasses.container}`}
          >
            <input
              type='radio'
              name={name}
              value={option.value}
              checked={value === option.value}
              onChange={e => onChange(e.target.value)}
              disabled={option.disabled}
              className={`border-gray-300 ${colourClasses[colour]} ${variantClasses.input} disabled:cursor-not-allowed`}
            />
            <label
              className={`${variantClasses.label} ${
                alignment === 'right' ? 'mr-3' : 'ml-3'
              } ${option.disabled ? 'text-gray-400' : ''}`}
            >
              <span
                className={`${variantClasses.text} ${
                  option.disabled ? 'text-gray-400' : ''
                }`}
              >
                {option.label}
              </span>
              {option.description && (
                <span
                  className={`${variantClasses.description} ${
                    option.disabled ? 'text-gray-400' : ''
                  }`}
                >
                  {option.description}
                </span>
              )}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

const SimpleRadioGroup = ({ className = '', ...props }) => {
  const variantClasses = {
    group: 'space-y-4',
    container: 'items-center',
    input: 'h-4 w-4',
    label: 'block text-sm font-medium leading-6 text-gray-900',
    text: '',
    description: ''
  };
  return (
    <BaseRadioGroup
      className={className}
      variantClasses={variantClasses}
      {...props}
    />
  );
};

const SimpleInlineRadioGroup = ({ className = '', ...props }) => {
  const variantClasses = {
    group: '',
    container: 'items-center inline-flex mr-4',
    input: 'h-4 w-4',
    label: 'block text-sm font-medium leading-6 text-gray-900',
    text: '',
    description: ''
  };
  return (
    <BaseRadioGroup
      className={`flex-row ${className}`}
      variantClasses={variantClasses}
      {...props}
    />
  );
};

export { SimpleRadioGroup, SimpleInlineRadioGroup };
