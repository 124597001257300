import React, { useRef, useState } from 'react';
import { Upload } from 'lucide-react';

const FileInput = ({
  multiple = false,
  allowDragDrop = true,
  onChange,
  accept,
  className = ''
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = event => {
    const files = Array.from(event.target.files);
    if (onChange) {
      onChange(multiple ? files : files[0]);
    }
  };

  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
    if (allowDragDrop) {
      setIsDragging(true);
    }
  };

  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    if (allowDragDrop) {
      const files = Array.from(e.dataTransfer.files);
      if (onChange) {
        onChange(multiple ? files : files[0]);
      }
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div
      className={`relative ${className}`}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div
        onClick={handleClick}
        className={`
          flex flex-col items-center justify-center p-6
          border-2 border-dashed rounded-lg cursor-pointer
          transition-colors duration-200
          ${
            isDragging
              ? 'border-primary-500 bg-primary-50'
              : 'border-neutral-300 hover:border-primary-500 bg-white'
          }
        `}
      >
        <Upload
          size={24}
          className={`mb-2 ${
            isDragging ? 'text-primary-500' : 'text-neutral-400'
          }`}
        />
        <p className='text-sm text-neutral-600'>
          {allowDragDrop ? (
            <>
              Drop files here or{' '}
              <span className='text-primary-600'>browse</span>
            </>
          ) : (
            <span className='text-primary-600'>Choose files</span>
          )}
        </p>
        <p className='text-xs text-neutral-500 mt-1'>
          {multiple
            ? 'You can select multiple files'
            : 'You can select one file'}
        </p>
      </div>

      <input
        ref={fileInputRef}
        type='file'
        className='hidden'
        multiple={multiple}
        accept={accept}
        onChange={handleFileChange}
      />
    </div>
  );
};

export { FileInput };
