import React from 'react';
import { Breadcrumbs } from '../navigation/Breadcrumbs';

const PageHeading = ({ title, pages, homeHref = '/', actions, meta }) => {
  return (
    <div className='flex-none border-b'>
      <div className='flex items-center justify-between'>
        <div>
          <Breadcrumbs homeHref={homeHref} pages={pages} />
          <h1 className='text-xl md:text-2xl font-semibold text-base-900 mt-2'>
            {title}
          </h1>
          {meta && <div className='mt-1 text-sm text-gray-500'>{meta}</div>}
        </div>
        {actions && (
          <div className='flex items-center gap-x-3 shrink-0'>
            {Array.isArray(actions)
              ? actions.map((action, index) => (
                  <div key={index} className='shrink-0'>
                    {action}
                  </div>
                ))
              : actions}
          </div>
        )}
      </div>
    </div>
  );
};

export { PageHeading };
