import { useContext } from 'react';
import { NavLink, Routes, Route } from 'react-router-dom';
import {
  Users,
  Tag,
  Link,
  Webhook,
  ChevronRight,
  Earth,
  SquareTerminal
} from 'lucide-react';
import TagsSettings from './tabs/Tags';
import GlobalSettings from './tabs/GlobalSettings';
import { UserProfileContext } from '../../../App';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import { PERMISSIONS } from '../../../utilities/Permissions';
import TeamMembers from './tabs/TeamMembers';
import Roles from './tabs/Roles';
import Integrations from './tabs/Integrations';
import Scripts from './tabs/Scripts';

const SettingsHome = ({ settingsCategories }) => {
  return (
    <div>
      <h1 className='text-xl font-semibold text-base-900 mb-8'>Settings</h1>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
        {settingsCategories.map(category => (
          <div
            key={category.name}
            className={`group block rounded-lg border border-base-200 p-6 ${
              category.disabled
                ? 'opacity-60 cursor-not-allowed'
                : 'hover:border-base-300 hover:shadow-sm transition-all'
            }`}
          >
            {category.disabled ? (
              <div className='flex items-start space-x-4'>
                <div className={`p-2 rounded-lg ${category.iconBackground}`}>
                  <category.icon
                    className={`w-6 h-6 ${category.iconForeground}`}
                  />
                </div>
                <div className='flex-1 min-w-0'>
                  <div className='flex items-center justify-between'>
                    <div className='flex items-center gap-2'>
                      <p className='text-lg font-medium text-gray-900'>
                        {category.name}
                      </p>
                      <span className='px-2 py-1 text-xs font-medium rounded-full bg-gray-100 text-gray-600'>
                        Coming soon
                      </span>
                    </div>
                    <ChevronRight className='w-5 h-5 text-gray-400' />
                  </div>
                  <p className='mt-1 text-sm text-gray-500'>
                    {category.description}
                  </p>
                </div>
              </div>
            ) : (
              <NavLink to={category.path}>
                <div className='flex items-start space-x-4'>
                  <div className={`p-2 rounded-lg ${category.iconBackground}`}>
                    <category.icon
                      className={`w-6 h-6 ${category.iconForeground}`}
                    />
                  </div>
                  <div className='flex-1 min-w-0'>
                    <div className='flex items-center justify-between'>
                      <p className='text-lg font-medium text-gray-900'>
                        {category.name}
                      </p>
                      <ChevronRight className='w-5 h-5 text-gray-400 group-hover:text-gray-600 transition-colors' />
                    </div>
                    <p className='mt-1 text-sm text-gray-500'>
                      {category.description}
                    </p>
                  </div>
                </div>
              </NavLink>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const Settings = () => {
  const { userProfile: currentUser } = useContext(UserProfileContext);

  const settingsCategories = [
    currentUser.hasPermission(
      EntityTypeEnum.Settings,
      PERMISSIONS[EntityTypeEnum.Settings].VIEW_GLOBALS
    ) && {
      name: 'Global Settings',
      path: '/settings/globals',
      icon: Earth,
      description: 'Configure system-wide default settings',
      iconBackground: 'bg-sky-100',
      iconForeground: 'text-sky-600'
    },
    currentUser.hasPermission(
      EntityTypeEnum.Settings,
      PERMISSIONS[EntityTypeEnum.Settings].VIEW_TEAMS
    ) && {
      name: 'Team Members',
      path: '/settings/team',
      icon: Users,
      description: 'Manage your team members and their access',
      iconBackground: 'bg-emerald-100',
      iconForeground: 'text-emerald-600',
      disabled: false
    },
    currentUser.hasPermission(
      EntityTypeEnum.Settings,
      PERMISSIONS[EntityTypeEnum.Settings].VIEW_ROLES
    ) && {
      name: 'Roles & Permissions',
      path: '/settings/roles',
      icon: Users,
      description: 'Configure user roles and permissions',
      iconBackground: 'bg-violet-100',
      iconForeground: 'text-violet-600',
      disabled: false
    },
    currentUser.hasPermission(
      EntityTypeEnum.Settings,
      PERMISSIONS[EntityTypeEnum.Settings].VIEW_TAGS
    ) && {
      name: 'Tags',
      path: '/settings/tags',
      icon: Tag,
      description: 'Configure system tags and categories',
      iconBackground: 'bg-amber-100',
      iconForeground: 'text-amber-600'
    },
    currentUser.hasPermission(
      EntityTypeEnum.Settings,
      PERMISSIONS[EntityTypeEnum.Settings].VIEW_SCRIPTS
    ) && {
      name: 'Admin Scripts',
      path: '/settings/scripts',
      icon: SquareTerminal,
      description: 'Scripts for admins to run (data imports etc)',
      iconBackground: 'bg-red-100',
      iconForeground: 'text-red-600',
      disabled: false
    },
    {
      name: 'Integrations',
      path: '/settings/integrations',
      icon: Link,
      description: 'Connect third-party services and apps',
      iconBackground: 'bg-rose-100',
      iconForeground: 'text-rose-600',
      disabled: false
    },
    currentUser.hasPermission(
      EntityTypeEnum.Settings,
      PERMISSIONS[EntityTypeEnum.Settings].VIEW_API
    ) && {
      name: 'API',
      path: '/settings/api',
      icon: Webhook,
      description: 'API keys and webhook configuration',
      iconBackground: 'bg-blue-100',
      iconForeground: 'text-blue-600',
      disabled: true
    }
    // {
    //   name: 'Brand Settings',
    //   path: '/settings/brand',
    //   icon: Layout,
    //   description: 'Customize your brand appearance',
    //   iconBackground: 'bg-orange-100',
    //   iconForeground: 'text-orange-600',
    //   disabled: true
    // }
  ].filter(Boolean);

  return (
    <div className='h-full mx-auto overflow-y-auto bg-base-100'>
      <div className='p-4 h-full w-full bg-white'>
        <Routes>
          <Route
            path='/*'
            element={<SettingsHome settingsCategories={settingsCategories} />}
          />
          <Route path='/globals/*' element={<GlobalSettings />} />
          <Route path='/team/*' element={<TeamMembers />} />
          <Route path='/roles/*' element={<Roles />} />
          <Route path='/tags/*' element={<TagsSettings />} />
          <Route path='/integrations/*' element={<Integrations />} />
          <Route path='/api/*' element={<div>API Settings</div>} />
          <Route path='/scripts/*' element={<Scripts />} />
          {/* <Route path='/brand/*' element={<div>Brand Settings</div>} /> */}
        </Routes>
      </div>
    </div>
  );
};

export default Settings;
