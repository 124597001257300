import { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import {
  FilledButton,
  SoftButton,
  TextButton,
  Toggle,
  Input,
  DropdownInput,
  RangeInput,
  MultiSelectBox,
  UserProfilePicture
} from 'core';
import {
  X,
  Filter,
  Save,
  Eye,
  EyeOff,
  Trash2,
  Pin,
  PinOff
} from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import SubTabs from '../navigation/FilterTabs';
import FilterModel from '../../models/Filter';
import { realtime } from '../../utilities/supabase';
import { UserProfileContext } from '../../App';
import { PERMISSIONS } from '../../utilities/Permissions';
import { debounce } from 'lodash';

const FiltersMenu = ({
  isOpen,
  onClose,
  filter,
  filters,
  setFilters,
  entityType,
  onPinnedFiltersChange,
  parentContext = null
}) => {
  const { userProfile: currentUser, setUserProfile } =
    useContext(UserProfileContext);
  const [localFilters, setLocalFilters] = useState({});
  const [globalFilters, setGlobalFilters] = useState([]);
  const [myFilters, setMyFilters] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [showSavePopup, setShowSavePopup] = useState(false);
  const [filterSearch, setFilterSearch] = useState('');

  const getCacheKey = () => {
    if (!parentContext) return entityType;
    return `${entityType}_${parentContext.type}`;
  };

  const pinnedFilters = useMemo(() => {
    const cacheKey = getCacheKey();
    return currentUser?.crmPreferences?.pinned_filters?.[cacheKey] || [];
  }, [currentUser, getCacheKey]);

  const location = useLocation();
  const navigate = useNavigate();

  //Fetches filters from the database
  const fetchFilters = async (params, setter) => {
    try {
      const { data } = await FilterModel.getAll(params, 1, 100, 'created_date');
      setter(data);
    } catch (error) {
      console.error('Error fetching filters:', error);
    }
  };

  // Initialize local filters from URL parameters or existing filters
  // This effect parses URL search parameters to restore filter state, handling special cases for distance filters
  // with lat/lng coordinates. Falls back to existing filters if no URL parameters exist.
  useEffect(() => {
    const initialFilters = filter.reduce((acc, { field }) => {
      if (filters[field]) {
        acc[field] = {
          value: filters[field].value,
          label: filters[field].label,
          operator: filters[field].operator,
          isType: filters[field].isType,
          friendlyValue: filters[field].friendlyValue,
          ...(filters[field].metadata && { metadata: filters[field].metadata })
        };
      }
      return acc;
    }, {});
    setLocalFilters(initialFilters);
  }, [filter, filters, location.search, entityType, currentUser?.id]);

  // Fetches and subscribes to filter updates
  // - Loads global filters for the entity type
  // - Loads user-specific filters if user is logged in
  // - Sets up realtime subscription to update filters when changes occur
  // - Cleans up subscription on unmount
  useEffect(() => {
    fetchFilters(
      { entity_type: { value: entityType }, status: { value: 123 } },
      setGlobalFilters
    );

    if (currentUser)
      fetchFilters(
        {
          entity_type: { value: entityType },
          owner: { operator: 'equals', value: currentUser.id }
        },
        setMyFilters
      );

    const filtersSubscription = realtime('*', 'filters', () => {
      fetchFilters(
        { entity_type: { value: entityType }, status: { value: 123 } },
        setGlobalFilters
      );
      if (currentUser) {
        fetchFilters(
          {
            entity_type: { value: entityType },
            owner: { operator: 'equals', value: currentUser.id }
          },
          setMyFilters
        );
      }
    });

    return () => filtersSubscription.unsubscribe();
  }, [entityType, currentUser?.id]);

  const updateUrlParams = newFilters => {
    const searchParams = new URLSearchParams(location.search);
    Object.entries(newFilters).forEach(([key, val]) => {
      if (val.value) {
        if (key === 'distance') {
          searchParams.set(key, `${val.operator}|${val.value}`);
          searchParams.set('lat', val.metadata.lat ?? 53.00630079443634);
          searchParams.set('lng', val.metadata.lng ?? -2.1651132351130746);
        } else {
          searchParams.set(key, `${val.operator}|${val.value}`);
        }
      } else {
        searchParams.delete(key);
        if (key === 'distance') {
          searchParams.delete('lat');
          searchParams.delete('lng');
        }
      }
    });
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true
    });
  };

  const handleInputChange = (field, value, operator = 'eq') => {
    setLocalFilters(prev => {
      const newFilters = { ...prev };
      if (Array.isArray(value)) {
        newFilters[field] = { value: value, operator };
      } else if (typeof value === 'object' && value !== null) {
        newFilters[field] = {
          value: { ...newFilters[field]?.value, ...value },
          operator
        };
      } else if (value) {
        newFilters[field] = { value: value, operator };
      } else {
        delete newFilters[field];
      }
      if (Object.keys(newFilters).length === 0) setShowSavePopup(false);
      return newFilters;
    });
  };

  const handleApply = filterData => {
    const appliedFilters = Object.entries(filterData).reduce(
      (acc, [key, value]) => {
        const filterItem = filter.find(f => f.field === key);
        if (key === 'distance') {
          acc[key] = {
            operator: 'lte',
            value: value.value.distance || value.value,
            label: filterItem ? filterItem.label : key,
            metadata: {
              lat: value.lat || 53.00630079443634,
              lng: value.lng || -2.1651132351130746
            }
          };
        } else {
          acc[key] = {
            operator: value.operator || filterItem?.operator || 'eq',
            value:
              typeof value.value === 'object' && !Array.isArray(value.value)
                ? JSON.stringify(value.value)
                : value.value,
            label: filterItem ? filterItem.label : key,
            isType: filterItem?.isType || false
          };
        }
        return acc;
      },
      {}
    );

    // Update user preferences with cached filters using the context-aware cache key
    if (currentUser) {
      const cacheKey = getCacheKey();
      const updatedPreferences = {
        ...currentUser.crmPreferences,
        cached_filters: {
          ...currentUser.crmPreferences?.cached_filters,
          [cacheKey]: appliedFilters
        }
      };
      currentUser.update({ crmPreferences: updatedPreferences });
    }

    onClose();
    updateUrlParams(appliedFilters);
    setFilters(appliedFilters);
  };

  const handleConfirmSave = async () => {
    try {
      if (currentUser && Object.keys(localFilters).length > 0) {
        const newFilter = new FilterModel({
          name: filterName,
          status: 124,
          entityType: entityType,
          owner: currentUser.id,
          filterData: localFilters
        });
        await newFilter.insert();
        setFilterName('');
        setShowSavePopup(false);
      }
    } catch (error) {
      console.error('Error saving filter:', error);
    }
  };

  const handleApplyFilter = filterData => {
    setLocalFilters(filterData);
    handleApply(filterData);
  };

  const handleToggleFilterVisibility = async (filter, currentStatus) => {
    try {
      const newStatus = currentStatus === 123 ? 124 : 123;
      await filter.update({ status: newStatus });
    } catch (error) {
      console.error('Error updating filter visibility:', error);
    }
  };

  const handleDeleteFilter = async filter => {
    try {
      // Delete the filter from database
      await filter.delete();

      // Remove from pinned filters if exists
      const cacheKey = getCacheKey();
      if (currentUser?.crmPreferences?.pinned_filters?.[cacheKey]) {
        const updatedPinnedFilters = currentUser.crmPreferences.pinned_filters[
          cacheKey
        ].filter(pinnedFilter => pinnedFilter.id !== filter.id);

        // Update user preferences
        currentUser.crmPreferences.pinned_filters[cacheKey] =
          updatedPinnedFilters;
        const newUser = await currentUser.update();
        setUserProfile(newUser);

        // Update local state
        setPinnedFilters(updatedPinnedFilters);
        if (onPinnedFiltersChange) {
          onPinnedFiltersChange(updatedPinnedFilters);
        }
      }
    } catch (error) {
      console.error('Error deleting filter:', error);
    }
  };

  const renderFilterList = (filterList, isGlobal = false) => (
    <div className='space-y-2'>
      <Input
        placeholder='Search filters...'
        value={filterSearch}
        onChange={e => setFilterSearch(e.target.value)}
        className='mb-4'
      />
      {filterList.length > 0 ? (
        filterList
          .filter(filter =>
            filter.name?.toLowerCase().includes(filterSearch.toLowerCase())
          )
          .map(savedFilter => (
            <div
              key={savedFilter.id}
              className='group relative flex items-center justify-between rounded-lg bg-white p-3 shadow-sm ring-1 ring-gray-200 hover:bg-gray-50'
              onClick={() => handleApplyFilter(savedFilter.filterData)}
            >
              <div className='min-w-0 flex-1'>
                <h5 className='text-sm font-medium text-gray-900'>
                  {savedFilter.name || 'Unnamed Filter'}
                </h5>
                <div className='mt-1 flex items-center gap-x-2'>
                  <UserProfilePicture
                    firstName={savedFilter.ownerDetails?.first_name}
                    lastName={savedFilter.ownerDetails?.last_name}
                    profilePicture={savedFilter.ownerDetails?.profile_photo}
                    size='xs'
                  />
                  <p className='text-xs text-gray-500'>
                    {savedFilter.ownerDetails?.first_name} •{' '}
                    {new Date(savedFilter.createdDate).toLocaleDateString()}
                  </p>
                </div>
              </div>
              <div className='ml-4 flex shrink-0 items-center gap-x-2'>
                <button
                  onClick={e => {
                    e.stopPropagation();
                    togglePinFilter(savedFilter);
                  }}
                  disabled={!isPinned(savedFilter) && pinnedFilters.length >= 5}
                  className={`group-hover:opacity-100 transition-opacity relative p-1 rounded hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed ${
                    !isPinned(savedFilter) ? 'opacity-0' : 'opacity-100'
                  }`}
                  title={
                    !isPinned(savedFilter) && pinnedFilters.length >= 5
                      ? 'Maximum of 5 pinned filters allowed'
                      : isPinned(savedFilter)
                      ? 'Unpin filter'
                      : 'Pin filter'
                  }
                >
                  {!isPinned(savedFilter) && pinnedFilters.length >= 5 ? (
                    <PinOff size={16} className='text-base-500' />
                  ) : isPinned(savedFilter) ? (
                    <>
                      <Pin
                        size={16}
                        className='text-primary-900 group-hover:hidden'
                      />
                      <PinOff
                        size={16}
                        className='text-danger-600 hidden group-hover:block'
                      />
                    </>
                  ) : (
                    <>
                      <Pin
                        size={16}
                        className='text-base-500 group-hover:hidden'
                      />
                      <Pin
                        size={16}
                        className='text-primary-900 hidden group-hover:block'
                      />
                    </>
                  )}
                </button>
                {!isGlobal && (
                  <>
                    <TextButton
                      onClick={e => {
                        e.stopPropagation();
                        handleToggleFilterVisibility(
                          savedFilter,
                          savedFilter.status
                        );
                      }}
                      colour='primary'
                      size='sm'
                      leftIcon={
                        savedFilter.status === 123 ? (
                          <Eye size={16} />
                        ) : (
                          <EyeOff size={16} />
                        )
                      }
                      className='opacity-0 group-hover:opacity-100 transition-opacity'
                    />
                    <TextButton
                      onClick={e => {
                        e.stopPropagation();
                        handleDeleteFilter(savedFilter);
                      }}
                      colour='primary'
                      size='sm'
                      leftIcon={<Trash2 size={16} />}
                      className='opacity-0 group-hover:opacity-100 transition-opacity'
                    />
                  </>
                )}
              </div>
            </div>
          ))
      ) : (
        <div className='rounded-lg border-2 border-dashed border-gray-200 p-12'>
          <div className='flex flex-col items-center'>
            <Filter className='h-8 w-8 text-gray-300' />
            <p className='mt-2 text-sm font-medium text-gray-500'>
              No filters found
            </p>
          </div>
        </div>
      )}
      {filterList.length > 0 && (
        <p className='text-xs text-gray-500 mt-2'>
          {pinnedFilters.length}/5 filters pinned
        </p>
      )}
    </div>
  );

  const togglePinFilter = async filterData => {
    if (!currentUser) return;
    const cacheKey = getCacheKey();

    const updatedPreferences = {
      ...currentUser.crmPreferences,
      pinned_filters: {
        ...currentUser.crmPreferences?.pinned_filters
      }
    };

    if (!updatedPreferences.pinned_filters) {
      updatedPreferences.pinned_filters = {};
    }

    if (!updatedPreferences.pinned_filters[cacheKey]) {
      updatedPreferences.pinned_filters[cacheKey] = [];
    }

    const currentPinned = updatedPreferences.pinned_filters[cacheKey];
    const filterIndex = currentPinned.findIndex(f => f.id === filterData.id);

    if (filterIndex === -1 && currentPinned.length < 5) {
      // Pin the filter
      currentPinned.push(filterData);
    } else if (filterIndex !== -1) {
      // Unpin the filter
      currentPinned.splice(filterIndex, 1);
    }

    const newUser = await currentUser.update({
      crmPreferences: updatedPreferences
    });
    setUserProfile(newUser);
    if (onPinnedFiltersChange) {
      onPinnedFiltersChange(currentPinned);
    }
  };

  const isPinned = filterData => {
    return pinnedFilters.some(f => f.id === filterData.id);
  };

  const canViewDeleted = PERMISSIONS[entityType]?.VIEW_DELETED
    ? currentUser.hasPermission(
        entityType,
        PERMISSIONS[entityType].VIEW_DELETED
      )
    : false;

  const geocodeAddress = async address => {
    if (!window.google?.maps) {
      console.error('Google Maps library not loaded');
      return { lat: null, lng: null };
    }

    try {
      const { Geocoder } = await google.maps.importLibrary('geocoding');
      const geocoder = new Geocoder();

      return new Promise((resolve, reject) => {
        geocoder.geocode({ address: address }, (results, status) => {
          if (status === 'OK' && results[0]) {
            const { lat, lng } = results[0].geometry.location.toJSON();
            console.log('resolving', lat, lng);
            resolve({ lat: lat, lng: lng });
          } else {
            console.error('Error geocoding address:', status);
            resolve({ lat: null, lng: null });
          }
        });
      });
    } catch (error) {
      console.error('Error geocoding address:', error);
      return { lat: null, lng: null };
    }
  };

  const debouncedGeocodeAddress = useCallback(
    debounce(async (address, field, operator) => {
      const coords = await geocodeAddress(address);
      console.log('coords', coords);
      handleInputChange(field, { ...coords }, operator);
    }, 1000),
    []
  );

  const renderFilterInput = ({
    field,
    label,
    hint,
    type,
    operator,
    options,
    isType
  }) => {
    const value = localFilters[field]?.value || '';
    const friendlyValue = localFilters[field]?.friendlyValue || '';
    if (field === 'showArchived' && !canViewDeleted) {
      return null;
    }

    if (field === 'status' && type === 'select' && !canViewDeleted) {
      options = options.filter(option => option.value !== 'Archived');
    }

    if (type === 'multiselect') {
      return (
        <MultiSelectBox
          key={field}
          label={label}
          placeholder={`Select ${label}...`}
          selectedOptions={
            value
              ? value.map(val => ({
                  value: val,
                  label: friendlyValue[value.indexOf(val)] || val
                }))
              : null
          }
          onChange={selected => {
            const values = selected.map(item => item.value);
            const friendlyValues = selected.map(item => item.label);
            handleInputChange(field, values, operator);
            setLocalFilters(prev => ({
              ...prev,
              [field]: {
                ...prev[field],
                friendlyValue: friendlyValues
              }
            }));
          }}
          loadOptions={async query => {
            return options
              .filter(opt =>
                opt.value.toLowerCase().includes(query.toLowerCase())
              )
              .map(opt => ({
                value: opt.key,
                label: opt.value
              }));
          }}
        />
      );
    } else if (type === 'select') {
      return (
        <DropdownInput
          key={field}
          id={field}
          name={field}
          label={label}
          value={value}
          onChange={e => handleInputChange(field, e.target.value, operator)}
          options={[
            { value: '', label: 'Select...' },
            ...options.map(option => ({
              value: option.key,
              key: option.key,
              label: option.value
            }))
          ]}
          isType={isType}
        />
      );
    } else if (type === 'daterange') {
      return (
        <div className='space-y-2'>
          <Input
            key={`${field}__from`}
            label={`${label} From`}
            value={value ? value.from : ''}
            onChange={e =>
              handleInputChange(field, { from: e.target.value }, operator)
            }
            type='date'
          />
          <Input
            key={`${field}__to`}
            label={`${label} To`}
            value={value ? value.to : ''}
            onChange={e =>
              handleInputChange(field, { to: e.target.value }, operator)
            }
            type='date'
          />
        </div>
      );
    } else if (type === 'toggle') {
      return (
        <Toggle
          key={field}
          checked={value}
          onChange={e => handleInputChange(field, e.target.checked, operator)}
          label={label}
          id={field}
          description={hint}
        />
      );
    } else if (type === 'conditional_toggle') {
      const currentValue = localFilters[field]?.value || '';
      return (
        <Toggle
          key={field}
          checked={currentValue === options.value}
          description={hint}
          onChange={checked => {
            if (checked) {
              handleInputChange(field, options.value, operator);
            } else {
              handleInputChange(field, '', operator);
            }
          }}
          label={label}
          id={field}
        />
      );
    } else if (type === 'distance') {
      return (
        <div className='space-y-2'>
          <Input
            key={`${field}__address`}
            label={`${label} Address`}
            onChange={e =>
              debouncedGeocodeAddress(e.target.value, field, operator)
            }
            type='text'
          />
          <RangeInput
            key={field}
            label={label}
            labelHint={`${value.distance || value || 50} miles`}
            value={value.distance || value || 50}
            intervals={[0, 5, 10, 15, 20, 30, 40, 50, 75, 100]}
            onChange={val =>
              handleInputChange(field, { distance: val }, operator)
            }
            min={0}
            max={100}
            step={1}
            hint={hint}
          />
        </div>
      );
    } else {
      return (
        <Input
          key={field}
          label={label}
          value={value}
          onChange={e => handleInputChange(field, e.target.value, operator)}
          type='text'
        />
      );
    }
  };

  const tabs = [
    {
      label: 'Filters',
      content: <div className='space-y-4'>{filter.map(renderFilterInput)}</div>
    },
    {
      label: 'Quick Filters',
      content: renderFilterList(globalFilters, true)
    },
    {
      label: 'My Filters',
      content: renderFilterList(myFilters)
    }
  ];

  return (
    <div
      className={`fixed z-20 top-0 right-0 h-full w-96 bg-white shadow-lg transform ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      } transition-transform duration-300 ease-in-out overflow-y-auto`}
    >
      <div className='p-4'>
        <div className='flex flex-col mb-3 sticky top-0 bg-white z-30'>
          <div className='flex justify-between items-center mb-2'>
            <h2 className='text-xl font-semibold text-neutral-800'>Filters</h2>
            <TextButton onClick={onClose} colour='base' size='sm'>
              <X size={24} />
            </TextButton>
          </div>
          <div className='flex space-x-2'>
            <FilledButton
              onClick={() => handleApply(localFilters)}
              colour='primary'
              className='flex-grow justify-center'
            >
              Apply
            </FilledButton>
            <SoftButton
              onClick={() => setShowSavePopup(!showSavePopup)}
              colour='primary'
              disabled={Object.keys(localFilters).length === 0}
            >
              <Save size={18} />
            </SoftButton>
          </div>
          {showSavePopup && (
            <div className='mt-2 bg-neutral-50 p-3 rounded-md'>
              <div>
                <Input
                  label='Filter Name'
                  value={filterName}
                  onChange={e => setFilterName(e.target.value)}
                  maxLength={25}
                />
              </div>
              <div className='mt-2 flex items-center justify-between'>
                <div className='flex items-center gap-2'>
                  <svg
                    height='20'
                    width='20'
                    viewBox='0 0 20 20'
                    className='text-primary-700'
                  >
                    <circle r='10' cx='10' cy='10' fill='#e9ecef' />
                    <circle
                      r='5'
                      cx='10'
                      cy='10'
                      fill='transparent'
                      stroke='currentColor'
                      strokeWidth='10'
                      strokeDasharray={`calc(${
                        (filterName.length / 25) * 100
                      } * 31.4 / 100) 31.4`}
                      transform='rotate(-90) translate(-20)'
                    />
                    <circle r='6' cx='10' cy='10' fill='white' />
                  </svg>
                  <span className='text-xs text-neutral-600'>
                    {filterName.length} / 25 characters
                  </span>
                </div>
                <div className='flex space-x-2'>
                  <TextButton
                    onClick={() => setShowSavePopup(false)}
                    colour='base'
                  >
                    Cancel
                  </TextButton>
                  <FilledButton onClick={handleConfirmSave} colour='primary'>
                    Save
                  </FilledButton>
                </div>
              </div>
            </div>
          )}
        </div>
        <SubTabs tabs={tabs} />
      </div>
    </div>
  );
};

export default FiltersMenu;
