import React, { useState, useEffect, useCallback } from 'react';
import { Upload, FileText } from 'lucide-react';
import { FilledButton, Popup } from 'core';
import { supabase } from '../../../utilities/supabase';
import axios from 'axios';
import Document from '../../../models/Document';

const FileUploader = ({
  onClose,
  onUploadSuccess,
  destinationFolder,
  connections,
  isOpen
}) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadSpeed, setUploadSpeed] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [fileName, setFileName] = useState('');
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    if (file) {
      setFileName(file.name);
    }
  }, [file]);

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleFileNameChange = event => {
    setFileName(event.target.value);
  };

  const handleDragEnter = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDrop = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);

    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
    }
  }, []);

  const formatFileSize = bytes => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const formatUploadSpeed = bytesPerSecond => {
    if (bytesPerSecond >= 1024 * 1024) {
      return `${(bytesPerSecond / 1024 / 1024).toFixed(2)} MB/s`;
    } else if (bytesPerSecond >= 1024) {
      return `${(bytesPerSecond / 1024).toFixed(2)} KB/s`;
    } else {
      return `${bytesPerSecond.toFixed(2)} B/s`;
    }
  };

  const formatTimeRemaining = seconds => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.ceil(seconds % 60);
    if (minutes > 0) {
      return `${minutes}m${remainingSeconds}s`;
    } else {
      return `${remainingSeconds}s`;
    }
  };

  const handleUpload = async () => {
    if (!file) return;

    setUploading(true);
    setProgress(0);

    let lastLoaded = 0;
    let lastUpdateTime = Date.now();

    try {
      const { data: signedUrlData, error: signedUrlError } =
        await supabase.functions.invoke('storage/getSigned/upload', {
          body: {
            fileName: fileName,
            originalFileName: file.name,
            destinationFolder: destinationFolder
          }
        });

      if (signedUrlError) throw signedUrlError;

      // Use the signed URL directly without preflight request
      const upload = axios.put(signedUrlData.url, file, {
        headers: {
          'Content-Type': file.type
        },
        onUploadProgress: progressEvent => {
          const currentTime = Date.now();
          const elapsedTime = (currentTime - lastUpdateTime) / 1000; // in seconds
          const loaded = progressEvent.loaded;
          const total = progressEvent.total;

          const percentCompleted = Math.round((loaded * 100) / total);
          setProgress(percentCompleted);

          if (elapsedTime > 0) {
            const loadDifference = loaded - lastLoaded;
            const speed = loadDifference / elapsedTime; // bytes per second
            setUploadSpeed(speed);

            const remainingBytes = total - loaded;
            const timeRemaining = remainingBytes / speed;
            setTimeRemaining(timeRemaining);

            lastUpdateTime = currentTime;
            lastLoaded = loaded;
          }
        }
      });

      try {
        const response = await upload;
        console.log(response);
      } catch (error) {
        console.error('Upload failed:', error);
        // Handle the error appropriately
      }
      const document = await Document.getById(signedUrlData.file.id);
      await document.update({ filePath: signedUrlData.file.key });
      if (connections) {
        await document.update({ connections });
      }
      onUploadSuccess();
      onClose();
    } catch (error) {
      console.error('Error uploading file:', error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setUploading(false);
    }
  };

  return (
    <Popup isOpen={isOpen} onClose={onClose} title='Upload File'>
      <div className='flex flex-col items-center space-y-4'>
        <input
          type='file'
          onChange={handleFileChange}
          className='hidden'
          id='fileInput'
        />
        <label
          htmlFor='fileInput'
          className={`cursor-pointer flex items-center justify-center w-full h-32 border-2 border-dashed rounded-lg transition-colors duration-300 ${
            isDragging
              ? 'border-brand-500 bg-brand-50'
              : 'border-neutral-300 hover:border-brand-500'
          }`}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <div className='text-center'>
            <Upload size={24} className='mx-auto text-neutral-400' />
            <p className='mt-2 text-sm text-neutral-600'>
              {file
                ? file.name
                : isDragging
                ? 'Drop file here'
                : 'Click to select a file or drag and drop'}
            </p>
          </div>
        </label>
        {file && (
          <div className='w-full'>
            <div className='flex items-center space-x-2'>
              <FileText size={20} className='text-neutral-400' />
              <input
                type='text'
                value={fileName}
                onChange={handleFileNameChange}
                className='flex-grow border rounded px-2 py-1'
              />
            </div>
            <p className='text-sm text-neutral-600 mt-2'>
              Size: {formatFileSize(file.size)} | Type: {file.type}
            </p>
          </div>
        )}
        {uploading && (
          <div className='w-full space-y-2'>
            <div className='bg-neutral-200 rounded-full h-2.5 dark:bg-neutral-700'>
              <div
                className='bg-brand-600 h-2.5 rounded-full'
                style={{ width: `${progress}%` }}
              ></div>
            </div>
            <div className='flex justify-between text-sm text-neutral-600'>
              <span>{`${progress}%`}</span>
              <span>{formatUploadSpeed(uploadSpeed)}</span>
              <span>{formatTimeRemaining(timeRemaining)}</span>
            </div>
          </div>
        )}
        <FilledButton
          onClick={handleUpload}
          disabled={!file || uploading}
          className='w-full'
          colour='primary'
          size='md'
        >
          {uploading ? 'Uploading...' : 'Upload'}
        </FilledButton>
      </div>
    </Popup>
  );
};

export default FileUploader;
