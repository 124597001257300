import React, { useState, useEffect, useContext } from 'react';
import { Eye, EyeOff, Send } from 'lucide-react';
import {
  FilledButton,
  TextEditor,
  SoftButton,
  ComboBox,
  Popup,
  renderIcon
} from 'core';
import CreateTemplate from './CreateTemplate';
import ActivityTemplate from '../../../models/ActivityTemplate';
import ActivityLog from '../../../models/ActivityLog';
import { supabase } from '../../../utilities/supabase';
import {
  Clock,
  AlertTriangle,
  Star,
  PinIcon,
  FileText,
  Phone,
  MessageCircle,
  Mail,
  ThumbsUp,
  Ampersand
} from 'lucide-react';
import { UserProfileContext } from '../../../App';

const CreateNote = ({ entityType, entityId, onClose, onSubmit }) => {
  const [activityType, setActivityType] = useState(119); // Default to 'Note'
  const [content, setContent] = useState('');
  const [isCreateTemplateOpen, setIsCreateTemplateOpen] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [isPrivate, setIsPrivate] = useState(true);
  const [isTemplateSelectorVisible, setIsTemplateSelectorVisible] =
    useState(false);
  const [editingTemplate, setEditingTemplate] = useState(null);

  const userProfile = useContext(UserProfileContext);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const { data } = await ActivityTemplate.getAll({
          type: { operator: 'eq', value: activityType }
        });
        setTemplates(data);
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    };

    fetchTemplates();

    const templatesChannel = supabase
      .channel('custom-all-channel')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'activity_templates' },
        () => fetchTemplates()
      )
      .subscribe();

    return () => {
      supabase.removeChannel(templatesChannel);
    };
  }, [activityType]);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const newActivity = new ActivityLog({
        auditType: { id: activityType },
        content,
        entityType: { id: entityType },
        entityId,
        internal: isPrivate,
        owner: { id: userProfile.userProfile.id }
      });
      const insertedActivity = await newActivity.insert();
      onSubmit(insertedActivity);
      onClose();
    } catch (error) {
      console.error('Error creating activity:', error);
    }
  };

  const handleCommentChange = newContent => {
    setContent(newContent);
  };

  const activityTypes = [
    { type: 119, label: 'Note' },
    { type: 120, label: 'Email' },
    { type: 121, label: 'Text' },
    { type: 122, label: 'Call' }
  ];

  const handleCreateTemplateSubmit = async templateData => {
    try {
      const newTemplate = new ActivityTemplate(templateData);
      await newTemplate.insert();
      setIsCreateTemplateOpen(false);
      setEditingTemplate(null);
    } catch (error) {
      console.error('Error creating template:', error);
    }
  };

  const handleTemplateClick = templateContent => {
    setContent(templateContent);
    setIsTemplateSelectorVisible(false);
  };

  const togglePrivate = () => {
    setIsPrivate(!isPrivate);
  };

  const handleDeleteTemplate = async (e, templateId) => {
    e.stopPropagation();
    try {
      const template = new ActivityTemplate({ id: templateId });
      await template.delete();
      setTemplates(templates.filter(t => t.id !== templateId));
    } catch (error) {
      console.error('Error deleting template:', error);
    }
  };

  const handleEditTemplate = async templateId => {
    try {
      const template = templates.find(t => t.id === templateId);
      if (template) {
        setIsCreateTemplateOpen(true);
        setEditingTemplate(template);
      }
    } catch (error) {
      console.error('Error editing template:', error);
    }
  };

  const icons = {
    clock: Clock,
    'alert-triangle': AlertTriangle,
    star: Star,
    pin: PinIcon,
    'file-text': FileText,
    phone: Phone,
    'message-circle': MessageCircle,
    mail: Mail,
    'thumbs-up': ThumbsUp,
    ampersand: Ampersand
  };

  const footer = (
    <div className='flex justify-end gap-x-4'>
      <SoftButton
        onClick={togglePrivate}
        colour={isPrivate ? 'base' : 'danger'}
        size='md'
        leftIcon={isPrivate ? <EyeOff size={18} /> : <Eye size={18} />}
      >
        {isPrivate ? 'Private' : 'Public'}
      </SoftButton>
      <FilledButton onClick={handleSubmit} colour='primary' size='md'>
        Send
      </FilledButton>
    </div>
  );

  return (
    <Popup
      isOpen={true}
      onClose={onClose}
      title='Create Activity'
      size='md'
      footer={footer}
    >
      <div className='flex flex-col'>
        <div className='flex justify-between mb-4'>
          <div className='flex space-x-2'>
            {activityTypes.map(({ type, label }) => (
              <SoftButton
                key={label}
                onClick={() => setActivityType(type)}
                colour={activityType == type ? 'brand' : 'base'}
              >
                {type === 119 ? (
                  <>
                    <FileText size={18} className='mr-2' />
                    <span>Note</span>
                  </>
                ) : type === 120 ? (
                  <>
                    <Mail size={18} className='mr-2' />
                    <span>Email</span>
                  </>
                ) : type === 121 ? (
                  <>
                    <MessageCircle size={18} className='mr-2' />
                    <span>Text</span>
                  </>
                ) : type === 122 ? (
                  <>
                    <Phone size={18} className='mr-2' />
                    <span>Call</span>
                  </>
                ) : null}
              </SoftButton>
            ))}
          </div>

          <ComboBox
            loadOptions={query => {
              return templates
                .filter(template =>
                  template.title.toLowerCase().includes(query.toLowerCase())
                )
                .map(template => ({
                  value: template.id,
                  label: template.title,
                  icon: {
                    colour: template.colour,
                    icon: template.icon,
                    render: () => renderIcon(template.icon, template.colour)
                  },
                  button: {
                    name: 'Edit'
                  }
                }));
            }}
            placeholder='Search Templates...'
            onChange={selectedOption => {
              console.log('Selected Option:', selectedOption);
              console.log('Selected Option Type:', selectedOption?.type);
              console.log('Selected Option Value:', selectedOption?.value);

              if (!selectedOption) {
                console.log('No option selected');
                return;
              }

              if (selectedOption?.type == 'edit') {
                console.log('Handling edit template');
                return handleEditTemplate(selectedOption?.value);
              }

              if (selectedOption?.type == 'create') {
                console.log('Opening create template modal');
                return setIsCreateTemplateOpen(true);
              }

              const selectedTemplate = templates.find(
                t => t.id == selectedOption.value
              );
              console.log('Found template:', selectedTemplate);

              if (selectedTemplate) {
                console.log('Applying template content');
                handleTemplateClick(selectedTemplate.content);
              }
            }}
            value={null}
            colour='primary'
            className='justify-end'
            allowedCreate='Create new Template...'
          />
        </div>
        <TextEditor
          value={content}
          onChange={handleCommentChange}
          className='mb-4'
        />
        <div
          className={`flex ${
            isTemplateSelectorVisible ? 'justify-between' : 'justify-end'
          } items-center`}
        ></div>
        {isCreateTemplateOpen && (
          <CreateTemplate
            onClose={() => {
              setIsCreateTemplateOpen(false);
              setEditingTemplate(null);
            }}
            edit={editingTemplate}
            activityType={activityType}
          />
        )}
      </div>
    </Popup>
  );
};

export default CreateNote;
