import React, { useState, useEffect } from 'react';
import AdditionalItem from '../../../../models/AdditionalItem';
import EntityTable from '../../../../components/tables/EntityTable';
import { formatDate } from '../../../../utilities/Formatting';
import { Link, Pencil, Plus, SquareArrowOutUpRight, Trash } from 'lucide-react';
import { EntityTypeEnum } from '../../../../utilities/Enumerables';
import CoreEntityTypes from '../../../../models/CoreEntityType';
import AddAdditionalItem from '../../../../components/popups/bookings/AddAdditionalItem';
import EditAdditionalItem from '../../../../components/popups/bookings/EditAdditionalItem';
import LinkInvoice from '../../../../components/popups/bookings/LinkInvoice';
import { SoftButton, Modal } from 'core';

const AdditionalItems = ({ bookingId }) => {
  const [itemTypeOptions, setItemTypeOptions] = useState([]);
  const [isAddItemOpen, setIsAddItemOpen] = useState(false);
  const [isEditItemOpen, setIsEditItemOpen] = useState(false);
  const [isLinkInvoiceOpen, setIsLinkInvoiceOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const itemTypes = await CoreEntityTypes.getByEntityId(
          EntityTypeEnum.AdditionalItemType
        );

        setItemTypeOptions(
          itemTypes.map(option => ({
            key: option.id,
            value: option.type
          }))
        );
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, []);

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/additional-items/${data.id}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => {
        setSelectedItemId(data.id);
        setIsEditItemOpen(true);
      }
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  const handleNewClick = () => {
    setIsAddItemOpen(true);
  };

  const handleCloseAddItem = () => {
    setIsAddItemOpen(false);
  };

  const handleItemAdded = newItem => {
    // Handle the newly added item, e.g., refresh the table
    console.log('New item added:', newItem);
  };

  const handleOpenLinkInvoice = itemId => {
    setSelectedItemId(itemId);
    setIsLinkInvoiceOpen(true);
  };

  const handleCloseCreateInvoice = () => {
    setIsLinkInvoiceOpen(false);
    setSelectedItemId(null);
  };

  const handleOpenConfirmModal = () => {
    setIsConfirmModalOpen(true);
  };

  const handleCloseConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const handleConfirmCreateInvoice = () => {
    setIsConfirmModalOpen(false);
    // Add logic here to create a new invoice
    console.log('Creating new invoice');
  };

  const handleRowClick = row => {
    setSelectedItemId(row.id);
    setIsEditItemOpen(true);
  };

  const handleCloseEditItem = () => {
    setIsEditItemOpen(false);
    setSelectedItemId(null);
  };

  const handleItemUpdated = updatedItem => {
    // Handle the updated item, e.g., refresh the table
    console.log('Item updated:', updatedItem);
  };

  return (
    <>
      <EntityTable
        parentContext={{ type: 'bookings' }}
        name='Additional Items'
        filter={[
          { field: 'id', label: 'ID', type: 'number' },
          { field: 'invoice_id', label: 'Invoice ID', type: 'number' },
          { field: 'description', label: 'Description', type: 'text' },
          {
            field: 'item_type',
            label: 'Item Type',
            type: 'select',
            options: itemTypeOptions
          },
          { field: 'created_date', label: 'Created Date', type: 'date' }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id'
          },
          {
            label: 'Invoice ID',
            field: 'invoiceId',
            cell: (value, row) => {
              if (value) {
                return value;
              } else {
                return (
                  <div className='flex flex-row space-x-1'>
                    <SoftButton
                      colour='primary'
                      size='xs'
                      onClick={e => {
                        e.stopPropagation();
                        handleOpenConfirmModal();
                      }}
                    >
                      <Plus />
                    </SoftButton>
                    <SoftButton
                      colour='primary'
                      size='xs'
                      onClick={e => {
                        e.stopPropagation();
                        handleOpenLinkInvoice(row.id);
                      }}
                    >
                      <Link />
                    </SoftButton>
                  </div>
                );
              }
            }
          },
          { label: 'Description', field: 'description' },
          {
            label: 'Item Type',
            field: 'itemType.name',
            foreignKey: { table: 'item_type', column: 'type' }
          },
          { label: 'VAT Rate', field: 'vatRate' },
          {
            label: 'Amount',
            field: 'amount'
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={AdditionalItem}
        entityType={EntityTypeEnum.AdditionalItem}
        defaultFilters={{ booking: { value: bookingId } }}
        onNewClick={handleNewClick}
        canCreate={true}
        onRowClick={handleRowClick}
      />
      {isAddItemOpen && bookingId && (
        <AddAdditionalItem
          isOpen={isAddItemOpen}
          onClose={handleCloseAddItem}
          bookingId={bookingId}
          onItemAdded={handleItemAdded}
        />
      )}
      {isEditItemOpen && selectedItemId && (
        <EditAdditionalItem
          isOpen={isEditItemOpen}
          onClose={handleCloseEditItem}
          bookingId={bookingId}
          itemId={selectedItemId}
          onItemUpdated={handleItemUpdated}
        />
      )}
      <LinkInvoice
        isOpen={isLinkInvoiceOpen}
        onClose={handleCloseCreateInvoice}
        additionalItemId={selectedItemId}
      />
      <Modal
        isOpen={isConfirmModalOpen}
        onClose={handleCloseConfirmModal}
        title='Create New Invoice'
        actions={[
          {
            label: 'Create Invoice',
            onClick: handleConfirmCreateInvoice,
            style: 'primary'
          },
          {
            label: 'Cancel',
            onClick: handleCloseConfirmModal,
            style: 'base'
          }
        ]}
      >
        <p>
          Are you sure you want to create a new invoice for this additional
          item?
        </p>
      </Modal>
    </>
  );
};

export default AdditionalItems;
