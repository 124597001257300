import {
  Hash,
  User as UserIcon,
  Building,
  GraduationCap,
  Users as UsersIcon,
  CircleFadingArrowUp,
  CalendarHeart,
  MapPinCheck,
  Info,
  FilePen,
  Award,
  Monitor,
  CheckCircle
} from 'lucide-react';
import { Alert } from 'core';
import { useParams } from 'react-router-dom';
import { useCallback, useContext, useEffect, useState } from 'react';
import Entity from '../../../components/entity/Entity';
import Quote from '../../../models/Quote';
import UserProfile from '../../../models/UserProfile';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import DateCard from '../../../components/entity/DateCard';
import { UserProfileContext } from '../../../App';
import { PERMISSIONS } from '../../../utilities/Permissions';
import { supabase } from '../../../utilities/supabase';
import QuoteOptions from './tabs/QuoteOptions';
import Course from '../../../models/Course';
import CoreEntityType from '../../../models/CoreEntityType';

const QuoteDetails = () => {
  const { id } = useParams();
  const { userProfile: currentUser } = useContext(UserProfileContext);
  const [experienceLevels, setExperienceLevels] = useState([]);
  const [quote, setQuote] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    const fetchQuote = async () => {
      const data = await Quote.getById(id);
      setQuote(data);
    };
    fetchQuote();
  }, [id]);

  useEffect(() => {
    const fetchOptions = async () => {
      const expLevels = await CoreEntityType.getByEntityId(
        EntityTypeEnum.QuoteExperienceLevel
      );
      const statuses = await CoreEntityType.getByEntityId(
        EntityTypeEnum.QuoteStatus
      );

      setExperienceLevels(
        expLevels.map(level => ({
          value: level.id,
          label: level.type
        }))
      );

      setStatusOptions(
        statuses
          .sort((a, b) => a.metadata?.order - b.metadata?.order)
          .map(status => ({
            value: status.id,
            label: status.type
          }))
      );
    };

    fetchOptions();
  }, []);

  if (
    !currentUser.hasPermission(
      EntityTypeEnum.Quote,
      PERMISSIONS[EntityTypeEnum.Quote].VIEW
    )
  ) {
    return (
      <Alert
        title='Permission Denied'
        description='You do not have permission to view quotes.'
        style='danger'
      />
    );
  }

  const userCanEdit = currentUser.hasPermission(
    EntityTypeEnum.Quote,
    PERMISSIONS[EntityTypeEnum.Quote].EDIT
  );

  const loadBookerOptions = async (searchTerm, page = 1) => {
    if (!quote?.company?.id) return [];

    const { data: users } = await UserProfile.getAll(
      {
        fts: {
          operator: FilterOperatorEnum.TEXTSEARCH,
          value: searchTerm
        },
        company_id: {
          value: quote.company.id
        }
      },
      page
    );

    return users.map(user => ({
      value: user.id,
      label: `${user.firstName} ${user.lastName}`,
      secondaryLabel: `${user.email}`
    }));
  };

  const loadCourseOptions = async searchTerm => {
    if (!searchTerm) return [];

    const { data: courses } = await Course.getAll(
      {
        fts: {
          operator: FilterOperatorEnum.TEXTSEARCH,
          value: searchTerm
        }
      },
      1,
      10
    );

    return courses.map(course => ({
      value: course.id,
      label: course.name
    }));
  };

  const resolveBookers = useCallback(async bookerIds => {
    if (!bookerIds?.length) return [];

    try {
      const { data: users } = await UserProfile.getAll({
        id: {
          operator: FilterOperatorEnum.IN,
          value: bookerIds
        }
      });

      return users.map(user => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`
      }));
    } catch (error) {
      console.error('Error resolving bookers:', error);
      return [];
    }
  }, []);

  const resolveDeliveryMethods = useCallback(async methodIds => {
    if (!methodIds?.length) return [];

    try {
      const { data: methods } = await CoreEntityType.getAll({
        id: {
          operator: FilterOperatorEnum.IN,
          value: methodIds
        }
      });

      return methods.map(method => ({
        value: method.id,
        label: method.type
      }));
    } catch (error) {
      console.error('Error resolving delivery methods:', error);
      return [];
    }
  }, []);

  const resolveAccreditations = useCallback(async accreditationIds => {
    if (!accreditationIds?.length) return [];

    try {
      const { data: accreditations } = await CoreEntityType.getAll({
        id: {
          operator: FilterOperatorEnum.IN,
          value: accreditationIds
        }
      });

      return accreditations.map(accreditation => ({
        value: accreditation.id,
        label: accreditation.type
      }));
    } catch (error) {
      console.error('Error resolving accreditations:', error);
      return [];
    }
  }, []);

  const infoItems = [
    {
      icon: CheckCircle,
      label: 'Status',
      displayField: 'status.name',
      editField: 'status.id',
      isEditable: userCanEdit,
      dataType: 'select',
      options: statusOptions,
      inlineLabel: true,
      inlineEditor: true
    },
    {
      icon: FilePen,
      label: 'Quote Type',
      field: 'fullProposal',
      isEditable: true,
      dataType: 'select',
      valueResolver: value => {
        return value ? 'Full Proposal' : 'Price Check';
      },
      options: [
        { value: false, label: 'Price Check' },
        { value: true, label: 'Full Proposal' }
      ]
    },
    {
      icon: Building,
      label: 'Company',
      editField: 'companyId',
      displayField: 'company.name',
      isEditable: false
    },
    {
      icon: Hash,
      label: 'Account Number',
      field: 'company.accountNumber',
      isEditable: false
    },
    {
      icon: UserIcon,
      label: 'Bookers',
      field: 'bookers',
      isEditable: userCanEdit,
      dataType: 'multi-select',
      loadOptions: loadBookerOptions,
      valueResolver: resolveBookers
    },
    {
      icon: GraduationCap,
      label: 'Course',
      field: 'courseName',
      isEditable: userCanEdit,
      dataType: 'autocomplete',
      loadOptions: loadCourseOptions
    },
    {
      icon: Award,
      label: 'Accreditations',
      field: 'accreditations',
      isEditable: userCanEdit,
      dataType: 'multi-select',
      loadOptions: async searchTerm => {
        const { data } = await supabase
          .from('core_entity_types')
          .select('id, type')
          .eq('entity_id', EntityTypeEnum.AccreditationBody)
          .ilike('type', `%${searchTerm}%`);
        return data.map(item => ({
          value: item.id,
          label: item.type
        }));
      },
      valueResolver: resolveAccreditations
    },
    {
      icon: Monitor,
      label: 'Delivery Methods',
      field: 'deliveryMethods',
      isEditable: userCanEdit,
      dataType: 'multi-select',
      loadOptions: async searchTerm => {
        const { data } = await supabase
          .from('core_entity_types')
          .select('id, type')
          .eq('entity_id', EntityTypeEnum.CourseDelivery)
          .ilike('type', `%${searchTerm}%`);
        return data.map(item => ({
          value: item.id,
          label: item.type
        }));
      },
      valueResolver: resolveDeliveryMethods
    },
    {
      icon: UsersIcon,
      label: 'Spaces',
      field: 'delegates',
      isEditable: userCanEdit,
      dataType: 'number'
    },
    {
      icon: CircleFadingArrowUp,
      label: 'Experience Level',
      displayField: 'expLevel.name',
      editField: 'expLevel.id',
      isEditable: userCanEdit,
      dataType: 'select',
      options: experienceLevels
    },
    {
      icon: CalendarHeart,
      label: 'Date Preferences',
      field: 'datePrefs',
      isEditable: userCanEdit
    },
    {
      icon: MapPinCheck,
      label: 'Locations',
      field: 'locations',
      isEditable: userCanEdit
    },
    {
      icon: Info,
      label: 'Further Info',
      field: 'furtherInfo',
      isEditable: userCanEdit
    }
  ];

  const accordionItems = [
    {
      title: 'Key Dates',
      content: ({ data, onUpdate }) => (
        <div className='grid grid-cols-2 md:grid-cols-3 gap-2'>
          <DateCard
            date={data.createdDate}
            label='Created Date'
            isEditable={false}
          />
          <DateCard
            date={data.updatedDate}
            label='Updated Date'
            isEditable={false}
          />
          <DateCard
            date={data.requestedDate}
            label='Requested Date'
            isEditable={userCanEdit}
            onUpdate={(field, value) => onUpdate('requestedDate', value)}
          />
          <DateCard
            date={data.lastChaseDate}
            label='Last Chase'
            isEditable={userCanEdit}
            onUpdate={(field, value) => onUpdate('lastChaseDate', value)}
          />
          <DateCard
            date={data.nextChaseDate}
            label='Next Chase'
            isEditable={userCanEdit}
            onUpdate={(field, value) => onUpdate('nextChaseDate', value)}
          />
          <DateCard
            date={data.readyDate}
            label='Ready Date'
            isEditable={userCanEdit}
            onUpdate={(field, value) => onUpdate('readyDate', value)}
          />
          <DateCard
            date={data.sentDate}
            label='Sent Date'
            isEditable={userCanEdit}
            onUpdate={(field, value) => onUpdate('sentDate', value)}
          />
          <DateCard
            date={data.expiryDate}
            label='Expiry Date'
            isEditable={userCanEdit}
            onUpdate={(field, value) => onUpdate('expiryDate', value)}
          />
          <DateCard
            date={data.completedDate}
            label='Completed Date'
            isEditable={userCanEdit}
            onUpdate={(field, value) => onUpdate('completedDate', value)}
          />
        </div>
      )
    }
  ];

  const additionalTabs = [
    {
      path: 'quote-options',
      label: 'Quote Options',
      content: <QuoteOptions quoteId={id} />
    }
  ];

  const progressItems = {
    title: 'Quote Progress',
    steps: statusOptions.map(status => ({
      label: status.label,
      status: 'upcoming'
    })),
    getValue: entity => {
      const currentStatusIndex = statusOptions.findIndex(
        status => status.value === entity?.status.id
      );
      return currentStatusIndex + 1;
    }
  };

  return (
    <Entity
      currentUser={currentUser}
      entityType={EntityTypeEnum.Quote}
      model={Quote}
      infoItems={infoItems}
      accordionItems={accordionItems}
      additionalTabs={additionalTabs}
      progressItems={progressItems}
    />
  );
};

export default QuoteDetails;
