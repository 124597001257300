import React, { useState, useEffect } from 'react';
import Booking from '../../../models/Booking';
import CoreEntityType from '../../../models/CoreEntityType';
import EntityTable from '../../../components/tables/EntityTable';
import { formatDate } from '../../../utilities/Formatting';
import { Link, useNavigate } from 'react-router-dom';
import {
  ListRestart,
  Pencil,
  SquareArrowOutUpRight,
  Trash
} from 'lucide-react';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import { useContext } from 'react';
import { UserProfileContext } from '../../../App';
import UserProfile from '../../../models/UserProfile';
import { PERMISSIONS } from '../../../utilities/Permissions';
import { Alert, useNotification } from 'core';
import Invoice from '../../../models/Invoice';
import { Modal } from 'core';
import { Input } from 'core';

const Bookings = () => {
  //Set the page title
  document.title = 'Bookings | CRM | BOHSA LTD';
  const [statusOptions, setStatusOptions] = useState([]);
  const [subStatusOptions, setSubStatusOptions] = useState([]);
  const [isResitModalOpen, setIsResitModalOpen] = useState(false);
  const [resitSpaces, setResitSpaces] = useState(1);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const navigate = useNavigate();
  const { addNotification } = useNotification();

  const { userProfile: currentUser } = useContext(UserProfileContext);
  //Test user permissions for view access
  if (
    !currentUser.hasPermission(
      EntityTypeEnum.Booking,
      PERMISSIONS[EntityTypeEnum.Booking].VIEW
    )
  ) {
    return (
      <Alert
        title='Permission Denied'
        description='You do not have permission to view bookings.'
        style='danger'
      />
    );
  }

  const userCanEdit = currentUser.hasPermission(
    EntityTypeEnum.Booking,
    PERMISSIONS[EntityTypeEnum.Booking].EDIT
  );

  const userCanDelete = currentUser.hasPermission(
    EntityTypeEnum.Booking,
    PERMISSIONS[EntityTypeEnum.Booking].DELETE
  );

  useEffect(() => {
    const fetchStatusOptions = async () => {
      try {
        const { data: statuses } = await CoreEntityType.getAll({
          entity_id: { operator: 'eq', value: EntityTypeEnum.BookingStatus }
        });
        const { data: subStatuses } = await CoreEntityType.getAll({
          entity_id: { operator: 'eq', value: EntityTypeEnum.BookingSubStatus }
        });
        setStatusOptions(
          statuses.map(status => ({ key: status.id, value: status.type }))
        );
        setSubStatusOptions(
          subStatuses.map(status => ({ key: status.id, value: status.type }))
        );
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    fetchStatusOptions();
  }, []);

  const handleCreateResit = async () => {
    try {
      const data = selectedBooking;
      //Get the original data's invoice and check if it is not published, we can link it if it isn't
      let originalInvoice = null;
      if (data.invoiceId) {
        const invoice = await Invoice.getById(data.invoiceId);
        if (invoice && invoice.status.id != 219 && invoice.status.id != 220) {
          originalInvoice = invoice.id;
        }
      }

      const resitData = {
        company_id: data.company.id,
        contacts: data.contacts,
        course_id: data.course.id,
        delivery_type: { id: data.deliveryType.id },
        venue_id: data.venue ? data.venue.id : null,
        trainer_id: data.trainer ? data.trainer.id : null,
        levy: data.levy,
        invoice_id: originalInvoice,
        parent_id: data.id, // Set the original booking as the parent,
        status: { id: 141 },
        sub_status: { id: 146 },
        owner: { id: data.owner.id },
        spaces: resitSpaces
      };
      let resit = new Booking(resitData);
      resit = await resit.insert();
      console.log(resit);
      navigate(`/bookings/${resit.id}`);
    } catch (error) {
      console.error('Error creating resit:', error);
    }
  };

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/bookings/${data.id}`, '_blank')
    },
    {
      label: 'Create Resit',
      icon: <ListRestart size={14} />,
      onClick: data => {
        setSelectedBooking(data);
        setIsResitModalOpen(true);
      }
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => navigate(`/bookings/${data.id}`)
    },
    userCanDelete && {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: async data => {
        try {
          const booking = await Booking.getById(data.id);
          await booking.update({ status: { id: 243 } });
          addNotification({
            type: 'success',
            title: 'The booking status was set to archived.'
          });
        } catch (err) {
          addNotification({
            type: 'error',
            title: 'Error deleting booking',
            description: err.message
          });
        }
      },
      show: userCanDelete
    }
  ];

  const handleNewClick = () => {
    navigate('/bookings/create');
  };

  const handleRowClick = data => {
    navigate(`/bookings/${data.id}`);
  };

  return (
    <div className='p-4'>
      <EntityTable
        name='Bookings'
        defaultFilters={{
          status: { value: 243, operator: FilterOperatorEnum.NOT_EQUALS }
        }}
        filter={[
          { field: 'id', label: 'ID', type: 'number' },
          {
            field: 'companies.name',
            label: 'Company Name',
            type: 'text',
            operator: 'ilike'
          },
          {
            field: 'trainers.name',
            label: 'Trainer Name',
            type: 'text',
            operator: 'ilike'
          },
          {
            field: 'courses.name',
            label: 'Course Name',
            type: 'text',
            operator: 'ilike'
          },
          {
            field: 'status',
            label: 'Status',
            type: 'select',
            options: statusOptions
          },
          {
            field: 'sub_status',
            label: 'Sub Status',
            type: 'select',
            options: subStatusOptions
          },
          {
            field: 'booking_date',
            label: 'Booking Date',
            type: 'daterange',
            operator: 'between'
          },
          {
            field: 'created_date',
            label: 'Created Date',
            type: 'daterange',
            operator: 'between'
          },
          {
            field: 'updated_date',
            label: 'Updated Date',
            type: 'daterange',
            operator: 'between'
          },
          {
            field: 'booking_items_incomplete',
            label: 'Incomplete Items',
            hint: 'Show only bookings with incomplete items',
            type: 'conditional_toggle',
            operator: 'gt',
            options: {
              value: '0'
            }
          },
          {
            field: 'showArchived',
            label: 'Show Archived',
            hint: 'Include archived bookings',
            type: 'conditional_toggle',
            operator: 'is',
            options: {
              value: 'true'
            }
          }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id',
            cell: value => (
              <Link
                to={`/bookings/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          {
            label: 'Company',
            field: 'company.name',
            foreignKey: { table: 'companies', column: 'name' }
          },
          {
            label: 'Trainer',
            field: 'trainer.name',
            foreignKey: { table: 'trainers', column: 'name' }
          },
          {
            label: 'Course',
            field: 'course.name',
            foreignKey: { table: 'courses', column: 'name' }
          },
          {
            label: 'Status',
            field: 'status.name',
            foreignKey: { table: 'status', column: 'type' }
          },
          { label: 'Spaces', field: 'spaces' },
          {
            label: 'Booking Date',
            field: 'bookingDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={Booking}
        entityType={EntityTypeEnum.Booking}
        onRowClick={handleRowClick}
        onNewClick={handleNewClick}
        canCreate={true}
      />
      <Modal
        isOpen={isResitModalOpen}
        onClose={() => setIsResitModalOpen(false)}
        title='Create Resit Booking'
        actions={[
          {
            label: 'Create Resit',
            onClick: () => {
              handleCreateResit();
              setIsResitModalOpen(false);
            },
            style: 'primary'
          },
          {
            label: 'Cancel',
            onClick: () => setIsResitModalOpen(false),
            style: 'base'
          }
        ]}
      >
        <p>Are you sure you want to create a resit booking?</p>
        <Input
          label='Number of delegate spaces'
          type='number'
          min='1'
          value={resitSpaces}
          onChange={e => setResitSpaces(parseInt(e.target.value))}
        />
      </Modal>
    </div>
  );
};

export default Bookings;
