import { useState, useCallback } from 'react';
import { supabase } from '../../../utilities/supabase';
import { PrimaryInput } from '../../../components/inputs/Forms';
import { FilledButton, OutlineButton } from 'core';
import Auth from '../Auth';
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [hasStarted, setHasStarted] = useState({
    email: false,
    password: false
  });

  const navigate = useNavigate();

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setHasStarted(prev => ({ ...prev, [name]: true }));

    if (name === 'email') {
      const isValid = validateEmail(value);
      setFormErrors(prev => ({
        ...prev,
        email: isValid ? null : 'A valid email is required'
      }));
    } else if (name === 'password' && value.trim()) {
      setFormErrors(prev => {
        const { [name]: _, ...rest } = prev;
        return rest;
      });
    }
  };

  const validateEmail = email => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleLogin = useCallback(
    async e => {
      e.preventDefault();
      setLoading(true);
      setError(null);
      setFormErrors({});

      const errors = {};
      if (!formData.email.trim()) errors.email = 'Email is required';
      else if (!validateEmail(formData.email))
        errors.email = 'A valid email is required';

      if (!formData.password.trim()) errors.password = 'Password is required';

      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        setLoading(false);
        return;
      }

      try {
        const { error } = await supabase.auth.signInWithPassword({
          email: formData.email,
          password: formData.password
        });

        if (error) throw error;
        navigate('/', { replace: true });
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [formData]
  );

  // const handleMicrosoftLogin = useCallback(async () => {
  //   setLoading(true);
  //   setError(null);

  //   try {
  //     const { error } = await supabase.auth.signInWithOAuth({
  //       provider: 'azure',
  //       options: {
  //         scopes: 'email profile User.Read openid',
  //         redirectTo: `${window.location.origin}`
  //       }
  //     });

  //     if (error) throw error;
  //     navigate('/', { replace: true });
  //   } catch (error) {
  //     setError(error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, []);

  return (
    <>
      <Auth title='Welcome Back' onSubmit={handleLogin} type='login'>
        <PrimaryInput
          id='email'
          name='email'
          type='text'
          placeholder='Enter your email'
          value={formData.email}
          onChange={handleInputChange}
          label='Email'
          error={formErrors.email}
        />
        <PrimaryInput
          id='password'
          name='password'
          type='password'
          placeholder='Enter your password'
          value={formData.password}
          onChange={handleInputChange}
          label='Password'
          error={formErrors.password}
        />
        <div className='flex items-center justify-between'>
          <div className='flex items-center'>
            <input
              id='remember-me'
              type='checkbox'
              className='h-4 w-4 text-brand-700 focus:ring-brand-700 border-neutral-300 rounded'
            />
            <label
              htmlFor='remember-me'
              className='ml-2 block text-sm text-neutral-600'
            >
              Remember me
            </label>
          </div>
          <div className='text-sm'>
            <Link
              to='/password'
              className='font-medium text-brand-700 hover:text-brand-800 transition-colors duration-300'
            >
              Forgot Password?
            </Link>
          </div>
        </div>
        {error && <p className='text-danger-700 text-sm'>{error}</p>}
        <div>
          <FilledButton
            type='submit'
            disabled={loading}
            className='w-full'
            colour='primary'
            size='md'
          >
            {loading ? 'Logging in...' : 'Log In'}
          </FilledButton>
        </div>
        <div className='mt-6'>
          {/* <OutlineButton
            onClick={handleMicrosoftLogin}
            disabled={loading}
            className='w-full'
            colour='base'
            size='md'
          >
            {loading ? 'Logging in...' : 'Log In with Microsoft'}
          </OutlineButton> */}
        </div>
      </Auth>
    </>
  );
};

export default Login;
