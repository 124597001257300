import React, { useEffect, useState } from 'react';
import { Pencil, SquareArrowOutUpRight, Trash } from 'lucide-react';
import Location from '../../../../models/Location';
import EntityTable from '../../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import CoreEntityType from '../../../../models/CoreEntityType';
import AddLocation from '../../../../components/popups/locations/AddLocation';
import EditLocation from '../../../../components/popups/locations/EditLocation';
import { Modal, Badge } from 'core';

const Offices = ({ companyId }) => {
  const [isAddOfficeOpen, setIsAddOfficeOpen] = useState(false);
  const [isEditOfficeOpen, setIsEditOfficeOpen] = useState(false);
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [officeTypes, setOfficeTypes] = useState([]);
  const [locationSubTypes, setLocationSubTypes] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [officeToDelete, setOfficeToDelete] = useState(null);

  useEffect(() => {
    const fetchOfficeTypes = async () => {
      try {
        const types = await CoreEntityType.getByEntityId(
          EntityTypeEnum.LocationStatus
        );
        const formattedTypes = types.map(type => ({
          value: type.type,
          key: type.id
        }));
        setOfficeTypes(formattedTypes);
      } catch (error) {
        console.error('Error fetching office types:', error);
      }
    };

    const fetchLocationSubTypes = async () => {
      try {
        const types = await CoreEntityType.getByEntityId(
          EntityTypeEnum.LocationSubType
        );
        const formattedTypes = types.map(type => ({
          value: type.type,
          key: type.id
        }));
        setLocationSubTypes(formattedTypes);
      } catch (error) {
        console.error('Error fetching location sub types:', error);
      }
    };

    fetchOfficeTypes();
    fetchLocationSubTypes();
  }, []);

  const handleDeleteOffice = async () => {
    try {
      const office = await Location.getById(officeToDelete.id);
      office.status = { id: 114 };
      await office.update();
      setIsDeleteModalOpen(false);
      setOfficeToDelete(null);
    } catch (error) {
      console.error('Error archiving office:', error);
    }
  };

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/locations/${data.id}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => {
        setSelectedOffice(data);
        setIsEditOfficeOpen(true);
      }
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => {
        setOfficeToDelete(data);
        setIsDeleteModalOpen(true);
      }
    }
  ];

  const handleNewClick = () => {
    setIsAddOfficeOpen(true);
  };

  const handleCloseAddOffice = () => {
    setIsAddOfficeOpen(false);
  };

  const handleCloseEditOffice = () => {
    setIsEditOfficeOpen(false);
    setSelectedOffice(null);
  };

  const handleOfficeAdded = newOffice => {
    console.log('New office added:', newOffice);
  };

  const handleOfficeUpdated = updatedOffice => {
    console.log('Office updated:', updatedOffice);
  };

  const handleRowClick = office => {
    setSelectedOffice(office);
    setIsEditOfficeOpen(true);
  };

  return (
    <>
      <EntityTable
        name='Offices'
        parentContext={{ type: 'company' }}
        filter={[
          { field: 'id', label: 'ID', type: 'number' },
          {
            field: 'name',
            label: 'Name',
            type: 'text',
            operator: FilterOperatorEnum.TEXTSEARCH
          },
          {
            field: 'address',
            label: 'Address',
            type: 'text',
            operator: FilterOperatorEnum.TEXTSEARCH
          },
          {
            field: 'phone',
            label: 'Phone',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'email',
            label: 'Email',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'status',
            label: 'Status',
            type: 'select',
            operator: FilterOperatorEnum.EQUALS,
            options: officeTypes
          },
          {
            field: 'location_sub_types',
            label: 'Sub Types',
            type: 'select',
            operator: FilterOperatorEnum.CONTAINS,
            options: locationSubTypes
          }
        ]}
        columns={[
          { label: 'ID', field: 'id' },
          { label: 'Name', field: 'name' },
          { label: 'Address', field: 'fullAddress' },
          { label: 'Status', field: 'status.type' },
          {
            label: 'Sub Types',
            field: 'locationSubTypes',
            foreignKey: {
              table: 'core_entity_types',
              column: 'type'
            },
            cell: value => {
              if (!value) return '';
              return (
                <div className='flex flex-wrap gap-1'>
                  {value.map(type => {
                    const subType = locationSubTypes.find(
                      st => st.key === type
                    );
                    return (
                      <Badge key={type} size='xs' colour='base'>
                        {subType ? subType.value : type}
                      </Badge>
                    );
                  })}
                </div>
              );
            }
          }
        ]}
        menuItems={menuItems}
        model={Location}
        entityType={EntityTypeEnum.Location}
        defaultFilters={{
          entity_type: { value: EntityTypeEnum.Company },
          entity_id: { value: companyId }
        }}
        onNewClick={handleNewClick}
        onRowClick={handleRowClick}
        canCreate={true}
      />
      <AddLocation
        isOpen={isAddOfficeOpen}
        onClose={handleCloseAddOffice}
        entityId={companyId}
        entityType={EntityTypeEnum.Company}
        onLocationAdded={handleOfficeAdded}
      />
      <EditLocation
        isOpen={isEditOfficeOpen}
        onClose={handleCloseEditOffice}
        location={selectedOffice}
        onLocationUpdated={handleOfficeUpdated}
      />
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title='Delete Office'
        style='danger'
        actions={[
          {
            label: 'Cancel',
            onClick: () => setIsDeleteModalOpen(false),
            style: 'base'
          },
          {
            label: 'Delete',
            onClick: handleDeleteOffice,
            style: 'danger'
          }
        ]}
      >
        <p className='text-sm text-base-600'>
          Are you sure you want to delete this office? This action cannot be
          undone.
        </p>
      </Modal>
    </>
  );
};

export default Offices;
