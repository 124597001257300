import React, { useState, useEffect } from 'react';
import {
  Popup,
  FilledButton,
  Input,
  DropdownInput,
  ComboBox,
  SimpleInlineRadioGroup,
  Map,
  geocodeAddress,
  TextButton,
  Toggle
} from 'core';
import AgreedRate from '../../../models/AgreedRate';
import Course from '../../../models/Course';
import CoreEntityType from '../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import { PoundSterling, Plus, Trash2 } from 'lucide-react';

const AddRate = ({
  isOpen,
  onClose,
  companyId,
  trainerId,
  courseId,
  onAdd
}) => {
  const initialFormData = {
    company: null,
    course: null,
    delivery: '',
    trainer: null,
    rateType: 'nationwide',
    price: '',
    status: '',
    zone: {
      type: 'circle',
      points: [],
      label: '',
      colour: '#000000',
      latitude: null,
      longitude: null,
      radius: '',
      address: ''
    },
    halfDay: '',
    fullDay: '',
    extras: []
  };

  const [formData, setFormData] = useState(initialFormData);
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [statusTypes, setStatusTypes] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [focusedZone, setFocusedZone] = useState('');
  const [showOtherRates, setShowOtherRates] = useState(false);
  const [otherRateZones, setOtherRateZones] = useState([]);

  useEffect(() => {
    const fetchEntityTypes = async () => {
      try {
        const [deliveryData, statusData] = await Promise.all([
          CoreEntityType.getAll({
            entity_id: {
              operator: 'eq',
              value: EntityTypeEnum.CourseDelivery
            }
          }),
          CoreEntityType.getAll({
            entity_id: {
              operator: 'eq',
              value: EntityTypeEnum.AgreedRateStatus
            }
          })
        ]);

        setDeliveryTypes(
          deliveryData.data.map(type => ({ label: type.type, value: type.id }))
        );
        setStatusTypes(
          statusData.data.map(type => ({ label: type.type, value: type.id }))
        );
      } catch (error) {
        console.error('Error fetching entity types:', error);
      }
    };

    fetchEntityTypes();
  }, []);

  useEffect(() => {
    const fetchOtherRates = async () => {
      if (showOtherRates) {
        try {
          const { data } = await AgreedRate.getAll({
            rate_type: {
              operator: 'eq',
              value: 'area'
            },
            trainer_id: { value: trainerId }
          });

          setOtherRateZones(
            data.map(rate => ({
              type: rate.zone.type,
              points: rate.zone.points,
              label: `Rate #${rate.id}`,
              colour: rate.zone.colour,
              editable: false,
              latitude: rate.zone.latitude,
              longitude: rate.zone.longitude,
              radius: rate.zone.radius
            }))
          );
        } catch (error) {
          console.error('Error fetching other rates:', error);
        }
      }
    };

    fetchOtherRates();
  }, [showOtherRates]);

  const loadCourses = async (query, page) => {
    try {
      const { data } = await Course.getAll({
        fts: {
          operator: 'textSearch',
          value: query
        }
      });
      return data.map(course => ({
        label: course.name,
        value: course.id,
        data: course
      }));
    } catch (error) {
      console.error('Error fetching courses:', error);
      return [];
    }
  };

  const handleSubmit = async () => {
    try {
      const agreedRate = new AgreedRate({
        company: companyId
          ? { id: companyId }
          : formData?.company?.data || null,
        course: courseId ? { id: courseId } : formData?.course?.data || null,
        delivery: formData.delivery ? { id: formData.delivery } : null,
        trainer: trainerId
          ? { id: trainerId }
          : formData?.trainer?.data || null,
        price: parseFloat(formData.price),
        status: { id: formData.status || 183 },
        rateType: formData.rateType,
        halfDay: parseFloat(formData.halfDay),
        fullDay: parseFloat(formData.fullDay),
        zone: formData.zone,
        extras: formData.extras
      });

      await agreedRate.insert();
      if (onAdd) onAdd();
      handleClose();
    } catch (error) {
      console.error('Error creating agreed rate:', error);
    }
  };

  const handleChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handlePolygonUpdate = updatedZone => {
    setFormData(prev => ({
      ...prev,
      zone: {
        ...prev.zone,
        points: updatedZone.points
      }
    }));
  };

  const handleClose = () => {
    setFormData(initialFormData);
    onClose();
  };

  const handleAddExtra = () => {
    setFormData(prev => ({
      ...prev,
      extras: [
        ...prev.extras,
        {
          id: prev.extras.length + 1,
          description: '',
          cost: 0
        }
      ]
    }));
  };

  const handleRemoveExtra = extraId => {
    setFormData(prev => ({
      ...prev,
      extras: prev.extras.filter(extra => extra.id !== extraId)
    }));
  };

  const handleExtraChange = (extraId, field, value) => {
    setFormData(prev => ({
      ...prev,
      extras: prev.extras.map(extra =>
        extra.id === extraId
          ? { ...extra, [field]: field === 'cost' ? parseFloat(value) : value }
          : extra
      )
    }));
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={handleClose}
      title='Add Agreed Rate'
      size={`${formData.rateType == 'area' ? 'lg' : 'sm'}`}
      footer={
        <div className='flex justify-end space-x-2'>
          <FilledButton onClick={handleClose} colour='secondary'>
            Cancel
          </FilledButton>
          <FilledButton
            onClick={handleSubmit}
            colour='primary'
            disabled={
              (formData.delivery == 62 &&
                (!formData.halfDay || !formData.fullDay)) ||
              (formData.delivery != 62 && !formData.price)
            }
          >
            Save
          </FilledButton>
        </div>
      }
    >
      <div className='space-y-4'>
        <DropdownInput
          label='Status'
          options={statusTypes}
          value={formData.status.id}
          onChange={e => handleChange('status', e.target.value)}
        />
        {!courseId && (
          <ComboBox
            label='Course'
            loadOptions={loadCourses}
            value={formData.course}
            onChange={course => handleChange('course', course)}
            placeholder='Search courses...'
          />
        )}

        <DropdownInput
          label='Delivery'
          options={deliveryTypes}
          value={formData.delivery}
          onChange={e => handleChange('delivery', e.target.value)}
        />

        {formData.delivery != 62 && (
          <Input
            type='number'
            label='Price'
            inlineLeftAddon={<PoundSterling size={14} />}
            value={formData.price || 0}
            onChange={e => handleChange('price', e.target.value)}
            required={formData.delivery != 62}
          />
        )}
        {formData.delivery == 62 && (
          <>
            <SimpleInlineRadioGroup
              label='Type'
              options={[
                { value: 'nationwide', label: 'Nationwide' },
                { value: 'area', label: 'Custom Area' }
              ]}
              value={formData.rateType}
              onChange={value => {
                handleChange('rateType', value);
              }}
            />
            <div className='flex-col space-y-4 rounded-lg border p-1 w-full'>
              {formData.rateType == 'nationwide' && (
                <div className='space-y-4'>
                  <Input
                    type='number'
                    label='Half Day Rate'
                    inlineLeftAddon={<PoundSterling size={14} />}
                    value={formData.halfDay || 0}
                    onChange={e => handleChange('halfDay', e.target.value)}
                    required={formData.delivery == 62}
                  />
                  <Input
                    type='number'
                    label='Full Day Rate'
                    inlineLeftAddon={<PoundSterling size={14} />}
                    value={formData.fullDay || 0}
                    onChange={e => handleChange('fullDay', e.target.value)}
                    required={formData.delivery == 62}
                  />
                </div>
              )}
              {formData.rateType == 'area' && formData.zone && (
                <div className='flex space-x-2 w-full'>
                  <div className='w-1/2'>
                    <Map
                      height='500px'
                      locations={[
                        {
                          type: formData.zone.type,
                          points: formData.zone.points,
                          label: formData.zone.label,
                          colour: formData.zone.colour,
                          editable: true,
                          latitude: formData.zone.latitude,
                          longitude: formData.zone.longitude,
                          radius: formData.zone.radius,
                          address: formData.zone.address
                        },
                        ...(showOtherRates ? otherRateZones : [])
                      ]}
                      onPolygonUpdate={handlePolygonUpdate}
                      focusZoneLabel={focusedZone}
                      drawing={isDrawing}
                    />
                    <Toggle
                      label='Show Other Rate Areas'
                      description='Display existing rate areas on the map'
                      checked={showOtherRates}
                      onChange={setShowOtherRates}
                    />
                  </div>
                  <div className='flex space-y-2 w-1/2'>
                    <div className='flex flex-col space-y-2 w-full'>
                      <SimpleInlineRadioGroup
                        label='Area Type'
                        options={[
                          { value: 'circle', label: 'Circle' },
                          { value: 'polygon', label: 'Polygon' }
                        ]}
                        value={formData.zone.type}
                        onChange={value => {
                          const updatedZone = {
                            ...formData.zone,
                            type: value
                          };
                          handleChange('zone', updatedZone);
                        }}
                      />
                      {formData.zone?.type == 'circle' && (
                        <div className='flex flex-col space-y-2'>
                          <div className='flex flex-row items-center space-x-2'>
                            <Input
                              placeholder='Enter address'
                              className='flex-1 w-full'
                              value={formData.zone.address || ''}
                              onChange={e => {
                                const updatedZone = {
                                  ...formData.zone,
                                  address: e.target.value
                                };
                                handleChange('zone', updatedZone);
                              }}
                            />
                            <TextButton
                              className='whitespace-nowrap px-4'
                              onClick={async () => {
                                const coordinates = await geocodeAddress(
                                  formData.zone.address
                                );
                                if (coordinates) {
                                  const updatedZone = {
                                    ...formData.zone,
                                    latitude: coordinates.lat,
                                    longitude: coordinates.lng
                                  };
                                  handleChange('zone', updatedZone);
                                }
                              }}
                            >
                              Search Address
                            </TextButton>
                          </div>
                          <Input
                            type='number'
                            label='Radius (miles)'
                            value={formData.zone.radius || 0}
                            onChange={e => {
                              const updatedZone = {
                                ...formData.zone,
                                radius: e.target.value
                              };
                              handleChange('zone', updatedZone);
                            }}
                          />
                        </div>
                      )}
                      {formData.zone?.type == 'polygon' && (
                        <div className='flex flex-col space-y-2'>
                          {isDrawing ? (
                            <div className='space-y-2'>
                              <div className='text-sm text-gray-600 bg-yellow-100 p-2 rounded'>
                                Drawing Mode Active - Click points on map to
                                draw area. Click Confirm when done.
                              </div>
                              <div className='flex space-x-2'>
                                <TextButton
                                  onClick={() => {
                                    if (formData.zone.points?.length >= 3) {
                                      const updatedZone = {
                                        ...formData.zone,
                                        points: formData.zone.points
                                      };
                                      handleChange('zone', updatedZone);
                                      setIsDrawing(false);
                                    }
                                  }}
                                  colour='base'
                                  disabled={formData.zone.points?.length < 3}
                                >
                                  Confirm Drawing
                                </TextButton>
                                <TextButton
                                  onClick={() => {
                                    setIsDrawing(false);
                                    const updatedZone = {
                                      ...formData.zone,
                                      points: formData.zone.points || []
                                    };
                                    handleChange('zone', updatedZone);
                                  }}
                                  colour='red'
                                >
                                  Cancel Drawing
                                </TextButton>
                              </div>
                            </div>
                          ) : formData.zone.points?.length > 0 ? (
                            <>
                              <div className='text-sm text-gray-600'>
                                {formData.zone.points.length} points defined
                              </div>
                              <TextButton
                                onClick={() => {
                                  const updatedZone = {
                                    ...formData.zone,
                                    points: []
                                  };
                                  handleChange('zone', updatedZone);
                                }}
                                colour='red'
                              >
                                Clear Area
                              </TextButton>
                            </>
                          ) : (
                            <TextButton
                              onClick={() => setIsDrawing(true)}
                              colour='base'
                            >
                              Draw Area
                            </TextButton>
                          )}
                        </div>
                      )}
                      <div className='flex items-center space-x-2'>
                        <label className='text-sm font-medium text-gray-700'>
                          Zone Colour
                        </label>
                        <input
                          type='color'
                          value={formData.zone.colour}
                          onChange={e => {
                            const updatedZone = {
                              ...formData.zone,
                              colour: e.target.value
                            };
                            handleChange('zone', updatedZone);
                          }}
                          className='w-10 h-10 p-1 rounded-md border border-gray-300 shadow-sm cursor-pointer hover:border-base-500 focus:outline-none focus:ring-2 focus:ring-base-500'
                        />
                      </div>
                      <div className='space-y-4'>
                        <Input
                          type='number'
                          label='Half Day Rate'
                          inlineLeftAddon={<PoundSterling size={14} />}
                          value={formData.halfDay || 0}
                          onChange={e =>
                            handleChange('halfDay', e.target.value)
                          }
                          required={formData.delivery == 62}
                        />
                        <Input
                          type='number'
                          label='Full Day Rate'
                          inlineLeftAddon={<PoundSterling size={14} />}
                          value={formData.fullDay || 0}
                          onChange={e =>
                            handleChange('fullDay', e.target.value)
                          }
                          required={formData.delivery == 62}
                        />
                        <div className='space-y-4'>
                          <div className='flex justify-between items-center'>
                            <h3 className='text-base font-medium'>Extras</h3>
                            <TextButton
                              onClick={handleAddExtra}
                              colour='base'
                              size='sm'
                            >
                              <Plus size={16} className='mr-1' />
                              Add Extra
                            </TextButton>
                          </div>
                          {formData.extras.map(extra => (
                            <div
                              key={extra.id}
                              className='flex space-x-2 items-start'
                            >
                              <Input
                                type='text'
                                placeholder='Description'
                                value={extra.description}
                                onChange={e =>
                                  handleExtraChange(
                                    extra.id,
                                    'description',
                                    e.target.value
                                  )
                                }
                                className='flex-grow'
                              />
                              <Input
                                type='number'
                                inlineLeftAddon={<PoundSterling size={14} />}
                                value={extra.cost}
                                onChange={e =>
                                  handleExtraChange(
                                    extra.id,
                                    'cost',
                                    e.target.value
                                  )
                                }
                                className='w-32'
                              />
                              <button
                                onClick={() => handleRemoveExtra(extra.id)}
                                className='p-2 text-gray-500 hover:text-red-500'
                              >
                                <Trash2 size={16} />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Popup>
  );
};

export default AddRate;
