import BaseModel from './BaseModel';
import { supabase } from '../utilities/supabase';

/**
 * Represents a Trainer in the system.
 * @extends BaseModel
 */
class Trainer extends BaseModel {
  /** @type {string} The name of the database table for Trainers */
  static table = 'trainers';

  /** @type {string} The SQL query to select Trainer data with related information */
  static selectQuery = `
    *,
    status:core_entity_types!status (id, type),
    term_type:core_entity_types!term_type (id, type)
  `;

  /**
   * Creates a Trainer instance from database format data
   * @param {Object} data - The database format trainer data
   * @returns {Trainer} A new Trainer instance
   */
  static fromDatabase(data = {}) {
    const trainer = new Trainer();
    trainer.id = data.id;
    trainer.createdDate = data.created_date;
    trainer.updatedDate = data.updated_date;
    trainer.name = data.name;
    trainer.capsuleId = data.capsule_id;
    trainer.xeroId = data.xero_id;
    trainer.accountNumber = data.account_number;
    trainer.terms =
      data.term_days !== undefined && data.term_type?.id != null
        ? {
            days: data.term_days,
            type: data.term_type
              ? { id: data.term_type.id, type: data.term_type.type }
              : null
          }
        : null;
    trainer.termsAgreed = data.terms_agreed;
    trainer.creditLimit = data.credit_limit;
    trainer.contacts = data.contacts || [{}];
    trainer.status = data.status
      ? { id: data.status.id, name: data.status.type }
      : null;
    trainer.approved = data.approved;
    trainer.rating = data.rating;
    trainer.options = data.options || {};
    trainer.financeNotes = data.finance_notes;
    trainer.discount = data.discount;
    return trainer;
  }

  /**
   * Creates an instance of Trainer.
   * @param {Object} data - The trainer data.
   */
  constructor(data = {}) {
    super(data);
    this.name = data.name ?? this.name ?? null;
    this.capsuleId = data.capsuleId ?? this.capsuleId ?? null;
    this.xeroId = data.xeroId ?? this.xeroId ?? null;
    this.accountNumber = data.accountNumber ?? this.accountNumber ?? null;
    this.terms = data.terms ?? this.terms ?? null;
    this.termsAgreed = data.termsAgreed ?? this.termsAgreed ?? false;
    this.creditLimit = data.creditLimit ?? this.creditLimit ?? null;
    this.contacts = data.contacts ?? this.contacts ?? [{}];
    this.status = data.status ?? this.status ?? null;
    this.approved = data.approved ?? this.approved ?? false;
    this.rating = data.rating ?? this.rating ?? null;
    this.options = data.options ?? this.options ?? {};
    this.financeNotes = data.financeNotes ?? this.financeNotes ?? null;
    this.discount = data.discount ?? this.discount ?? null;
  }

  /**
   * Converts the Trainer instance to a database-friendly format.
   * @returns {Object} The trainer data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      name: this.name,
      capsule_id: this.capsuleId,
      xero_id: this.xeroId,
      account_number: this.accountNumber,
      term_days: this.terms?.days,
      term_type: this.terms?.type.id,
      terms_agreed: this.termsAgreed,
      credit_limit: this.creditLimit,
      contacts: this.contacts,
      status: this.status ? this.status.id : null,
      approved: this.approved,
      rating: this.rating,
      options: this.options,
      finance_notes: this.financeNotes,
      discount: this.discount
    };
  }

  /**
   * Get trainers by course using a specific function with filtering, sorting, and pagination.
   * @param {number} courseId - The course ID to filter by.
   * @param {Object} filters - Additional filters to apply.
   * @param {number} page - The page number.
   * @param {number} size - The page size.
   * @param {string} sortBy - The field to sort by.
   * @param {string} sortType - The sort type ('asc' or 'desc').
   * @returns {Promise<Object>} A promise that resolves to an object containing the data and pagination info.
   */
  static async getAllByFunction(
    filters = {},
    page = 1,
    size = 10,
    sortBy = 'id',
    sortType = 'asc'
  ) {
    try {
      const { courseId, ...remainingFilters } = filters;
      let query = supabase.rpc(
        'get_trainers_by_course',
        {
          p_course_id: courseId
        },
        { count: 'exact' }
      );

      query = this.filters(query, remainingFilters);
      query = this.sorting(query, sortBy, sortType);
      query = this.range(query, page, size);

      const { data, error, count } = await query.select(this.selectQuery);

      if (error) throw error;

      return {
        data: data.map(item => Trainer.fromDatabase(item)),
        count: count,
        page: page,
        size: size,
        total: Math.ceil(count / size)
      };
    } catch (error) {
      console.error(`[Error] Fetching Trainers By Course: `, error);
      throw error;
    }
  }
}

export default Trainer;
