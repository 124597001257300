import React, { useState, useEffect, useContext } from 'react';
import {
  CopyPlus,
  Pencil,
  SquareArrowOutUpRight,
  Trash,
  EyeOff,
  LoaderCircle
} from 'lucide-react';
import QuoteOption from '../../../../models/QuoteOption';
import EntityTable from '../../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import { formatCurrency } from '../../../../utilities/Formatting';
import CoreEntityType from '../../../../models/CoreEntityType';
import EditQuoteOption from '../../../../components/popups/quote-options/EditQuoteOption';
import { Modal, OutlineButton, Tooltip } from 'core';
import Quote from '../../../../models/Quote';
import UserProfile from '../../../../models/UserProfile';
import { UserProfileContext } from '../../../../App';
import BookQuote from '../../../../components/popups/quote-options/BookQuote';
import Booking from '../../../../models/Booking';
import BookingSpace from '../../../../models/BookingSpace';
import { supabase } from '../../../../utilities/supabase';
import axios from 'axios';
import Document from '../../../../models/Document';
import ActivityLog from '../../../../models/ActivityLog';
import { current } from 'tailwindcss/colors';
import TaskTemplate from '../../../../models/TaskTemplate';

const QuoteOptions = ({ quoteId }) => {
  const [statusOptions, setStatusOptions] = useState([]);
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [editPopupOpen, setEditPopupOpen] = useState(false);
  const [selectedQuoteOptionId, setSelectedQuoteOptionId] = useState(null);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const [selectedQuoteOption, setSelectedQuoteOption] = useState(null);
  const [bookModalOpen, setBookModalOpen] = useState(false);
  const [isConverting, setIsConverting] = useState(false);
  const [bookingPopupOpen, setBookingPopupOpen] = useState(false);

  const { userProfile: currentUser } = useContext(UserProfileContext);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const { data: statuses } = await CoreEntityType.getAll({
          entity_id: { operator: 'eq', value: EntityTypeEnum.QuoteOptionStatus }
        });
        const { data: deliveryTypes } = await CoreEntityType.getAll({
          entity_id: { operator: 'eq', value: EntityTypeEnum.CourseDelivery }
        });

        setStatusOptions(
          statuses
            .sort((a, b) => a.metadata?.order - b.metadata?.order)
            .map(status => ({ key: status.id, value: status.type }))
        );
        setDeliveryOptions(
          deliveryTypes.map(type => ({ key: type.id, value: type.type }))
        );
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, []);

  const handleEditClick = data => {
    setSelectedQuoteOptionId(data.id);
    setEditPopupOpen(true);
  };

  const handleDuplicateClick = data => {
    setSelectedQuoteOption(data);
    setDuplicateModalOpen(true);
  };

  const handleDuplicateConfirm = async () => {
    try {
      const duplicatedOption = await new QuoteOption({
        ...selectedQuoteOption,
        status: { id: 206 }
      }).insert();

      setDuplicateModalOpen(false);
    } catch (error) {
      console.error('Error duplicating quote option:', error);
    }
  };

  const handleBookClick = async quoteOption => {
    if (quoteOption.status.id == 256) {
      if (quoteOption.bookingId?.startsWith('bubble_')) {
        window.open(
          `https://bohsa.co.uk/tasks?task_id=${quoteOption.bookingId.replace(
            'bubble_',
            ''
          )}`,
          '_blank'
        );
      } else {
        window.open(`/dashboard/bookings/${quoteOption.bookingId}`, '_blank');
      }
      return;
    }
    setSelectedQuoteOption(quoteOption);
    setBookingPopupOpen(true);
  };

  const createBubbleBooking = async (
    descriptionText,
    nextId,
    isTest,
    attachments
  ) => {
    const base_data_url = `https://bohsa.co.uk/${
      isTest ? 'version-test/' : ''
    }api/1.1/obj`;
    const base_workflow_url = `https://bohsa.co.uk/${
      isTest ? 'version-test/' : ''
    }api/1.1/wf`;
    const tasks_table = 'tasks(v1)-tasks';
    const workflow = 'TEMP - Send Email For API Bookings';

    const body = {
      description_text: descriptionText,
      id_number: nextId.toString(),
      owner_list_user: [
        isTest
          ? '1699547743585x779032891720133200' // LUKE TESTING
          : '1663685744805x549086156906939200' // support
      ],
      priority_option_task___priority: 'Medium',
      status_option_task___status: 'Pending',
      task_list_custom_tasks___task_list: isTest
        ? '1618505959565x177540116070006800' // test list
        : '1619169254512x282527841734098940', // bookings
      task_name_text: `Booking-${selectedQuoteOption.course?.name || ''}`
    };

    const response = await fetch(`${base_data_url}/${tasks_table}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    if (result.status === 'success') {
      //Upload files then attach them
      if (attachments && attachments.length > 0) {
        const filePromises = attachments.map(async file => {
          // Convert file to base64
          const base64 = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split(',')[1]); // Remove data:image/jpeg;base64, prefix
            reader.onerror = error => reject(error);
          });

          // Upload file to Bubble
          const uploadResponse = await fetch(
            `${base_workflow_url}/TEMP - Upload file to task`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                contents: base64,
                name: file.name,
                task_uid: result.id
              })
            }
          );

          if (!uploadResponse.ok) {
            throw new Error(`Failed to upload file: ${file.name}`);
          }

          const uploadResult = await uploadResponse.text();
          console.log(uploadResult);
        });

        // Wait for all files to upload and task files to be created
        await Promise.all(filePromises);
      }

      const emailResponse = await fetch(`${base_workflow_url}/${workflow}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          task_id: nextId.toString(),
          task_name: `Booking-${selectedQuoteOption.course?.name || ''}`,
          description: descriptionText,
          creator_name: currentUser.fullName,
          to_mail: isTest ? ['luke@bohsa.co.uk'] : ['support@bohsa.co.uk']
        })
      });

      if (!emailResponse.ok) {
        console.error(
          'Error sending email notification:',
          emailResponse.status
        );
      }

      return nextId;
    }

    throw new Error('Failed to create bubble booking');
  };

  const createCrmBooking = async bookingData => {
    // Create booking data from quote option and form data
    const parentQuote = await Quote.getById(bookingData.quoteOption.quoteId);
    let payload = {
      owner: { id: currentUser.id },
      bookingDate: new Date(Date.now()),
      quoteId: bookingData.quoteOption.quoteId,
      company: parentQuote.company,
      trainer: bookingData.quoteOption.trainer,
      venue: bookingData.quoteOption.location,
      course: bookingData.quoteOption.course,
      deliveryType: bookingData.quoteOption.courseDelivery,
      quotedDate: parentQuote.reqestedDate || parentQuote.createdDate,
      startDate: bookingData.startDate,
      endDate: bookingData.endDate,
      invoiceAmount: bookingData.quoteOption.quoteValue,
      cpoNumber: bookingData.clientPo,
      tpoAmount: bookingData.quoteOption.total,
      status: { id: 141 }, // draft/new
      spaces: parentQuote.delegates,
      spaceRate: bookingData.quoteOption.quoteValue / parentQuote.delegates,
      contacts:
        parentQuote.bookers?.map(booker => ({
          user_id: booker,
          role: 'booker'
        })) || []
    };

    // Create and save the booking
    let booking = new Booking(payload);
    booking = await booking.insert();

    if (!booking.id) {
      console.error(
        'Booking id was null after creation, unable to create spaces safely'
      );
    } else {
      //Create task checklist for this booking
      const checklist = await TaskTemplate.getById(9);
      if (checklist) {
        try {
          const taskPayload = JSON.stringify([
            {
              priority: 1,
              status: 161,
              title: checklist.template.title,
              description: checklist.template.description || '',
              category: checklist.template.category || null,
              entity_type: 2,
              entity_id: booking.id,
              daysAfterCreation: checklist.template.daysAfterCreation
            },
            ...checklist.template.children.map(child => ({
              priority: 1,
              status: 161,
              title: child.title,
              description: child.description || '',
              category: child.category || null,
              entity_type: 2,
              entity_id: booking.id,
              daysAfterCreation: child.daysAfterCreation
            }))
          ]);

          const { data, error } = await supabase.rpc('insert_tasks', {
            json_payload: taskPayload
          });

          if (error) throw error;

          console.log('Tasks created successfully. Parent task ID:', data);
        } catch (error) {
          console.error('Error creating tasks from template:', error);
        }
      } else {
        console.error('Checklist could not be found with id 9');
      }

      //Create an activity log if there is a comment
      if (bookingData.comments) {
        const activity = new ActivityLog({
          owner: currentUser,
          entityType: { id: 2 },
          entityId: booking.id,
          auditType: { id: 119 },
          pinned: true,
          internal: true,
          content: bookingData.comments
        });

        await activity.insert();
      }
      //Create the booking spaces (if delegates are added)
      if (bookingData.delegates.length > 0) {
        const bookingSpaces = bookingData.delegates.map(delegate => {
          const bookingSpace = new BookingSpace({
            booking: {
              id: booking.id
            },
            companyId: parentQuote.company.id,
            delegate: delegate,
            status: { id: 213 }, // Pending
            levyStatus: { id: 152 } // Not Applicable
          });
          return bookingSpace.insert();
        });
        await Promise.all(bookingSpaces);
      }

      //Create documents if files were attached
      if (bookingData.attachments?.length > 0) {
        const uploadPromises = bookingData.attachments.map(async file => {
          try {
            const { data: signedUrlData, error: signedUrlError } =
              await supabase.functions.invoke('storage/getSigned/upload', {
                body: {
                  fileName: file.name,
                  originalFileName: file.name,
                  destinationFolder: 'general'
                }
              });

            if (signedUrlError) throw signedUrlError;

            // Upload file using signed URL
            await axios.put(signedUrlData.url, file, {
              headers: {
                'Content-Type': file.type
              }
            });

            // Create document record
            const document = await Document.getById(signedUrlData.file.id);
            await document.update({
              filePath: signedUrlData.file.key,
              connections: {
                2: [`${booking.id}`] // 2 = booking entity type
              }
            });
          } catch (error) {
            console.error('Error uploading attachment:', error);
          }
        });

        await Promise.all(uploadPromises);
      }
    }
    return booking;
  };

  const completeOptions = async (crmBooking, bubbleId, closeOtherOptions) => {
    // Update the selected quote option status to booked
    const updatedOption = new QuoteOption({
      ...selectedQuoteOption,
      status: { id: 256 }, // Sold
      bookingId: `bubble_${bubbleId}` //crmBooking.id - when we fully switch
    });
    await updatedOption.update();

    if (closeOtherOptions) {
      // Get all other quote options for this quote
      const { data: otherOptions } = await QuoteOption.getAll({
        quote_id: { operator: 'eq', value: quoteId },
        id: { operator: 'neq', value: selectedQuoteOption.id },
        status: {
          operator: FilterOperatorEnum.NOT_IN,
          value: [256, 257, 205, 204]
        }
        // Do not update options which are won,lost,archived or expired
      });

      // Update all other options to closed status
      await Promise.all(
        otherOptions.map(option =>
          new QuoteOption({
            ...option,
            status: { id: 257 } // Lost
          }).update()
        )
      );
    }

    // Update quote status if needed
    const parentQuote = await Quote.getById(quoteId);
    parentQuote.status = { id: 192 }; // Sold
    await parentQuote.update();
  };

  const handleBookConfirm = async bookingData => {
    try {
      setIsConverting(true);
      //TEMPORARY INTEGRATION CODE
      const isTest = false;

      //console.log(bookingData);
      //Request to bubble api to create a new task in the booking list
      const base_data_url = `https://bohsa.co.uk/${
        isTest ? 'version-test/' : ''
      }api/1.1/obj`;
      const tasks_table = 'tasks(v1)-tasks';

      const parentQuote = await Quote.getById(quoteId);
      const { data: bookers } = await UserProfile.getAll({
        id: { value: parentQuote.bookers, operator: 'in' }
      });

      // Build description object for booking task
      const description = {
        'Full Course Name': selectedQuoteOption.course?.name || '',
        'Quote ID': parentQuote.id || '',
        'Course Duration': selectedQuoteOption.courseDuration || '',
        'Client / Course PO': bookingData.clientPo || '',
        'Delegate/s': bookingData.delegates.map(d => d.label).join(', ') || '',
        'Client Name': parentQuote.company?.name || '',
        'Client who booked the course':
          bookers.map(b => `${b.firstName} ${b.lastName}`).join(', ') || '',
        'Start Time': bookingData.startDate || '',
        'End Time': bookingData.endDate || '',
        'Location Full Add':
          selectedQuoteOption.courseDelivery?.id === 63
            ? selectedQuoteOption.obfuscatedLocation ||
              selectedQuoteOption.location?.fullAddress ||
              ''
            : selectedQuoteOption.courseDelivery?.name || '',
        'Site Contact': '',
        'Full Date': '',
        Trainer: selectedQuoteOption.trainer?.name || '',
        'Spaces Confirmed with Trainer Y/N': '',
        'Trainer PO': '',
        'PO Amount': `£${selectedQuoteOption.total || 0} + VAT`,
        'Additional fees (Certificate, Exam)_trainer': 'N/A',
        'Invoice Number': '',
        'Invoice Amount': `£${selectedQuoteOption.quoteValue || 0} + VAT`,
        'Additional fees (Certificate, Exam)_invoice': '',
        'Account Customer Y/N': '',
        Advisor: 'BOHSA',
        Notes: bookingData.comments || ''
      };

      const descriptionText =
        `Full Course Name: ${description['Full Course Name']}\n` +
        `Quote ID: ${description['Quote ID']}\n` +
        `Course Duration: ${description['Course Duration']}\n` +
        `Client / Course PO: ${description['Client / Course PO']}\n\n` +
        `Delegate/s: ${description['Delegate/s']}\n` +
        `Client Name: ${description['Client Name']}\n` +
        `Client who booked the course: ${description['Client who booked the course']}\n\n` +
        `Start Time: ${description['Start Time']}\n` +
        `End Time: ${description['End Time']}\n` +
        `Location Full Add: ${description['Location Full Add']}\n` +
        `Site Contact: ${description['Site Contact']}\n\n` +
        `Full Date: ${description['Full Date']}\n` +
        `Trainer: ${description['Trainer']}\n` +
        `Spaces Confirmed with Trainer Y/N: ${description['Spaces Confirmed with Trainer Y/N']}\n\n` +
        `Trainer PO: ${description['Trainer PO']}\n` +
        `PO Amount: ${description['PO Amount']}\n` +
        `Additional fees (Certificate, Exam): ${description['Additional fees (Certificate, Exam)_trainer']}\n` +
        `Invoice Number: ${description['Invoice Number']}\n` +
        `Invoice Amount: ${description['Invoice Amount']}\n` +
        `Additional fees (Certificate, Exam): ${description['Additional fees (Certificate, Exam)_invoice']}\n` +
        `Account Customer Y/N: ${description['Account Customer Y/N']}\n` +
        `Advisor: ${description['Advisor']}\n` +
        `Notes: ${description['Notes']}`;

      const lastTaskObj = await fetch(
        `${base_data_url}/${tasks_table}?sort_field=id_number&descending=true`
      );

      if (!lastTaskObj.ok) {
        throw new Error("Couldn't fetch the last task data");
      }

      const lastTaskData = await lastTaskObj.json();
      const lastTask = lastTaskData.response.results[0];
      const nextId = Number(lastTask.id_number) + 1;

      const bubbleId = await createBubbleBooking(
        descriptionText,
        nextId,
        isTest,
        bookingData.attachments
      );
      const crmBooking = await createCrmBooking(bookingData);
      await completeOptions(
        crmBooking,
        bubbleId,
        bookingData.closeOtherOptions
      );

      setIsConverting(false);
      setBookModalOpen(false);
    } catch (error) {
      console.error('Error booking quote option:', error);
    }
  };

  const menuItems = [
    {
      label: 'Duplicate',
      icon: <CopyPlus size={14} />,
      onClick: handleDuplicateClick
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: handleEditClick
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  const handleNewClick = () => {
    setSelectedQuoteOptionId(null);
    setEditPopupOpen(true);
  };

  return (
    <>
      <EntityTable
        parentContext={{ type: 'quote' }}
        name='Quote Options'
        filter={[
          { field: 'id', label: 'ID', type: 'number' },
          {
            field: 'status',
            label: 'Status',
            type: 'multiselect',
            options: statusOptions,
            operator: FilterOperatorEnum.IN,
            isType: true
          },
          {
            field: 'course_delivery',
            label: 'Delivery Type',
            type: 'multiselect',
            options: deliveryOptions,
            operator: FilterOperatorEnum.IN,
            isType: true
          },
          {
            field: 'trainers.name',
            label: 'Trainer',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'created_date',
            label: 'Created Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          }
        ]}
        columns={[
          { label: 'ID', field: 'id' },
          {
            label: 'Status',
            field: 'status.name',
            foreignKey: { table: 'status', column: 'type' }
          },
          // { label: 'Course', field: 'course.name' },
          {
            label: 'Trainer',
            field: 'trainer.name',
            foreignKey: { table: 'trainers', column: 'name' }
          },
          {
            label: 'Delivery',
            field: 'courseDelivery.name',
            foreignKey: { table: 'course_delivery', column: 'type' }
          },
          {
            label: 'Location',
            field: 'location.name',
            cell: (value, row) => {
              if (row.obfuscatedLocation) {
                return (
                  <div>
                    <Tooltip
                      size='sm'
                      content={`This location is hidden from clients. Original: ${row.location?.fullAddress}`}
                    >
                      <span className='items-center flex space-x-2'>
                        {row.obfuscatedLocation}{' '}
                        <EyeOff size={16} className='ml-2 text-danger-600' />
                      </span>
                    </Tooltip>
                  </div>
                );
              }
              return <div>{value || row.location?.fullAddress || 'n/a'}</div>;
            },
            foreignKey: { table: 'locations', column: 'name' }
          },
          {
            label: 'Quote Value',
            field: 'quoteValue',
            cell: value => formatCurrency(value)
          },
          {
            label: 'Cost',
            field: 'cost',
            cell: value => formatCurrency(value)
          },
          {
            label: 'Total',
            field: 'total',
            cell: value => formatCurrency(value)
          },
          {
            label: '',
            field: '',
            cell: (value, row) => {
              return (
                <OutlineButton
                  size='sm'
                  colour='primary'
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleBookClick(row);
                  }}
                  rightIcon={
                    row.status.id === 256 ? <SquareArrowOutUpRight /> : null
                  }
                  disabled={row.status.id == 257}
                >
                  {row.status.id === 256 ? 'Booked' : 'Book'}
                </OutlineButton>
              );
            }
          }
        ]}
        menuItems={menuItems}
        model={QuoteOption}
        entityType={EntityTypeEnum.QuoteOption}
        defaultFilters={{
          quote_id: { value: quoteId }
        }}
        onNewClick={handleNewClick}
        canCreate={true}
      />

      <EditQuoteOption
        isOpen={editPopupOpen}
        onClose={() => setEditPopupOpen(false)}
        quoteOptionId={selectedQuoteOptionId}
      />

      <BookQuote
        isOpen={bookingPopupOpen}
        onClose={() => {
          setBookingPopupOpen(false);
          setSelectedQuoteOption(null);
        }}
        onConfirm={async formData => {
          try {
            setIsConverting(true);
            // Merge the existing booking logic with the new form data
            const bookingData = {
              ...formData,
              quoteOption: selectedQuoteOption
            };
            await handleBookConfirm(bookingData);
            setBookingPopupOpen(false);
            setSelectedQuoteOption(null);
          } catch (error) {
            console.error('Error booking quote:', error);
          } finally {
            setIsConverting(false);
          }
        }}
        isLoading={isConverting}
        quoteOption={selectedQuoteOption}
      />

      <Modal
        isOpen={duplicateModalOpen}
        onClose={() => setDuplicateModalOpen(false)}
        title='Duplicate Quote Option'
        style='primary'
        actions={[
          {
            label: 'Cancel',
            onClick: () => setDuplicateModalOpen(false),
            style: 'base'
          },
          {
            label: 'Duplicate',
            onClick: handleDuplicateConfirm
          }
        ]}
      >
        <p className='text-sm text-neutral-600'>
          Are you sure you want to create a duplicate of this quote option? All
          details will be copied to a new quote option.
        </p>
      </Modal>
    </>
  );
};

export default QuoteOptions;
