import React, { useContext, useState } from 'react';
import UserProfile from '../../../models/UserProfile';
import EntityTable from '../../../components/tables/EntityTable';
import { formatDate } from '../../../utilities/Formatting';
import { Link, useNavigate } from 'react-router-dom';
import { Pencil, Trash, SquareArrowOutUpRight } from 'lucide-react';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import { Alert } from 'core';
import { UserProfileContext } from '../../../App';
import { PERMISSIONS } from '../../../utilities/Permissions';
import CreateUser from '../../../components/popups/user/CreateUser';

const Users = () => {
  //Set the page title
  document.title = 'Users | CRM | BOHSA LTD';
  const navigate = useNavigate();
  const [isCreateUserOpen, setIsCreateUserOpen] = useState(false);

  const { userProfile: currentUser } = useContext(UserProfileContext);
  //Test user permissions for view access
  if (
    !currentUser.hasPermission(
      EntityTypeEnum.User,
      PERMISSIONS[EntityTypeEnum.User].VIEW
    )
  ) {
    return (
      <Alert
        title='Permission Denied'
        description='You do not have permission to view users.'
        style='danger'
      />
    );
  }

  const userCanEdit = currentUser.hasPermission(
    EntityTypeEnum.User,
    PERMISSIONS[EntityTypeEnum.User].EDIT
  );

  const userCanDelete = currentUser.hasPermission(
    EntityTypeEnum.User,
    PERMISSIONS[EntityTypeEnum.User].DELETE
  );

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/user-profiles/${data.displayId}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => navigate(`/user-profiles/${data.displayId}`)
    }
    // userCanDelete && {
    //   label: 'Delete',
    //   icon: <Trash size={14} className='text-danger-700' />,
    //   onClick: data => console.log('Delete clicked', data)
    // }
  ];

  const handleRowClick = data => {
    navigate(`/user-profiles/${data.displayId}`);
  };

  const handleCreateClick = () => {
    setIsCreateUserOpen(true);
  };

  const handleCreateUserClose = () => {
    setIsCreateUserOpen(false);
  };

  const handleUserCreated = () => {
    // Refresh table data if needed
  };

  return (
    <div className='p-4'>
      <EntityTable
        name='Users'
        filter={[
          { field: 'display_id', label: 'ID', type: 'text', operator: 'eq' },
          {
            field: 'full_name',
            label: 'Full Name',
            type: 'text',
            operator: FilterOperatorEnum.TEXTSEARCH
          },
          {
            field: 'email',
            label: 'Email',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          { field: 'created_date', label: 'Created Date', type: 'daterange' },
          { field: 'updated_date', label: 'Updated Date', type: 'daterange' }
        ]}
        defaultFilters={{
          bohsa_admin: { value: false, operator: FilterOperatorEnum.IS }
        }}
        sortDefault={{ field: 'display_id', order: 'asc' }}
        columns={[
          {
            label: 'ID',
            field: 'displayId',
            cell: value => (
              <Link
                to={`/user-profiles/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          { label: 'Full Name', field: 'fullName' },
          { label: 'Email', field: 'email' },
          { label: 'Company', field: 'company.name' },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={UserProfile}
        entityType={EntityTypeEnum.User}
        onRowClick={handleRowClick}
        canCreate={true}
        onNewClick={handleCreateClick}
      />

      <CreateUser
        isOpen={isCreateUserOpen}
        onClose={handleCreateUserClose}
        onSuccess={handleUserCreated}
      />
    </div>
  );
};

export default Users;
