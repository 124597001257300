import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { Input } from './Input';

/**
 * AutoComplete Component
 *
 * @param {Function} props.loadOptions - Function to load autocomplete options
 * @param {string} props.label - Label text for the input
 * @param {string} props.placeholder - Placeholder text
 * @param {Function} props.onChange - Change handler function
 * @param {string} props.value - Current input value
 * @param {string} props.className - Additional CSS classes
 * @param {string} props.size - Size of the input ('xs', 'sm', 'md', 'lg', 'xl')
 * @param {string} props.hint - Hint text to display below input
 * @param {boolean} props.required - Whether the field is required
 * @param {boolean} props.disabled - Whether the field is disabled
 */
const AutoComplete = ({
  loadOptions,
  label,
  placeholder,
  onChange,
  value,
  className = '',
  size = 'md',
  hint,
  required,
  disabled
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [inputValue, setInputValue] = useState(value || '');

  const debouncedLoadOptions = useCallback(
    debounce(async query => {
      if (loadOptions && query) {
        try {
          const results = await loadOptions(query);
          setSuggestions(results || []);
        } catch (error) {
          console.error('Error loading options:', error);
          setSuggestions([]);
        }
      } else {
        setSuggestions([]);
      }
    }, 300),
    [loadOptions]
  );

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  const handleInputChange = e => {
    const newValue = e.target.value;
    setInputValue(newValue);
    setShowSuggestions(true);
    onChange(newValue);
    debouncedLoadOptions(newValue);
  };

  const handleSuggestionClick = suggestion => {
    setInputValue(suggestion.label);
    setShowSuggestions(false);
    onChange(suggestion.label);
  };

  const highlightMatch = (text, searchTerm) => {
    if (!searchTerm) return text;

    const regex = new RegExp(`(${searchTerm})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) => {
      if (part.toLowerCase() === searchTerm.toLowerCase()) {
        return (
          <span key={index} className='text-brand-700'>
            {part}
          </span>
        );
      }
      return part;
    });
  };

  return (
    <div className='relative'>
      <Input
        label={label}
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => setShowSuggestions(true)}
        onBlur={() => {
          // Delay hiding suggestions to allow click events
          setTimeout(() => setShowSuggestions(false), 200);
        }}
        placeholder={placeholder}
        className={className}
        size={size}
        hint={hint}
        required={required}
        disabled={disabled}
      />

      {showSuggestions && suggestions.length > 0 && !disabled && (
        <div className='absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5'>
          <ul className='max-h-60 overflow-auto py-1 text-sm'>
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                className='relative cursor-default select-none py-2 pl-3 pr-9 hover:bg-primary-100'
                onClick={() => handleSuggestionClick(suggestion)}
              >
                <div className='flex items-center'>
                  <span className='block truncate'>
                    {highlightMatch(suggestion.label, inputValue)}
                  </span>
                  {suggestion.secondaryLabel && (
                    <span className='ml-2 truncate text-primary-500 text-sm'>
                      {highlightMatch(suggestion.secondaryLabel, inputValue)}
                    </span>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export { AutoComplete };
