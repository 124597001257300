import React from 'react';
import { Input } from 'core';

const QuoteStep3 = ({ quoteData, handleInputChange }) => {
  return (
    <div className='space-y-6'>
      <div>
        <Input
          value={quoteData.datePrefs}
          onChange={e => handleInputChange('datePrefs', e.target.value)}
          placeholder='Enter date preferences...'
          className='w-full'
          label='Date Preferences'
        />
      </div>

      <div>
        <Input
          value={quoteData.locations}
          onChange={e => handleInputChange('locations', e.target.value)}
          placeholder='Enter location preferences...'
          className='w-full'
          label='Location Preferences'
        />
      </div>

      <div>
        <label className='block text-sm font-medium text-gray-700 mb-1'>
          Further Information
        </label>
        <textarea
          value={quoteData.furtherInfo}
          onChange={e => handleInputChange('furtherInfo', e.target.value)}
          placeholder='Enter any additional information...'
          className='w-full h-32 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-brand-500 focus:border-brand-500'
        />
      </div>
    </div>
  );
};

export default QuoteStep3;
