import React from 'react';
import { ArrowUp, ArrowDown, Minus, Users } from 'lucide-react';
import { Badge } from 'core';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip
} from 'recharts';

const StatsCard = ({
  label,
  value,
  change,
  changeType,
  previousValues = []
}) => {
  const isPositive = changeType === 'positive';
  const isNeutral = changeType === 'neutral';
  const ChangeIcon = isNeutral ? Minus : isPositive ? ArrowUp : ArrowDown;
  const prevValue = previousValues[previousValues.length - 2]?.value || 0;

  return (
    <div className='bg-white p-4 rounded-lg shadow'>
      <div className='flex flex-col space-y-2'>
        {/* Row 1 - Title */}
        <div className='flex items-center'>
          <h3 className='text-sm font-medium text-neutral-700'>{label}</h3>
        </div>

        {/* Row 2 - Value + Previous Value + Trend Badge */}
        <div className='flex items-center justify-between'>
          <div className='flex items-baseline space-x-2'>
            <span className='text-2xl font-semibold text-neutral-900'>
              {value}
            </span>
            <span className='text-sm text-neutral-500'>From {prevValue}</span>
          </div>
          <Badge
            colour={isNeutral ? 'neutral' : isPositive ? 'success' : 'danger'}
            size='xs'
            shape='pill'
            leftIcon={ChangeIcon}
          >
            {change}
          </Badge>
        </div>

        {/* Row 3 - Area Graph */}
        <div className='h-24'>
          <ResponsiveContainer width='100%' height='100%'>
            <AreaChart data={previousValues}>
              <XAxis dataKey='label' tick={{ fontSize: 10 }} stroke='#737373' />
              <YAxis hide={true} />
              <Tooltip />
              <Area
                type='monotone'
                dataKey='value'
                stroke='#BC3D3F'
                fill='#FBF3F3'
                strokeWidth={2}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default StatsCard;
