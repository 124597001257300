import BaseModel from './BaseModel';

/**
 * Represents a Quote Option in the system.
 * @extends BaseModel
 */
class QuoteOption extends BaseModel {
  /** @type {string} The name of the database table for Quote Options */
  static table = 'quote_options';

  /** @type {string} The SQL query to select Quote Option data with related information */
  static selectQuery = `
    *,
    courses (id,name),
    trainers (id,name),
    status:core_entity_types!status (*),
    course_delivery:core_entity_types!course_delivery (*),
    locations(id,name,full_address,address_1,geometry),
    comments:get_quote_option_comment_count
  `;

  /**
   * Creates a QuoteOption instance from database format data
   * @param {Object} data - The database format quote option data
   * @returns {QuoteOption} A new QuoteOption instance
   */
  static fromDatabase(data = {}) {
    const quoteOption = new QuoteOption();
    quoteOption.id = data.id;
    quoteOption.createdDate = data.created_date;
    quoteOption.status = data.status
      ? { id: data.status.id, name: data.status.type }
      : null;
    quoteOption.courseId = data.course_id;
    quoteOption.course = data.courses;
    quoteOption.courseDelivery = data.course_delivery
      ? { id: data.course_delivery.id, name: data.course_delivery.type }
      : null;
    quoteOption.courseDuration = data.course_duration;
    quoteOption.trainerId = data.trainer_id;
    quoteOption.trainer = data.trainers;
    quoteOption.quoteValue = data.quote_value;
    quoteOption.cost = data.cost;
    quoteOption.discountPercentage = data.discount_percentage;
    quoteOption.discountValue = data.discount_value;
    quoteOption.total = data.total;
    quoteOption.quoteId = data.quote_id;
    quoteOption.location = data.locations
      ? {
          id: data.locations.id,
          name: data.locations.name,
          address1: data.locations.address_1,
          fullAddress: data.locations.full_address
        }
      : null;
    quoteOption.comments = data.comments;
    quoteOption.obfuscatedLocation = data.obfuscated_location;
    quoteOption.bookingId = data.booking_id;
    return quoteOption;
  }

  /**
   * Creates an instance of QuoteOption.
   * @param {Object} data - The quote option data.
   */
  constructor(data = {}) {
    super(data);
    this.status = data.status ?? this.status ?? null;
    this.courseId = data.courseId ?? this.courseId ?? null;
    this.courseDelivery = data.courseDelivery ?? this.courseDelivery ?? null;
    this.courseDuration = data.courseDuration ?? this.courseDuration ?? null;
    this.trainerId = data.trainerId ?? this.trainerId ?? null;
    this.quoteValue = data.quoteValue ?? this.quoteValue ?? null;
    this.cost = data.cost ?? this.cost ?? null;
    this.discountPercentage =
      data.discountPercentage ?? this.discountPercentage ?? null;
    this.discountValue = data.discountValue ?? this.discountValue ?? null;
    this.total = data.total ?? this.total ?? null;
    this.quoteId = data.quoteId ?? this.quoteId ?? null;
    this.locationId = data.locationId ?? this.locationId ?? null;
    this.location = data.location ?? this.location ?? null;
    this.obfuscatedLocation =
      data.obfuscatedLocation ?? this.obfuscatedLocation ?? null;
    this.bookingId = data.bookingId ?? this.bookingId ?? null;
  }

  /**
   * Converts the QuoteOption instance to a database-friendly format.
   * @returns {Object} The quote option data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      status: this.status.id ? this.status.id : this.status || null,
      course_id: this.courseId,
      course_delivery: this.courseDelivery.id
        ? this.courseDelivery.id
        : this.courseDelivery || null,
      course_duration: this.courseDuration,
      trainer_id: this.trainerId,
      quote_value: this.quoteValue,
      cost: this.cost,
      discount_percentage: this.discountPercentage,
      discount_value: this.discountValue,
      total: this.total,
      quote_id: this.quoteId,
      location_id: this.location ? this.location.id : this.locationId,
      obfuscated_location: this.obfuscatedLocation,
      booking_id: this.bookingId
    };
  }
}

export default QuoteOption;
