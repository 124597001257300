import React, { useState, useEffect, useCallback, useContext } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Popup, CircularIconButton } from 'core';
import * as LucideIcons from 'lucide-react';
import EntityPopupHandler from '../EntityPopupHandler';
import { UserProfileContext } from '../../../App';

const FavouriteActionsPopup = ({
  isOpen,
  onClose,
  onUpdate,
  entity,
  entityType,
  entityId
}) => {
  const [actions, setActions] = useState([]);
  const [activePopup, setActivePopup] = useState(null);
  const { userProfile: currentUser, setUserProfile } =
    useContext(UserProfileContext);

  const defaultActions = [
    {
      icon: 'Edit',
      label: 'Note',
      handler: 'handleNoteClick',
      favourited: true,
      order: 1,
      row: 1,
      disabled: false
    },
    {
      icon: 'Mail',
      label: 'Email',
      handler: 'handleEmailClick',
      favourited: true,
      order: 2,
      row: 1,
      disabled: false
    },
    {
      icon: 'CheckSquare',
      label: 'Task',
      handler: 'handleTaskClick',
      favourited: true,
      order: 3,
      row: 1,
      disabled: false
    },
    {
      icon: 'Calendar',
      label: 'Meeting',
      handler: 'handleMeetingClick',
      favourited: true,
      order: 4,
      row: 1,
      disabled: false
    },
    {
      icon: 'KanbanSquare',
      label: 'Pipelines',
      handler: 'handlePipelinesClick',
      favourited: true,
      order: 5,
      row: 1,
      disabled: false
    },
    {
      icon: 'PhoneCall',
      label: 'Call',
      handler: 'handleCallClick',
      favourited: true,
      order: 6,
      row: 1,
      disabled: false
    },
    {
      icon: 'MessageSquare',
      label: 'SMS',
      handler: 'handleSMSClick',
      favourited: false,
      order: 7,
      row: 1,
      disabled: false
    },
    {
      icon: 'Headphones',
      label: 'Audio',
      handler: 'handleAudioClick',
      favourited: false,
      order: 8,
      row: 1,
      disabled: true
    },
    {
      icon: 'Video',
      label: 'Video',
      handler: 'handleVideoClick',
      favourited: false,
      order: 9,
      row: 1,
      disabled: true
    },
    {
      icon: 'MessageCircle',
      label: 'Ai Chat',
      handler: 'handleAiChatClick',
      favourited: false,
      order: 10,
      row: 1,
      disabled: true
    },
    {
      icon: 'Phone',
      label: 'Ai Call',
      handler: 'handleAiCallClick',
      favourited: false,
      order: 11,
      row: 1,
      disabled: true
    },
    {
      icon: 'Cpu',
      label: 'AiFlows',
      handler: 'handleAiFlowsClick',
      favourited: false,
      order: 12,
      row: 1,
      disabled: false
    },
    {
      icon: 'FileText',
      label: 'DocFlows',
      handler: 'handleDocFlowsClick',
      favourited: false,
      order: 13,
      row: 1,
      disabled: false
    },
    {
      icon: 'Tag',
      label: 'Tags',
      handler: 'handleTagsClick',
      favourited: false,
      order: 14,
      row: 1,
      disabled: false
    },
    {
      icon: 'CreditCard',
      label: 'E-Card',
      handler: 'handleECardClick',
      favourited: false,
      order: 15,
      row: 1,
      disabled: false
    },
    {
      icon: 'Users',
      label: 'Audience',
      handler: 'handleAudienceClick',
      favourited: false,
      order: 16,
      row: 1,
      disabled: false
    },
    {
      icon: 'File',
      label: 'Page',
      handler: 'handlePageClick',
      favourited: false,
      order: 17,
      row: 1,
      disabled: true
    },
    {
      icon: 'Link',
      label: 'Links',
      handler: 'handleLinksClick',
      favourited: false,
      order: 18,
      row: 1,
      disabled: true
    },
    {
      icon: 'Gift',
      label: 'Gift',
      handler: 'handleGiftClick',
      favourited: false,
      order: 19,
      row: 1,
      disabled: true
    }
  ];

  useEffect(() => {
    const loadActions = async () => {
      console.log('Loading actions...');

      if (currentUser.crmPreferences?.quick_actions) {
        if (
          !currentUser.crmPreferences.quick_actions_last_updated ||
          new Date(currentUser.crmPreferences.quick_actions_last_updated) <
            new Date(import.meta.env.VITE_LAST_ACTIONS_BUILD)
        ) {
          console.log('Setting default actions as quick actions.');
          setActions(defaultActions.sort((a, b) => a.order - b.order));
          currentUser.crmPreferences.quick_actions = defaultActions;
          currentUser.crmPreferences.quick_actions_last_updated =
            new Date().toISOString();
          const newUser = await currentUser.update();
          setUserProfile(newUser);
        } else {
          console.log('Loading user preferences for quick actions.');
          setActions(
            currentUser.crmPreferences.quick_actions.sort(
              (a, b) => a.order - b.order
            )
          );
        }
      } else {
        console.log('No quick actions found, setting default actions.');
        setActions(defaultActions.sort((a, b) => a.order - b.order));
        if (!currentUser.crmPreferences) {
          currentUser.crmPreferences = {};
        }
        currentUser.crmPreferences.quick_actions = defaultActions;
        currentUser.crmPreferences.quick_actions_last_updated =
          new Date().toISOString();
        const newUser = await currentUser.update();
        setUserProfile(newUser);
      }
    };

    loadActions();
  }, [currentUser]);

  const onDragEnd = useCallback(
    async result => {
      const { source, destination } = result;

      console.log('Drag result:', result);

      if (!destination) {
        console.log('No destination found, exiting drag operation.');
        return;
      }

      const newActions = Array.from(actions);
      const quickActions = newActions.filter(action => action.favourited);
      const availableActions = newActions.filter(action => !action.favourited);

      let sourceList, destinationList;
      if (source.droppableId.startsWith('quickActions')) {
        sourceList = quickActions;
      } else {
        sourceList = availableActions;
      }

      if (destination.droppableId.startsWith('quickActions')) {
        destinationList = quickActions;
      } else {
        destinationList = availableActions;
      }

      const movedItemIndex = sourceList.findIndex(
        item => item.label === result.draggableId
      );
      const [movedItem] = sourceList.splice(movedItemIndex, 1);

      movedItem.favourited = destination.droppableId.startsWith('quickActions');

      const adjustedIndex = destination.index;

      const quickActionsRow1Count = quickActions.filter(
        action => action.row === 1
      ).length;
      const quickActionsRow2Count = quickActions.filter(
        action => action.row === 2
      ).length;

      // If row 1 has 6 items and row 2 has 5, do not allow more to be added
      if (quickActionsRow1Count === 6 && quickActionsRow2Count === 5) {
        console.log(
          'Row 1 is full and Row 2 has 5 items, no more items can be added.'
        );
        return;
      }

      // If moving to row2 and row1 has less than 6 items, move to row1 instead
      if (
        destination.droppableId === 'quickActions-row2' &&
        quickActionsRow1Count < 6
      ) {
        movedItem.row = 1;
        movedItem.order = quickActionsRow1.length;
        destinationList.push(movedItem);
        console.log(
          'Moved to end of row 1 as row 1 is not full:',
          destinationList
        );
      }
      // If moving from row1 to row2, insert at beginning of row2
      else if (destination.droppableId === 'quickActions-row2') {
        movedItem.row = 2;
        destinationList.splice(adjustedIndex, 0, movedItem);
        console.log('Inserted at beginning of row 2:', destinationList);
      }
      // If moving from row2 to row1, insert at specified index
      else if (destination.droppableId === 'quickActions-row1') {
        movedItem.row = 1;
        destinationList.splice(adjustedIndex, 0, movedItem);
        console.log('Moved from row 2 to row 1:', destinationList);
      } else {
        destinationList.splice(adjustedIndex, 0, movedItem);
      }

      // If row 1 has more than 6 items, move the last item to the start of row 2
      if (quickActionsRow1Count > 6) {
        const lastItemInRow1 = quickActionsRow1.pop();
        lastItemInRow1.row = 2;
        quickActionsRow2.unshift(lastItemInRow1);
        console.log(
          'Moved last item from row 1 to beginning of row 2:',
          quickActionsRow2
        );
      }

      const updatedActions = [...quickActions, ...availableActions];

      setActions(updatedActions);

      currentUser.crmPreferences.quick_actions = updatedActions;
      currentUser.crmPreferences.quick_actions_last_updated =
        new Date().toISOString();
      const newUser = await currentUser.update();
      setUserProfile(newUser);

      if (onUpdate) {
        onUpdate(updatedActions);
      }
    },
    [actions, onUpdate]
  );

  const getIconComponent = useCallback(iconName => {
    return LucideIcons[iconName] || LucideIcons.HelpCircle;
  }, []);

  const handleActionClick = useCallback(handler => {
    setActivePopup(handler);
  }, []);

  const quickActions = actions.filter(action => action.favourited);
  const quickActionsRow1 = quickActions.filter(action => action.row == 1);
  const quickActionsRow2 = quickActions.filter(action => action.row == 2);
  const availableActions = actions.filter(action => !action.favourited);

  const getActionDisabledStatus = useCallback(label => {
    const defaultAction = defaultActions.find(action => action.label === label);
    return defaultAction ? defaultAction.disabled : false;
  }, []);

  return (
    <>
      <Popup isOpen={isOpen} onClose={onClose} title='Actions' size='sm'>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className='mb-4 overflow-hidden'>
            <h3 className='text-lg font-semibold mb-2'>
              Quick Actions{' '}
              <span className='text-sm font-normal'>
                ({quickActions.length}/11)
              </span>
            </h3>
            <div className='space-y-2'>
              <Droppable droppableId='quickActions-row1' direction='horizontal'>
                {provided => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className='grid grid-cols-6 gap-2 p-2 border border-base-100 rounded-xl overflow-hidden'
                  >
                    {quickActionsRow1
                      .filter(
                        action =>
                          !(
                            action.label == 'Audience' &&
                            ![4, 25, 49, 50].includes(entityType)
                          )
                      )
                      .map((action, index) => (
                        <Draggable
                          key={action.label}
                          draggableId={action.label}
                          index={index}
                          isDragDisabled={getActionDisabledStatus(action.label)}
                        >
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <div className='relative group'>
                                <div
                                  {...provided.dragHandleProps}
                                  className='absolute top-0 right-0 p-1 cursor-grab opacity-0 group-hover:opacity-100 transition-opacity duration-200'
                                >
                                  <LucideIcons.GripVertical className='w-4 h-4 text-gray-400' />
                                </div>
                                <CircularIconButton
                                  Icon={getIconComponent(action.icon)}
                                  label={action.label}
                                  onClick={() =>
                                    !getActionDisabledStatus(action.label) &&
                                    handleActionClick(action.handler)
                                  }
                                  colour={
                                    getActionDisabledStatus(action.label)
                                      ? 'base'
                                      : 'brand'
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>

              <Droppable droppableId='quickActions-row2' direction='horizontal'>
                {provided => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className='grid grid-cols-6 gap-2 p-2 min-h-20 border border-base-100 rounded-xl overflow-x-auto'
                  >
                    {quickActionsRow2
                      .filter(
                        action =>
                          !(
                            action.label == 'Audience' &&
                            ![4, 25, 49, 50].includes(entityType)
                          )
                      )
                      .map((action, index) => (
                        <Draggable
                          key={action.label}
                          draggableId={action.label}
                          index={index}
                          isDragDisabled={getActionDisabledStatus(action.label)}
                        >
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <div className='relative group'>
                                <div
                                  {...provided.dragHandleProps}
                                  className='absolute top-0 right-0 p-1 cursor-grab opacity-0 group-hover:opacity-100 transition-opacity duration-200'
                                >
                                  <LucideIcons.GripVertical className='w-4 h-4 text-gray-400' />
                                </div>
                                <CircularIconButton
                                  Icon={getIconComponent(action.icon)}
                                  label={action.label}
                                  onClick={() =>
                                    !getActionDisabledStatus(action.label) &&
                                    handleActionClick(action.handler)
                                  }
                                  colour={
                                    getActionDisabledStatus(action.label)
                                      ? 'base'
                                      : 'brand'
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>

          <div className='overflow-hidden'>
            <h3 className='text-lg font-semibold mb-2'>Available Actions</h3>
            <Droppable droppableId='availableActions' direction='horizontal'>
              {provided => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className='grid grid-cols-5 gap-2 p-2 overflow-x-auto'
                >
                  {availableActions
                    .filter(
                      action =>
                        !(
                          action.label == 'Audience' &&
                          ![4, 25, 49, 50].includes(entityType)
                        )
                    )
                    .map((action, index) => (
                      <Draggable
                        key={action.label}
                        draggableId={action.label}
                        index={index}
                        isDragDisabled={getActionDisabledStatus(action.label)}
                      >
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className='flex border border-base-100 rounded-xl p-2 w-20 h-20 items-center justify-center relative group'
                          >
                            <div
                              {...provided.dragHandleProps}
                              className='absolute top-0 right-0 p-1 cursor-grab opacity-0 group-hover:opacity-100 transition-opacity duration-200'
                            >
                              <LucideIcons.GripVertical className='w-4 h-4 text-gray-400' />
                            </div>
                            <CircularIconButton
                              Icon={getIconComponent(action.icon)}
                              label={action.label}
                              onClick={() =>
                                !getActionDisabledStatus(action.label) &&
                                handleActionClick(action.handler)
                              }
                              colour={
                                getActionDisabledStatus(action.label)
                                  ? 'base'
                                  : 'brand'
                              }
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </Popup>
      <EntityPopupHandler
        entityType={entityType}
        entityId={entityId}
        entity={entity}
        onUpdate={onUpdate}
        handler={activePopup}
      />
    </>
  );
};

export default FavouriteActionsPopup;
