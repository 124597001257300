import React, { useState, useEffect, useContext } from 'react';
import {
  OutlineButton,
  Badge,
  Input,
  SoftButton,
  TextButton,
  Checkbox
} from 'core';
import { Search, Trash, Settings } from 'lucide-react';
import { supabase } from '../../../../utilities/supabase';
import { useParams } from 'react-router-dom';
import Pagination from '../../../../components/navigation/Pagination';
import { UserProfileContext } from '../../../../App';

const News = () => {
  const [posts, setPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { id: companyId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const postsPerPage = 6;
  const [selectedPosts, setSelectedPosts] = useState([]);
  const [companyDetails, setCompanyDetails] = useState(null);
  const { userProfile: currentUser } = useContext(UserProfileContext);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [showCompanyNews, setShowCompanyNews] = useState(() => {
    return currentUser.crmPreferences.showCompanyNews ?? true;
  });
  const [showUserNews, setShowUserNews] = useState(() => {
    return currentUser.crmPreferences.showUserNews ?? false;
  });

  useEffect(() => {
    fetchNews();
    fetchCompanyDetails();
  }, [companyId, currentPage, showCompanyNews, showUserNews]);

  const fetchNews = async (search = '') => {
    try {
      let query = supabase
        .from('news')
        .select('*, user_profiles(full_name)', { count: 'exact' })
        .order('source_date', { ascending: false });

      if (showCompanyNews && !showUserNews) {
        query = query.eq('company_id', companyId).is('user_id', null);
      } else if (!showCompanyNews && showUserNews) {
        query = query.eq('company_id', companyId).not('user_id', 'is', null);
      } else if (showCompanyNews && showUserNews) {
        query = query.or(`company_id.eq.${companyId},user_id.not.is.null`);
      } else {
        // If both are false, return no results
        setPosts([]);
        setTotalPages(0);
        return;
      }

      if (search) {
        query = query.or(`title.ilike.*${search}*,content.ilike.*${search}*`);
      }

      const { data, error, count } = await query.range(
        (currentPage - 1) * postsPerPage,
        currentPage * postsPerPage - 1
      );

      if (error) throw error;

      setPosts(data);
      setTotalPages(Math.ceil(count / postsPerPage));
    } catch (error) {
      console.error('Error fetching news:', error);
    }
  };

  const fetchCompanyDetails = async () => {
    try {
      const { data, error } = await supabase
        .from('companies')
        .select('id,name,links')
        .eq('id', companyId)
        .single();

      if (error) throw error;

      setCompanyDetails(data);
    } catch (error) {
      console.error('Error fetching company details:', error);
    }
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchNews(searchTerm);
  };

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const handleDeletePost = async postId => {
    try {
      const { error } = await supabase.from('news').delete().eq('id', postId);

      if (error) throw error;

      // Refresh the news list after deletion
      fetchNews(searchTerm);
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const toggleReadStatus = async (postId, currentSeenBy) => {
    try {
      const updatedSeenBy = currentSeenBy.includes(currentUser.id)
        ? currentSeenBy.filter(id => id !== currentUser.id)
        : [...currentSeenBy, currentUser.id];

      const { error } = await supabase
        .from('news')
        .update({ seen_by: updatedSeenBy })
        .eq('id', postId);

      if (error) throw error;

      // Update the local state
      setPosts(
        posts.map(post =>
          post.id === postId ? { ...post, seen_by: updatedSeenBy } : post
        )
      );
    } catch (error) {
      console.error('Error updating read status:', error);
    }
  };

  const handleFetchInsights = async () => {
    try {
      const payload = {
        company: companyDetails
      };
      const { data, error } = await supabase.functions.invoke(
        'news/initiate-automation',
        {
          body: JSON.stringify(payload)
        }
      );
      if (error) throw error;
      console.log('Insights fetched:', data);
      // Handle the response as needed
    } catch (error) {
      console.error('Error fetching insights:', error);
    }
  };

  const handleDraftEmail = async () => {
    try {
      const selectedPostsDetails = posts.filter(post =>
        selectedPosts.includes(post.id)
      );
      const { data, error } = await supabase.functions.invoke(
        'news/draft-email',
        {
          body: JSON.stringify({
            company: companyDetails,
            selectedPosts: selectedPostsDetails
          })
        }
      );
      if (error) throw error;
      console.log('Email drafted:', data);
      // Handle the response as needed
    } catch (error) {
      console.error('Error drafting email:', error);
    }
  };

  const togglePostSelection = postId => {
    setSelectedPosts(prevSelected =>
      prevSelected.includes(postId)
        ? prevSelected.filter(id => id !== postId)
        : [...prevSelected, postId]
    );
  };

  const handleDeleteSelected = async () => {
    try {
      const { error } = await supabase
        .from('news')
        .delete()
        .in('id', selectedPosts);

      if (error) throw error;

      setSelectedPosts([]);
      fetchNews(searchTerm);
    } catch (error) {
      console.error('Error deleting selected posts:', error);
    }
  };

  const clearSelections = () => {
    setSelectedPosts([]);
  };

  const updateUserPreferences = async (key, value) => {
    try {
      const updatedPreferences = {
        ...currentUser.crmPreferences,
        [key]: value
      };

      const { error } = await supabase
        .from('user_profiles')
        .update({ crm_preferences: updatedPreferences })
        .eq('id', currentUser.id);

      if (error) throw error;

      // Update the local user profile context
      currentUser.crmPreferences = updatedPreferences;
    } catch (error) {
      console.error('Error updating user preferences:', error);
    }
  };

  const handleShowCompanyNewsChange = () => {
    const newValue = !showCompanyNews;
    setShowCompanyNews(newValue);
    updateUserPreferences('showCompanyNews', newValue);
  };

  const handleShowUserNewsChange = () => {
    const newValue = !showUserNews;
    setShowUserNews(newValue);
    updateUserPreferences('showUserNews', newValue);
  };

  return (
    <div className='bg-white'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto lg:mx-0'>
          <p className='text-lg leading-8 text-gray-600'>
            Leverage AI-powered insights to stay ahead with curated news and
            developments from across the web.
          </p>
          <div className='mt-4 flex w-full border-b pb-6 space-x-2'>
            <div className='flex min-w-32 sm:w-1/3 md:w-full'>
              <SoftButton colour='primary' onClick={handleFetchInsights}>
                Fetch insights
              </SoftButton>
            </div>
            <div className='flex w-full items-center justify-end space-x-1'>
              <Input
                placeholder='Search news...'
                leftIcon={Search}
                className='w-64'
                size='md'
                value={searchTerm}
                onChange={e => {
                  setSearchTerm(e.target.value);
                  if (e.target.value === '') {
                    setCurrentPage(1);
                    fetchNews();
                  } else {
                    handleSearch();
                  }
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
              />
              <div className='relative'>
                <TextButton
                  onClick={() => setIsOptionsOpen(!isOptionsOpen)}
                  rightIcon={<Settings size={16} />}
                >
                  <span className='hidden sm:block'>Options</span>
                </TextButton>
                {isOptionsOpen && (
                  <div className='absolute z-10 w-64 mt-1 bg-white border border-gray-300 rounded-md shadow-lg p-2 top-full right-0'>
                    <div className='flex items-center justify-between mb-2'>
                      <span className='text-sm'>Company News</span>
                      <Checkbox
                        checked={showCompanyNews}
                        onChange={handleShowCompanyNewsChange}
                      />
                    </div>
                    <div className='flex items-center justify-between'>
                      <span className='text-sm'>User News</span>
                      <Checkbox
                        checked={showUserNews}
                        onChange={handleShowUserNewsChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {selectedPosts.length > 0 && (
            <div className='mt-4 flex items-center justify-between space-x-2 p-2 bg-base-50 rounded-lg'>
              <div className='flex items-center'>
                <span className='text-sm text-base-600 dark:text-white'>
                  {selectedPosts.length} item
                  {selectedPosts.length !== 1 ? 's' : ''} selected
                </span>
                <TextButton colour='danger' onClick={handleDeleteSelected}>
                  Delete selected
                </TextButton>
                <TextButton colour='base' onClick={clearSelections}>
                  Clear selected
                </TextButton>
              </div>
              <OutlineButton colour='primary' onClick={handleDraftEmail}>
                Draft email
              </OutlineButton>
            </div>
          )}
        </div>

        <div className='mx-auto mt-4 mb-14 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 pt-10 sm:mt-4 sm:pt-8 lg:mx-0 lg:max-w-none md:grid-cols-2 xl:grid-cols-3'>
          {posts.map(post => (
            <article
              key={post.id}
              className={`flex max-w-2xl flex-col items-start justify-start relative group cursor-pointer p-1 ${
                selectedPosts.includes(post.id)
                  ? 'bg-primary-100 dark:bg-primary-700 rounded-md'
                  : ''
              }`}
              onClick={() => togglePostSelection(post.id)}
            >
              <div className='absolute top-0 right-0 hidden group-hover:flex items-center'>
                <SoftButton
                  colour='danger'
                  size='sm'
                  onClick={e => {
                    e.stopPropagation();
                    handleDeletePost(post.id);
                  }}
                >
                  <Trash size={16} />
                </SoftButton>
              </div>
              <div className='flex items-center gap-x-4 text-xs'>
                <time dateTime={post.source_date} className='text-gray-500'>
                  {new Date(post.source_date).toLocaleDateString('en-GB', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </time>
                <SoftButton
                  colour={
                    post.seen_by.includes(currentUser.id)
                      ? 'success'
                      : 'primary'
                  }
                  onClick={e => {
                    e.stopPropagation();
                    toggleReadStatus(post.id, post.seen_by);
                  }}
                  size='xs'
                  className='group'
                >
                  <span className='group-hover:hidden'>
                    {post.seen_by.includes(currentUser.id) ? 'Read' : 'Unread'}
                  </span>
                  <span className='hidden group-hover:inline'>
                    {post.seen_by.includes(currentUser.id)
                      ? 'Mark as unread'
                      : 'Mark as read'}
                  </span>
                </SoftButton>
              </div>
              <div className='group relative space-x-1'>
                <h3 className='mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600'>
                  <a href='#'>
                    <span className='absolute inset-0 text-pretty' />
                    {post.title}
                  </a>
                </h3>
                {post.company_id && <Badge size='xs'>Company</Badge>}
                {post.user_id && (
                  <Badge size='xs' colour='info'>
                    {post.user_profiles.full_name}
                  </Badge>
                )}
                <p className='mt-5 text-sm leading-6 text-gray-600 text-pretty'>
                  {post.content}
                </p>
              </div>
            </article>
          ))}
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default News;
