import React from 'react';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/react';
import { Eye, MoreVertical, Trash } from 'lucide-react';
import { Tooltip, Badge, UserProfilePicture } from 'core';
import { getIconComponent } from '../../../utilities/Formatting';
import { useNavigate } from 'react-router-dom';

const renderValue = value => {
  try {
    console.log(value);
    if (value === null || value === undefined || value === '')
      return <span className='text-gray-400'>N/A</span>;
    // Handle objects
    if (typeof value === 'object' && value !== null) {
      // Handle arrays
      if (Array.isArray(value)) {
        if (value.length === 0)
          return <span className='text-gray-400'>N/A</span>;

        // Handle phone numbers array
        if (value[0]?.type) {
          return (
            <div>
              {value.map((phone, index) => (
                <div key={index} className='flex items-center mb-1'>
                  <span className='text-xs font-medium text-gray-500 mr-2 capitalize'>
                    {phone.type}:
                  </span>
                  <span className='text-sm'>{phone.number}</span>
                </div>
              ))}
            </div>
          );
        }

        // Handle tags array
        if (value[0]?.name) {
          return (
            <div className='flex flex-wrap gap-2'>
              {value.map((tag, index) =>
                tag.category?.metadata?.pipelines ? (
                  <Badge
                    key={index}
                    colour={
                      tag?.colour || tag.category?.metadata?.colour || 'primary'
                    }
                    size='xs'
                  >
                    {tag.name}
                  </Badge>
                ) : null
              )}
            </div>
          );
        }

        // Handle quote options
        if (value[0]?.quote_value) {
          return (
            <div className='space-y-2 pl-6'>
              {value.map((option, index) => (
                <div key={index} className='grid grid-cols-3 gap-x-2 text-xs'>
                  <span className='text-gray-600'>{option.trainers?.name}</span>
                  <span className='font-medium'>
                    £{option.quote_value.toFixed(2)}
                  </span>
                  <span>{option.status.type}</span>
                </div>
              ))}
            </div>
          );
        }
      }

      // Handle profile object
      if (value.first_name) {
        return (
          <span className='flex items-center'>
            <UserProfilePicture
              firstName={value.first_name}
              lastName={value.last_name}
              profilePicture={value.profile_photo}
              size='sm'
            />
            <span className='ml-2'>
              {value.first_name} {value.last_name}
            </span>
          </span>
        );
      }

      // Handle simple named object
      if (value.name) {
        return value.name;
      }

      // Handle empty objects
      if (Object.keys(value).length === 0)
        return <span className='text-gray-400'>N/A</span>;

      // Fallback for other objects
      try {
        return JSON.stringify(value);
      } catch (e) {
        return <span className='text-gray-400'>Invalid Value</span>;
      }
    }

    // Handle strings
    if (typeof value === 'string') {
      if (!isNaN(Date.parse(value))) {
        return new Date(value).toLocaleDateString();
      }
      if (/<[a-z][\s\S]*>/i.test(value)) {
        return <div dangerouslySetInnerHTML={{ __html: value }} />;
      }
    }

    // Final fallback
    try {
      return String(value);
    } catch (e) {
      return <span className='text-gray-400'>Invalid Value</span>;
    }
  } catch (error) {
    console.log(error);
  }
};

const PipelineItem = ({ item, tableName, config, removeItemFromPipeline }) => {
  const navigate = useNavigate();
  return (
    <div className='relative p-2'>
      <div className='absolute right-2 top-2'>
        <Menu as='div' className='relative inline-block text-left items-center'>
          <div>
            <MenuButton>
              <span className='sr-only'>Options</span>
              <MoreVertical className='h-4 w-4' aria-hidden='true' />
            </MenuButton>
          </div>
          <MenuItems className='absolute right-0 z-20 w-42 origin-top-right bg-white divide-y divide-base-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div className='px-1 py-1'>
              <MenuItem>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-primary-100 text-base-900' : 'text-base-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={() => {
                      navigate(
                        `/${tableName.replaceAll('_', '-')}/${
                          item.id || item.display_id
                        }`
                      );
                    }}
                  >
                    <Eye
                      size={18}
                      className='mr-2 flex-shrink-0'
                      aria-hidden='true'
                    />
                    View
                  </button>
                )}
              </MenuItem>
              <MenuItem>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-primary-100 text-base-900' : 'text-base-900'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    onClick={() => removeItemFromPipeline(item.id, tableName)}
                  >
                    <Trash
                      size={18}
                      className='mr-2 text-danger-600 flex-shrink-0'
                      aria-hidden='true'
                    />
                    Remove
                  </button>
                )}
              </MenuItem>
            </div>
          </MenuItems>
        </Menu>
      </div>
      {Object.entries(config)
        .sort(([, a], [, b]) => (a.order || 0) - (b.order || 0))
        .map(([key, value]) => {
          if (key === 'pipelines' || key === 'id' || !item.hasOwnProperty(key))
            return null;
          return (
            <div
              key={key}
              className={`mb-3 ${
                value?.inline ? 'inline-block mr-4' : 'block'
              }`}
            >
              {value?.labelStyle !== 'hidden' && (
                <label
                  className={`text-xs font-medium text-gray-700 dark:text-gray-300 mb-1 mr-2 ${
                    value?.labelStyle === 'iconOnly' ? 'sr-only' : ''
                  }`}
                >
                  {value?.icon &&
                    React.createElement(getIconComponent(value.icon), {
                      size: 14,
                      className: 'inline-block mr-2 text-base-500 flex-shrink-0'
                    })}
                  {value?.labelStyle !== 'iconOnly' &&
                    key
                      .split('_')
                      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(' ')}
                </label>
              )}
              <Tooltip
                content={key
                  .split('_')
                  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')}
                className='text-xs'
              >
                <div
                  className={`text-sm ${
                    value?.labelStyle === 'iconOnly'
                      ? 'inline-flex items-center'
                      : ''
                  }`}
                >
                  {value?.labelStyle === 'iconOnly' &&
                    value?.icon &&
                    React.createElement(getIconComponent(value.icon), {
                      size: 14,
                      className: 'inline-block mr-2 text-base-500 flex-shrink-0'
                    })}
                  <div className='flex-grow text-wrap max-w-52'>
                    {renderValue(item[key])}
                  </div>
                </div>
              </Tooltip>
            </div>
          );
        })}
    </div>
  );
};

export default PipelineItem;
