import React, { useState, useEffect, useContext } from 'react';
import UserProfile from '../../../../models/UserProfile';
import {
  Input,
  DropdownInput,
  Modal,
  FilledButton,
  PageHeading,
  Pagination,
  Badge,
  Tooltip,
  useNotification
} from 'core';
import { Search, MoreVertical, User, XCircle, RefreshCw } from 'lucide-react';
import {
  FilterOperatorEnum,
  EntityTypeEnum
} from '../../../../utilities/Enumerables';
import { PERMISSIONS } from '../../../../utilities/Permissions';
import { Menu, MenuItem, MenuItems, MenuButton } from '@headlessui/react';
import { supabase } from '../../../../utilities/supabase';
import { UserProfileContext } from '../../../../App';
import CreateAdmin from '../../../../components/popups/user/CreateAdmin';
import { v4 as uuidv4 } from 'uuid';
import { formatDateOptions } from '../../../../utilities/Formatting';

const TeamMembers = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(null);
  const [permissionGroups, setPermissionGroups] = useState([]);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const { userProfile } = useContext(UserProfileContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { addNotification } = useNotification();

  useEffect(() => {
    const loadTeamMembers = async () => {
      try {
        const { data } = await UserProfile.getAll({
          bohsa_admin: { value: true, operator: FilterOperatorEnum.IS }
        });
        setTeamMembers(data || []);
      } catch (err) {
        console.error('Failed to load team members:', err);
        setError('Failed to load team members. Please try again later.');
      }
    };

    const loadPermissionGroups = async () => {
      try {
        const { data, error } = await supabase
          .from('permission_groups')
          .select('*');

        if (error) throw error;

        const groups = data.map(group => ({
          value: group.id,
          label: group.label,
          permissions: group.permissions,
          hierarchy: group.hierarchy
        }));

        setPermissionGroups(groups);
      } catch (err) {
        console.error('Failed to load permission groups:', err);
      }
    };

    loadTeamMembers();
    loadPermissionGroups();
  }, []);

  // Separate useEffect for realtime subscription
  useEffect(() => {
    const channel = supabase
      .channel('team-members')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'user_profiles'
        },
        async payload => {
          // Only refresh if the change affects an admin user
          if (payload.new?.bohsa_admin || payload.old?.bohsa_admin) {
            try {
              const { data } = await UserProfile.getAll({
                bohsa_admin: { value: true, operator: FilterOperatorEnum.IS }
              });
              setTeamMembers(data || []);
            } catch (err) {
              console.error('Failed to load team members:', err);
              setError('Failed to load team members. Please try again later.');
            }
          }
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  const handlePermissionChange = async (memberId, newPermissionGroupId) => {
    try {
      const permissionGroup = permissionGroups.find(
        g => g.value == newPermissionGroupId
      );
      if (!permissionGroup) throw new Error('Invalid permission group');

      const { data: userProfiles } = await UserProfile.getAll({
        id: { value: memberId }
      });
      const userProfile = userProfiles[0];
      if (!userProfile) throw new Error('User not found');
      userProfile.role = { id: newPermissionGroupId };
      await userProfile.update();
    } catch (err) {
      console.error('Failed to update permissions:', err);
    }
  };

  const handleAdminCreated = async newAdmin => {
    //setIsInviteModalOpen(false);
  };

  const handleRemoveAdmin = async () => {
    try {
      if (!selectedMember) return;

      const { data: userProfiles } = await UserProfile.getAll({
        id: { value: selectedMember.id }
      });
      const userProfile = userProfiles[0];
      if (!userProfile) throw new Error('User not found');

      userProfile.role = { id: 1 };
      userProfile.bohsa_admin = false;
      userProfile.metrics = { ...userProfile.metrics, signup: null };
      await userProfile.update();

      setTeamMembers(prev => prev.filter(m => m.id !== selectedMember.id));
      setIsRemoveModalOpen(false);
      setSelectedMember(null);
    } catch (err) {
      console.error('Failed to remove admin:', err);
    }
  };

  const handleResendInvite = async member => {
    try {
      const { data: userProfiles } = await UserProfile.getAll({
        id: { value: member.id }
      });
      const userProfile = userProfiles[0];
      if (!userProfile) throw new Error('User not found');

      // Generate new invite code and set expiry to 1 hour from now
      const authCode = uuidv4();
      const expiryTime = Math.floor(Date.now() / 1000) + 3600;

      userProfile.metrics = {
        ...userProfile.metrics,
        signup: {
          code: authCode,
          expiry: expiryTime.toString()
        }
      };
      await userProfile.update();

      // Generate and copy invite URL
      const inviteLink = `${window.location.origin}/signup/${member.id}?auth_code=${authCode}`;
      await navigator.clipboard.writeText(inviteLink);

      // Show notification
      addNotification({
        type: 'success',
        title: 'Invite Link Copied',
        description: 'A new invite link has been copied to your clipboard'
      });
    } catch (err) {
      console.error('Failed to resend invite:', err);
      addNotification({
        type: 'error',
        title: 'Failed to Resend Invite',
        description: 'There was an error generating a new invite link'
      });
    }
  };

  const getUserStatus = member => {
    if (!member.metrics?.signup) return { type: 'active', label: 'Active' };

    const now = Math.floor(Date.now() / 1000);
    const expiryDate = new Date(member.metrics.signup.expiry * 1000);
    if (member.metrics.signup.expiry > now) {
      return {
        type: 'pending',
        label: 'Pending',
        expires_in: expiryDate
      };
    }
    return { type: 'expired', label: 'Expired' };
  };

  const filteredMembers = teamMembers.filter(
    member =>
      member.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      member.email?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination calculations
  const totalPages = Math.ceil(filteredMembers.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedMembers = filteredMembers.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  if (error) {
    return <div className='p-4 text-red-500'>{error}</div>;
  }

  return (
    <div className='container mx-auto flex flex-col h-full'>
      <PageHeading
        title='Team Members'
        pages={[
          { name: 'Settings', href: '/settings' },
          { name: 'Team Members', href: '/settings/team-members' }
        ]}
        actions={[
          <Input
            placeholder='Search team members...'
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            leftIcon={Search}
          />,
          userProfile.hasPermission(
            EntityTypeEnum.Settings,
            PERMISSIONS[EntityTypeEnum.Settings].CREATE_TEAMS
          ) && (
            <FilledButton onClick={() => setIsInviteModalOpen(true)}>
              Invite Users
            </FilledButton>
          )
        ]}
      />

      <div className='py-6 overflow-y-auto'>
        <ul
          role='list'
          className='divide-y divide-gray-100 bg-white shadow rounded-lg mx-1'
        >
          {paginatedMembers.length === 0 ? (
            <div className='text-center py-8 text-gray-500'>
              No team members found
            </div>
          ) : (
            paginatedMembers.map(member => {
              const status = getUserStatus(member);
              return (
                <li
                  key={member.id}
                  className='flex justify-between gap-x-6 p-2'
                >
                  <div className='flex min-w-0 gap-x-4'>
                    {member.profilePhoto ? (
                      <img
                        src={member.profilePhoto}
                        alt=''
                        className='h-10 w-10 flex-none rounded-full bg-gray-50'
                      />
                    ) : (
                      <div className='h-10 w-10 flex-none rounded-full bg-base-200 flex items-center justify-center'>
                        {member.firstName?.charAt(0).toUpperCase()}
                      </div>
                    )}
                    <div className='min-w-0 flex-auto'>
                      <div className='flex items-center gap-2'>
                        <p className='text-sm/6 font-semibold text-gray-900'>
                          {member.fullName}
                        </p>
                      </div>
                      <p className='mt-1 flex text-xs/5 text-gray-500'>
                        <a
                          href={`mailto:${member.email}`}
                          className='truncate hover:underline'
                        >
                          {member.email}
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className='flex shrink-0 items-center gap-x-6'>
                    <Tooltip
                      size='sm'
                      content={
                        status.expires_in &&
                        `Invite expires ${formatDateOptions(status.expires_in, {
                          small: true
                        })}`
                      }
                    >
                      <Badge
                        colour={
                          status.type === 'active'
                            ? 'success'
                            : status.type === 'pending'
                            ? 'warning'
                            : 'danger'
                        }
                      >
                        {status.label}
                      </Badge>
                    </Tooltip>
                    <div className='hidden sm:flex sm:flex-col sm:items-end'>
                      <DropdownInput
                        disabled={
                          !userProfile.hasPermission(
                            EntityTypeEnum.Settings,
                            PERMISSIONS[EntityTypeEnum.Settings].EDIT_TEAMS
                          ) ||
                          userProfile.role.hierarchy < member.role.hierarchy
                        }
                        options={permissionGroups.map(group => ({
                          ...group,
                          disabled: group.hierarchy > userProfile.role.hierarchy
                        }))}
                        value={member.role.id}
                        onChange={e =>
                          handlePermissionChange(member.id, e.target.value)
                        }
                        size='sm'
                        className='max-w-48'
                      />
                    </div>
                    <Menu as='div' className='relative flex-none'>
                      <MenuButton className='-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900'>
                        <span className='sr-only'>Open options</span>
                        <MoreVertical className='h-5 w-5' aria-hidden='true' />
                      </MenuButton>
                      <MenuItems className='absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
                        <MenuItem>
                          {({ active }) => (
                            <a
                              href={`/user-profiles/${member.displayId}`}
                              className={`block px-3 py-1 text-sm/6 text-gray-900 ${
                                active ? 'bg-gray-50' : ''
                              }`}
                            >
                              <div className='flex items-center gap-2'>
                                <User className='h-4 w-4' />
                                View profile
                              </div>
                            </a>
                          )}
                        </MenuItem>

                        {(status.type === 'expired' ||
                          status.type === 'pending') && (
                          <MenuItem>
                            {({ active }) => (
                              <button
                                onClick={() => handleResendInvite(member)}
                                className={`block w-full px-3 py-1 text-sm/6 text-gray-900 ${
                                  active ? 'bg-gray-50' : ''
                                }`}
                              >
                                <div className='flex items-center gap-2'>
                                  <RefreshCw className='h-4 w-4' />
                                  Resend Invite
                                </div>
                              </button>
                            )}
                          </MenuItem>
                        )}

                        {userProfile.hasPermission(
                          EntityTypeEnum.Settings,
                          PERMISSIONS[EntityTypeEnum.Settings].EDIT_TEAMS
                        ) && (
                          <MenuItem>
                            {({ active }) => (
                              <button
                                onClick={() => {
                                  setSelectedMember(member);
                                  setIsRemoveModalOpen(true);
                                }}
                                className={`block w-full px-3 py-1 text-sm/6 text-red-600 ${
                                  active ? 'bg-gray-50' : ''
                                }`}
                              >
                                <div className='flex items-center gap-2'>
                                  <XCircle className='h-4 w-4' />
                                  Remove Admin
                                </div>
                              </button>
                            )}
                          </MenuItem>
                        )}
                      </MenuItems>
                    </Menu>
                  </div>
                </li>
              );
            })
          )}
        </ul>

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={setItemsPerPage}
        />
      </div>

      <CreateAdmin
        isOpen={isInviteModalOpen}
        onClose={() => setIsInviteModalOpen(false)}
        onSuccess={handleAdminCreated}
      />

      <Modal
        isOpen={isRemoveModalOpen}
        onClose={() => {
          setIsRemoveModalOpen(false);
          setSelectedMember(null);
        }}
        title='Remove Admin Access'
        style='danger'
        actions={[
          {
            label: 'Cancel',
            onClick: () => {
              setIsRemoveModalOpen(false);
              setSelectedMember(null);
            },
            style: 'base'
          },
          {
            label: 'Remove Admin',
            onClick: handleRemoveAdmin,
            style: 'danger'
          }
        ]}
      >
        <p className='text-base-500'>
          Are you sure you want to remove admin access for{' '}
          {selectedMember?.fullName}?
        </p>
        <i className='text-base-500 text-sm'>
          This will only remove admin access from the user profile, it will not
          delete the profile. This action can not be undone.
        </i>
      </Modal>
    </div>
  );
};

export default TeamMembers;
