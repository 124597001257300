import { useState, useEffect, useContext } from 'react';
import { supabase } from '../../../utilities/supabase';
import {
  useParams,
  useNavigate,
  Link,
  useSearchParams
} from 'react-router-dom';
import { PrimaryInput } from '../../../components/inputs/Forms';
import { FilledButton } from 'core';
import PasswordInput from '../../../components/inputs/Password';
import Auth from '../Auth';
import UserProfile from '../../../models/UserProfile';
import { UserProfileContext } from '../../../App';
import { LoaderCircle, ArrowLeft } from 'lucide-react';

const SignupConnect = ({ session }) => {
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [profile, setProfile] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [hasStarted, setHasStarted] = useState({
    password: false,
    confirmPassword: false
  });

  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setUserProfile } = useContext(UserProfileContext);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const code = searchParams.get('auth_code');
        if (!code) {
          setError({
            title: 'Invalid Signup Link',
            message: 'Missing signup code. Please check your invitation link.'
          });
          return;
        }

        const profileData = await UserProfile.fetchOne(
          'id',
          id,
          'active,auth,bohsa_admin,created_date,display_id,email,first_name,last_name,id,metrics'
        );
        if (error) throw error;
        if (!profileData) throw new Error('Profile not found');

        // Check if profile already has auth connected
        if (profileData.auth) {
          setError({
            title: 'Account Already Connected',
            message:
              'This account has already been connected to a login. Please sign in instead.'
          });
          return;
        }

        // Check if signup code exists and matches
        if (
          !profileData.metrics?.signup?.code ||
          profileData.metrics.signup.code !== code
        ) {
          setError({
            title: 'Invalid Signup Link',
            message:
              'This signup link is invalid. Please request a new invitation.'
          });
          return;
        }

        // Check if signup code has expired
        if (
          profileData.metrics.signup.expiry &&
          Math.floor(Date.now() / 1000) > profileData.metrics.signup.expiry
        ) {
          setError({
            title: 'Expired Signup Link',
            message:
              'This signup link has expired. Please request a new invitation.'
          });
          return;
        }

        setProfile(profileData);
      } catch (err) {
        setError({
          title: 'Profile Error',
          message:
            err.message ||
            'Unable to find profile. Please check your invitation link and try again.'
        });
        console.error('Error fetching profile:', err);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchProfile();
    }
  }, [id, searchParams]);

  useEffect(() => {
    if (hasStarted.confirmPassword) {
      const passwordsMatch = formData.password === formData.confirmPassword;
      setFormErrors(prev => ({
        ...prev,
        confirmPassword: passwordsMatch ? null : "Passwords don't match"
      }));
    }
  }, [formData.password, formData.confirmPassword, hasStarted.confirmPassword]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setHasStarted(prev => ({ ...prev, [name]: true }));

    if (name === 'password' && value.trim()) {
      setFormErrors(prev => {
        const { [name]: _, ...rest } = prev;
        return rest;
      });
    }
  };

  const handleConnectExisting = async () => {
    setLoading(true);
    setError(null);

    try {
      // Update the profile with the current user's auth ID
      const { error: updateError } = await profile.update({
        auth: session.user.id,
        metrics: {
          ...profile.metrics,
          signup: null
        }
      });

      if (updateError) throw updateError;
      navigate('/');
    } catch (error) {
      setError({
        title: 'Connection Failed',
        message:
          'There was a problem connecting your account. Please try again or contact support if the issue persists.'
      });
      console.error('Connection error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSignup = async e => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setFormErrors({});

    const errors = {};
    if (!formData.password.trim()) errors.password = 'Password is required';
    if (formData.password !== formData.confirmPassword)
      errors.confirmPassword = "Passwords don't match";

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setLoading(false);
      return;
    }

    try {
      const { data, error } = await supabase.auth.signUp({
        email: profile.email,
        password: formData.password,
        options: {
          data: {
            first_name: profile.firstName,
            last_name: profile.lastName,
            profile: id
          }
        }
      });

      if (error) throw error;

      // Fetch the new UserProfile
      const { data: userProfiles, error: profileError } =
        await UserProfile.getByAuthId(data.user.id);

      if (profileError) throw profileError;

      if (userProfiles && userProfiles.length > 0) {
        setUserProfile(userProfiles[0]);
        navigate('/');
      } else {
        throw new Error('User profile not found after signup');
      }
    } catch (error) {
      setError({
        title: 'Signup Failed',
        message:
          'There was a problem creating your account. Please try again or contact support if the issue persists.'
      });
      console.error('Signup error:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!profile && !error) {
    return (
      <Auth title='Connect Account'>
        <div className='flex flex-col items-center justify-center p-8'>
          <LoaderCircle className='w-8 h-8 text-brand-600 animate-spin mb-4' />
          <p className='text-gray-600 font-medium'>
            Loading profile details...
          </p>
        </div>
      </Auth>
    );
  }

  if (error) {
    return (
      <Auth title={error.title}>
        <div className='max-w-lg mx-auto'>
          <p className='text-gray-600 text-center mb-6'>{error.message}</p>
          <div className='flex justify-center mt-4'>
            <Link to='/' className='text-sm flex items-center gap-2'>
              <ArrowLeft className='w-4 h-4' />
              Back to home
            </Link>
          </div>
        </div>
      </Auth>
    );
  }

  if (session) {
    return (
      <Auth title='Connect Account'>
        <div className='bg-white rounded-lg shadow-sm border border-gray-200 p-6 mb-6'>
          <h3 className='text-lg font-semibold text-gray-900 mb-4'>
            Connect Existing Account
          </h3>
          <p className='text-gray-600 mb-6'>
            Would you like to connect this profile to your existing account?
          </p>
          <div className='space-y-3'>
            <div className='flex items-center'>
              <span className='text-gray-600 font-medium w-20'>Name:</span>
              <span className='text-gray-900'>
                {profile.firstName} {profile.lastName}
              </span>
            </div>
            <div className='flex items-center'>
              <span className='text-gray-600 font-medium w-20'>Email:</span>
              <span className='text-gray-900'>{profile.email}</span>
            </div>
          </div>
          {error && (
            <div className='bg-white rounded-lg shadow-sm border border-red-200 p-4 mt-4 mb-4'>
              <div className='flex items-center space-x-3'>
                <svg
                  className='h-5 w-5 text-red-400'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                  />
                </svg>
                <div>
                  <p className='text-sm font-semibold text-red-800'>
                    {error.title}
                  </p>
                  <p className='text-sm text-red-600'>{error.message}</p>
                </div>
              </div>
            </div>
          )}
          <div className='flex space-x-2 mt-6'>
            <Link to='/' className='w-full'>
              <FilledButton
                className='w-full justify-center'
                colour='base'
                size='md'
                disabled={loading}
              >
                Cancel
              </FilledButton>
            </Link>
            <FilledButton
              onClick={handleConnectExisting}
              colour='primary'
              className='w-full justify-center'
              size='md'
              disabled={loading}
            >
              {loading ? 'Connecting...' : 'Connect Account'}
            </FilledButton>
          </div>
        </div>
      </Auth>
    );
  }

  return (
    <Auth title='Connect Account' onSubmit={handleSignup} type='signup'>
      <div className='bg-white rounded-lg shadow-sm border border-gray-200 p-6 mb-6'>
        <h3 className='text-lg font-semibold text-gray-900 mb-4'>
          Profile Details
        </h3>
        <div className='space-y-3'>
          <div className='flex items-center'>
            <span className='text-gray-600 font-medium w-20'>Name:</span>
            <span className='text-gray-900'>
              {profile.firstName} {profile.lastName}
            </span>
          </div>
          <div className='flex items-center'>
            <span className='text-gray-600 font-medium w-20'>Email:</span>
            <span className='text-gray-900'>{profile.email}</span>
          </div>
        </div>
      </div>

      <PasswordInput
        id='password'
        name='password'
        type='password'
        placeholder='Enter your password'
        value={formData.password}
        onChange={handleInputChange}
        label='Password'
        error={formErrors.password}
      />
      <PrimaryInput
        id='confirm-password'
        name='confirmPassword'
        type='password'
        placeholder='Confirm your password'
        value={formData.confirmPassword}
        onChange={handleInputChange}
        label='Confirm Password'
        error={formErrors.confirmPassword}
      />
      {error && (
        <div className='bg-white rounded-lg shadow-sm border border-red-200 p-4 mb-4'>
          <div className='flex items-center space-x-3'>
            <svg
              className='h-5 w-5 text-red-400'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
              />
            </svg>
            <div>
              <p className='text-sm font-semibold text-red-800'>
                {error.title}
              </p>
              <p className='text-sm text-red-600'>{error.message}</p>
            </div>
          </div>
        </div>
      )}
      <div>
        <FilledButton
          type='submit'
          className='w-full'
          colour='primary'
          size='md'
        >
          {loading ? 'Connecting...' : 'Connect Account'}
        </FilledButton>
      </div>
    </Auth>
  );
};

export default SignupConnect;
