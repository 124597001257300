import BaseModel from './BaseModel';
import UserProfile from './UserProfile';

/**
 * Represents a Quote in the system.
 * @extends BaseModel
 */
class Quote extends BaseModel {
  /** @type {string} The name of the database table for Quotes */
  static table = 'quotes';

  /** @type {string} The SQL query to select Quote data with related information */
  static selectQuery = `
    *,
    companies!inner (name, account_number),
    user_profiles!inner (id, first_name, last_name, email, profile_photo),
    status:core_entity_types!status (*),
    exp_level:core_entity_types!exp_level(*)
  `;

  /**
   * Creates a Quote instance from database format data
   * @param {Object} data - The database format quote data
   * @returns {Quote} A new Quote instance
   */
  static fromDatabase(data = {}) {
    const quote = new Quote();
    quote.id = data.id;
    quote.createdDate = data.created_date;
    quote.updatedDate = data.updated_date;
    quote.requestedDate = data.requested_date;
    quote.courseName = data.course_name;
    quote.status = data.status
      ? { id: data.status.id, name: data.status.type }
      : null;
    quote.company = data.companies
      ? {
          id: data.company_id,
          name: data.companies.name,
          accountNumber: data.companies.account_number
        }
      : null;
    quote.owner = data.user_profiles
      ? {
          id: data.owner,
          firstName: data.user_profiles.first_name,
          lastName: data.user_profiles.last_name,
          fullName: `${data.user_profiles.first_name} ${data.user_profiles.last_name}`,
          profilePhoto: data.user_profiles.profile_photo
        }
      : null;
    quote.lastChaseDate = data.last_chase_date;
    quote.nextChaseDate = data.next_chase_date;
    quote.readyDate = data.ready_date;
    quote.sentDate = data.sent_date;
    quote.expiryDate = data.expiry_date;
    quote.completedDate = data.completed_date;
    quote.fullProposal = data.full_proposal;
    quote.cost = data.cost;
    quote.profit = data.profit;
    quote.margin = data.margin;
    quote.deliveryMethods = data.delivery_methods || [];
    quote.accreditations = data.accreditations || [];
    quote.delegates = data.delegates;
    quote.datePrefs = data.date_prefs;
    quote.locations = data.locations || '';
    quote.owners = data.owners || [];
    quote.bookers = data.bookers || [];
    quote.expLevel = data.exp_level
      ? { id: data.exp_level.id, name: data.exp_level.type }
      : null;
    quote.invoiceAddress = data.locations
      ? {
          id: data.invoice_address,
          address1: data.locations.address_1,
          address2: data.locations.address_2,
          city: data.locations.city,
          county: data.locations.county,
          postcode: data.locations.postcode
        }
      : null;
    quote.furtherInfo = data.further_info || '';
    return quote;
  }

  /**
   * Creates an instance of Quote.
   * @param {Object} data - The quote data.
   */
  constructor(data = {}) {
    super(data);
    this.requestedDate = data.requestedDate ?? this.requestedDate ?? null;
    this.courseName = data.courseName ?? this.courseName ?? null;
    this.status = data.status ?? this.status ?? null;
    this.company = data.company ?? this.company ?? null;
    this.owner = data.owner ?? this.owner ?? null;
    this.lastChaseDate = data.lastChaseDate ?? this.lastChaseDate ?? null;
    this.nextChaseDate = data.nextChaseDate ?? this.nextChaseDate ?? null;
    this.readyDate = data.readyDate ?? this.readyDate ?? null;
    this.sentDate = data.sentDate ?? this.sentDate ?? null;
    this.expiryDate = data.expiryDate ?? this.expiryDate ?? null;
    this.completedDate = data.completedDate ?? this.completedDate ?? null;
    this.fullProposal = data.fullProposal ?? this.fullProposal ?? false;
    this.cost = data.cost ?? this.cost ?? null;
    this.profit = data.profit ?? this.profit ?? null;
    this.margin = data.margin ?? this.margin ?? null;
    this.deliveryMethods = data.deliveryMethods ?? this.deliveryMethods ?? [];
    this.accreditations = data.accreditations ?? this.accreditations ?? [];
    this.delegates = data.delegates ?? this.delegates ?? null;
    this.datePrefs = data.datePrefs ?? this.datePrefs ?? null;
    this.locations = data.locations ?? this.locations ?? '';
    this.owners = data.owners ?? this.owners ?? [];
    this.bookers = data.bookers ?? this.bookers ?? [];
    this.expLevel = data.expLevel ?? this.expLevel ?? null;
    this.invoiceAddress = data.invoiceAddress ?? this.invoiceAddress ?? null;
    this.furtherInfo = data.furtherInfo ?? this.furtherInfo ?? '';
  }

  /**
   * Converts the Quote instance to a database-friendly format.
   * @returns {Object} The quote data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      requested_date: this.requestedDate,
      course_name: this.courseName,
      status: this.status ? this.status.id : null,
      company_id: this.company ? this.company.id : null,
      owner: this.owner ? this.owner.id : null,
      last_chase_date: this.lastChaseDate,
      next_chase_date: this.nextChaseDate,
      ready_date: this.readyDate,
      sent_date: this.sentDate,
      expiry_date: this.expiryDate,
      completed_date: this.completedDate,
      full_proposal: this.fullProposal,
      cost: this.cost,
      profit: this.profit,
      margin: this.margin,
      delivery_methods: this.deliveryMethods,
      accreditations: this.accreditations,
      delegates: this.delegates,
      date_prefs: this.datePrefs,
      locations: this.locations,
      owners: this.owners,
      bookers: this.bookers,
      exp_level: this.expLevel ? this.expLevel.id : null,
      invoice_address: this.invoiceAddress ? this.invoiceAddress.id : null,
      further_info: this.furtherInfo
    };
  }

  /**
   * Retrieves the user profiles for all bookers associated with this quote.
   * @returns {Promise<Array>} A promise that resolves to an array of UserModel instances.
   */
  async getBookerProfiles() {
    if (!this.bookers || this.bookers.length === 0) {
      return [];
    }

    try {
      const { data: bookerProfiles } = await UserProfile.getAll({
        id: {
          operator: 'in',
          value: this.bookers.map(booker => booker.id)
        }
      });
      return bookerProfiles;
    } catch (error) {
      console.error('[Error] Fetching contact profiles:', error);
      throw error;
    }
  }
}

export default Quote;
