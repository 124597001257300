import React, { useState, useEffect } from 'react';
import {
  Pencil,
  SquareArrowOutUpRight,
  Trash,
  AlertTriangle,
  ShieldCheck
} from 'lucide-react';
import EntityTable from '../../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import { useNavigate } from 'react-router-dom';
import CoreEntityType from '../../../../models/CoreEntityType';
import Course from '../../../../models/Course';
import { Tooltip } from 'core';
import Compliance from '../../../../models/Compliance';

const CoursesTab = ({ trainerId }) => {
  const navigate = useNavigate();
  const [statusOptions, setStatusOptions] = useState([]);
  const [accreditationOptions, setAccreditationOptions] = useState([]);
  const [activeCompliance, setActiveCompliance] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const { data: statuses } = await CoreEntityType.getAll({
          entity_id: { operator: 'eq', value: EntityTypeEnum.CourseStatus }
        });
        const { data: accreditations } = await CoreEntityType.getAll(
          {
            entity_id: {
              operator: 'eq',
              value: EntityTypeEnum.AccreditationBody
            }
          },
          1,
          100,
          'type'
        );
        const { data: compliance } = await Compliance.getAll({
          status: { value: 98 },
          trainer_id: { value: trainerId },
          type: { value: 95 }
        });

        setStatusOptions(
          statuses.map(status => ({ key: status.id, value: status.type }))
        );
        setAccreditationOptions(
          accreditations.map(accreditation => ({
            key: accreditation.id,
            value: accreditation.type
          }))
        );
        setActiveCompliance(compliance);
      } catch (error) {
        console.error('Error fetching course options:', error);
      }
    };

    fetchOptions();
  }, [trainerId]);

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/courses/${data.id}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => (window.location.href = `/courses/${data.id}`)
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  const renderAccreditation = accreditation => {
    if (!accreditation?.type) return null;

    const isValid = activeCompliance.some(
      compliance => compliance.accreditation.id === accreditation.id
    );

    return (
      <div className='flex items-center gap-2'>
        <span>{accreditation.type}</span>
        {isValid ? (
          <Tooltip content='Accreditation active' size='sm'>
            <ShieldCheck size={14} className='text-success-500' />
          </Tooltip>
        ) : (
          <Tooltip content='Accreditation not found or expired' size='sm'>
            <AlertTriangle size={14} className='text-danger-500' />
          </Tooltip>
        )}
      </div>
    );
  };

  return (
    <EntityTable
      parentContext={{ type: 'trainers' }}
      name='Courses'
      filter={[
        { field: 'id', label: 'ID', type: 'number' },
        {
          field: 'name',
          label: 'Course Name',
          type: 'text',
          operator: FilterOperatorEnum.TEXTSEARCH
        },
        {
          field: 'status',
          label: 'Status',
          type: 'select',
          options: statusOptions,
          operator: FilterOperatorEnum.EQUALS
        },
        {
          field: 'accreditation',
          label: 'Accreditation',
          type: 'select',
          options: accreditationOptions,
          operator: FilterOperatorEnum.EQUALS
        }
      ]}
      columns={[
        { label: 'ID', field: 'id' },
        { label: 'Course Name', field: 'name' },
        { label: 'Status', field: 'status.name' },
        {
          label: 'Accreditation',
          field: 'accreditation',
          cell: renderAccreditation
        }
      ]}
      menuItems={menuItems}
      model={Course}
      entityType={EntityTypeEnum.Course}
      fromFunction={true}
      defaultFilters={{ trainerId: trainerId }}
      canCreate={false}
    />
  );
};

export default CoursesTab;
