import React, { useState, useEffect } from 'react';
import { Pencil, SquareArrowOutUpRight, Trash } from 'lucide-react';
import BookingSpace from '../../../../models/BookingSpace';
import EntityTable from '../../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import { formatDate } from '../../../../utilities/Formatting';
import CoreEntityType from '../../../../models/CoreEntityType';
import EditSpace from '../../../../components/popups/booking-space/EditSpace';

const LevyTracker = ({ companyId }) => {
  const [statusOptions, setStatusOptions] = useState([]);
  const [levyStatusOptions, setLevyStatusOptions] = useState([]);
  const [selectedBookingSpace, setSelectedBookingSpace] = useState(null);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [{ data: statuses }, { data: levyStatuses }] = await Promise.all([
          CoreEntityType.getAll({
            entity_id: {
              operator: 'eq',
              value: EntityTypeEnum.BookingSpaceStatus
            }
          }),
          CoreEntityType.getAll({
            entity_id: {
              operator: 'eq',
              value: EntityTypeEnum.BookingSpaceLevyType
            }
          })
        ]);

        setStatusOptions(
          statuses.map(status => ({ key: status.id, value: status.type }))
        );
        setLevyStatusOptions(
          levyStatuses.map(status => ({ key: status.id, value: status.type }))
        );
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, []);

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/booking-space/${data.id}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => (window.location.href = `/booking-space/${data.id}`)
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  const columns = [
    { label: 'ID', field: 'id' },
    { label: 'Booking', field: 'booking.id' },
    { label: 'Delegate', field: 'delegate.fullName' },
    { label: 'Levy', field: 'levy' },
    { label: 'Levy Status', field: 'levyStatus.name' },
    {
      label: 'Updated Date',
      field: 'updatedDate',
      cell: value => formatDate(value)
    }
  ];

  const filters = [
    { field: 'id', label: 'ID', type: 'number' },
    { field: 'levy', label: 'Levy', type: 'number' },
    {
      field: 'levy_status',
      label: 'Levy Status',
      type: 'select',
      options: levyStatusOptions,
      operator: FilterOperatorEnum.EQUALS
    }
  ];

  const handleRowClick = bookingSpace => {
    setSelectedBookingSpace(bookingSpace.id);
  };

  return (
    <div className='space-y-2'>
      <EntityTable
        parentContext={{ type: 'company' }}
        name='All Spaces'
        filter={filters}
        columns={columns}
        menuItems={menuItems}
        model={BookingSpace}
        entityType={EntityTypeEnum.BookingSpace}
        defaultFilters={{
          'booking.company_id': { value: companyId },
          levy_status: { value: 152, operator: FilterOperatorEnum.NOT_EQUALS }
        }}
        onRowClick={handleRowClick}
      />

      <EditSpace
        isOpen={selectedBookingSpace}
        onClose={() => setSelectedBookingSpace(null)}
        bookingSpaceId={selectedBookingSpace}
      />
    </div>
  );
};

export default LevyTracker;
