import React, { useState, useEffect } from 'react';
import { Pencil, SquareArrowOutUpRight, Trash } from 'lucide-react';
import CourseOption from '../../../../models/CourseOption';
import EntityTable from '../../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import { useNavigate } from 'react-router-dom';
import CoreEntityType from '../../../../models/CoreEntityType';
import EditOption from '../../../../components/popups/course-options/EditOption';

const CourseOptions = ({ trainerId }) => {
  const navigate = useNavigate();
  const [statusOptions, setStatusOptions] = useState([]);
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const { data: statuses } = await CoreEntityType.getAll({
          entity_id: {
            operator: 'eq',
            value: EntityTypeEnum.CourseOptionStatus
          }
        });
        const { data: deliveryTypes } = await CoreEntityType.getAll({
          entity_id: {
            operator: 'eq',
            value: EntityTypeEnum.CourseDelivery
          }
        });
        setStatusOptions(
          statuses.map(status => ({ key: status.id, value: status.type }))
        );
        setDeliveryOptions(
          deliveryTypes.map(type => ({ key: type.id, value: type.type }))
        );
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, []);

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedOption(null);
  };

  const handleOptionUpdated = () => {
    // Refresh table data if needed
  };

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/course-options/${data.id}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => {
        setSelectedOption(data);
        setIsEditModalOpen(true);
      }
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  const handleNewClick = () => {
    navigate(`/course-options/create?trainer=${trainerId}`);
  };

  return (
    <>
      <EntityTable
        parentContext={{ type: 'trainers' }}
        name='Course Options'
        filter={[
          { field: 'id', label: 'ID', type: 'number' },
          {
            field: 'course',
            label: 'Course',
            type: 'text'
          },
          {
            field: 'delivery',
            label: 'Delivery Type',
            type: 'select',
            options: deliveryOptions,
            operator: FilterOperatorEnum.EQUALS
          },
          {
            field: 'location',
            label: 'Location',
            type: 'text'
          },
          {
            field: 'status',
            label: 'Status',
            type: 'select',
            options: statusOptions,
            operator: FilterOperatorEnum.EQUALS
          }
        ]}
        columns={[
          { label: 'ID', field: 'id' },
          { label: 'Course', field: 'course.name' },
          { label: 'Delivery Type', field: 'delivery.type' },
          { label: 'Location', field: 'location.name' },
          {
            label: 'Price',
            field: 'price',
            cell: value => `£${value?.toFixed(2)}`
          },
          {
            label: 'Cost',
            field: 'cost',
            cell: value => `£${value?.toFixed(2)}`
          },
          {
            label: 'Duration',
            field: 'duration'
          },
          {
            label: 'Valid For',
            field: 'validFor'
          },
          { label: 'Status', field: 'status.type' }
        ]}
        menuItems={menuItems}
        model={CourseOption}
        entityType={EntityTypeEnum.CourseOption}
        defaultFilters={{
          trainer_id: { value: trainerId }
        }}
        onNewClick={handleNewClick}
        canCreate={true}
      />
      <EditOption
        isOpen={isEditModalOpen}
        onClose={handleCloseEditModal}
        option={selectedOption}
        onOptionUpdated={handleOptionUpdated}
      />
    </>
  );
};

export default CourseOptions;
