import React, { useState, useEffect } from 'react';
import { GripVertical, Plus } from 'lucide-react';
import { Badge, Checkbox, TextButton, UserProfilePicture } from 'core';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import { formatDate, formatDateOptions } from '../../../utilities/Formatting';
import CreateTask from '../../popups/tasks/CreateTask';
import { MessageSquare } from 'lucide-react';

const TaskItem = ({ task, index, onClick }) => {
  const [showCreatePopup, setShowCreatePopup] = useState(false);

  return (
    <>
      <Draggable draggableId={String(task.id)} index={index}>
        {(provided, snapshot) => (
          <li
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={`flex items-center justify-between py-4 gap-x-6 ${!task.parent} group`}
          >
            <div className='flex items-center gap-x-3'>
              <div className='min-w-0'>
                <div className='flex items-center gap-x-3'>
                  <div
                    {...provided.dragHandleProps}
                    className='opacity-100 lg:opacity-0 group-hover:opacity-100 transition-opacity duration-200'
                  >
                    <GripVertical size={16} className='mr-2 cursor-grab' />
                  </div>
                  <Checkbox
                    checked={task.status.id === 165}
                    onChange={async () => {
                      task.status = { id: task.status.id !== 165 ? 165 : 161 };
                      await task.update();
                    }}
                    colour='primary'
                  />
                  <div className='flex items-center gap-x-2'>
                    <span
                      className={`text-sm font-semibold leading-6 cursor-pointer ${
                        task.status.id === 165
                          ? 'line-through text-base-600'
                          : 'text-base-900'
                      }`}
                      onClick={() => onClick(task)}
                    >
                      {task.title}
                    </span>
                    <Badge colour={task.status.colour} size='xs'>
                      {task.status.name}
                    </Badge>
                    {task.dueDate &&
                      new Date(task.dueDate) < new Date() &&
                      task.status.id !== 165 && (
                        <Badge colour='danger' size='xs'>
                          Overdue
                        </Badge>
                      )}
                    {task.comments > 0 && (
                      <div className='flex items-center gap-x-1'>
                        <MessageSquare size={14} className='text-base-500' />
                        <span className='text-xs text-base-500'>
                          {task.comments}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={`${
                    task.status.id === 165 ? 'hidden' : ''
                  } mt-1 ml-8 flex flex-col sm:flex-row sm:items-center gap-y-1 sm:gap-x-2 text-xs leading-5 text-base-500`}
                >
                  <span className='whitespace-nowrap'>
                    Due on{' '}
                    <time dateTime={task.dueDate}>
                      {formatDateOptions(task.dueDate, { multiline: false })}
                    </time>
                  </span>
                  <svg
                    viewBox='0 0 2 2'
                    className='hidden sm:block h-0.5 w-0.5 fill-current'
                  >
                    <circle r={1} cx={1} cy={1} />
                  </svg>
                  <span className='truncate flex items-center'>
                    Created by{' '}
                    <span className='ml-1 flex items-center'>
                      <UserProfilePicture
                        firstName={task.owner.firstName}
                        lastName={task.owner.lastName}
                        profilePicture={task.owner.profilePhoto}
                        size='xs'
                      />
                      {task.owner.firstName} {task.owner.lastName}
                    </span>
                  </span>
                </div>
                <div className='mt-2 ml-8'>
                  {task.children && task.children.length > 0 && (
                    <Droppable
                      droppableId={`task-${task.id}-child`}
                      type='child'
                    >
                      {provided => (
                        <ul
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className='space-y-2'
                        >
                          {task.children.map((childTask, index) => (
                            <Draggable
                              key={childTask.id}
                              draggableId={String(childTask.id)}
                              index={index}
                            >
                              {provided => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  className='flex items-center gap-x-2 group'
                                >
                                  <div
                                    {...provided.dragHandleProps}
                                    className='opacity-100 lg:opacity-0 group-hover:opacity-100 transition-opacity duration-200'
                                  >
                                    <GripVertical
                                      size={16}
                                      className='mr-1 cursor-grab'
                                    />
                                  </div>
                                  <Checkbox
                                    checked={childTask.status.id === 165}
                                    onChange={async () => {
                                      childTask.status = {
                                        id:
                                          childTask.status.id !== 165
                                            ? 165
                                            : 161
                                      };
                                      await childTask.update();
                                    }}
                                    className='h-4 w-4'
                                  />
                                  <span
                                    className={`text-xs text-base-700 truncate cursor-pointer ${
                                      childTask.status.id === 165
                                        ? 'line-through text-base-600'
                                        : 'text-base-900'
                                    }`}
                                    onClick={() => onClick(childTask)}
                                  >
                                    {childTask.title}
                                  </span>
                                  <time
                                    className='text-xs'
                                    dateTime={childTask.dueDate}
                                  >
                                    {formatDateOptions(childTask.dueDate, {
                                      dateOnly: true
                                    })}
                                  </time>
                                  <Badge
                                    colour={childTask.status.colour}
                                    size='xs'
                                  >
                                    {childTask.status.name}
                                  </Badge>
                                  {childTask.comments > 0 && (
                                    <div className='flex items-center gap-x-1'>
                                      <MessageSquare
                                        size={14}
                                        className='text-base-500'
                                      />
                                      <span className='text-xs text-base-500'>
                                        {childTask.comments}
                                      </span>
                                    </div>
                                  )}
                                </li>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  )}
                  <TextButton
                    size='xs'
                    className='ml-6'
                    leftIcon={<Plus size={16} />}
                    onClick={() => setShowCreatePopup(true)}
                  >
                    Add Child
                  </TextButton>
                </div>
              </div>
            </div>
          </li>
        )}
      </Draggable>
      {showCreatePopup && (
        <CreateTask
          onClose={() => setShowCreatePopup(false)}
          entityType={task.entityType}
          entityId={task.entityId}
          parentTask={task}
        />
      )}
    </>
  );
};

export default TaskItem;
