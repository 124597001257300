import React, { useState } from 'react';
import { Input, ComboBox, DropdownInput, FlatButton, Alert } from 'core';
import { PoundSterling, Percent, Eye, EyeOff } from 'lucide-react';

const Details = ({
  editedQuoteOption,
  setEditedQuoteOption,
  loading,
  deliveryTypes,
  optionStatuses,
  selectedTrainer,
  setSelectedTrainer,
  selectedLocation,
  setSelectedLocation,
  selectedCourse,
  setSelectedCourse,
  discountValue,
  total,
  profit,
  profitPct,
  loadTrainerOptions,
  loadLocationOptions,
  loadCourseOptions,
  calculateValues,
  disabled
}) => {
  const [locationObfuscated, setLocationObfuscated] = useState(
    editedQuoteOption.obfuscatedLocation != null &&
      editedQuoteOption.obfuscatedLocation !== ''
  );

  const handleInputChange = (field, value) => {
    const newFormData = {
      ...editedQuoteOption,
      [field]: value
    };
    setEditedQuoteOption(newFormData);
    calculateValues(newFormData);
  };

  const toggleLocationObfuscation = () => {
    if (locationObfuscated) {
      // Clear the obfuscated location text when disabling
      handleInputChange('obfuscatedLocation', '');
    }
    setLocationObfuscated(!locationObfuscated);
  };

  const courseDurationOptions = [
    { label: 'Half Day', value: 'Half Day' },
    ...Array.from({ length: 12 }, (_, i) => ({
      label: `${i + 1} Day${i > 0 ? 's' : ''}`,
      value: `${i + 1} Day${i > 0 ? 's' : ''}`
    })),
    { label: 'N/A', value: 'N/A' }
  ];

  console.log(editedQuoteOption);
  console.log(optionStatuses);

  return (
    <div className='w-full space-y-6'>
      <div className='space-y-4'>
        {editedQuoteOption.status == 256 && (
          <Alert
            style='warning'
            title='Changes not allowed'
            description='This quote option can not be changed as it has been converted into a booking. You can still add comments if required.'
          />
        )}
        <DropdownInput
          label='Status'
          value={editedQuoteOption.status}
          className='w-full'
          onChange={e => handleInputChange('status', e.target.value)}
          options={optionStatuses}
          required
          disabled={disabled}
        />

        <ComboBox
          label='Trainer'
          value={selectedTrainer}
          className='w-full'
          onChange={value => {
            setSelectedTrainer(value);
            handleInputChange('trainerId', value?.value);
          }}
          loadOptions={loadTrainerOptions}
          required
          disabled={disabled}
        />

        <ComboBox
          label='Course'
          value={selectedCourse}
          className='w-full'
          onChange={value => {
            setSelectedCourse(value);
            handleInputChange('courseId', value?.value);
          }}
          loadOptions={loadCourseOptions}
          required
          disabled={disabled}
        />

        <DropdownInput
          label='Course Duration'
          value={editedQuoteOption.courseDuration}
          className='w-full'
          onChange={e => handleInputChange('courseDuration', e.target.value)}
          options={courseDurationOptions}
          required
          disabled={disabled}
        />

        <div className='flex space-x-2'>
          <div
            className={`w-full ${
              editedQuoteOption.courseDelivery == 63 ? 'max-w-40' : ''
            }`}
          >
            <DropdownInput
              label='Course Delivery'
              value={editedQuoteOption.courseDelivery}
              className={`w-full ${
                editedQuoteOption.courseDelivery == 63 ? 'max-w-40' : ''
              }`}
              onChange={e =>
                handleInputChange('courseDelivery', e.target.value)
              }
              options={deliveryTypes}
              required
              disabled={disabled}
            />
          </div>
          {editedQuoteOption.courseDelivery == 63 && (
            <div
              className={`flex flex-row w-full ${
                locationObfuscated ? 'items-center' : 'items-end'
              } gap-2`}
            >
              <div className='flex-1'>
                {locationObfuscated ? (
                  <Input
                    label='Location'
                    value={editedQuoteOption.obfuscatedLocation}
                    onChange={e =>
                      handleInputChange('obfuscatedLocation', e.target.value)
                    }
                    required
                    hint={`${
                      selectedLocation
                        ? `Linked Location - [#${selectedLocation.value}] ${selectedLocation.label}`
                        : ''
                    }`}
                    disabled={disabled}
                  />
                ) : (
                  <ComboBox
                    label='Location'
                    value={selectedLocation}
                    className='w-full'
                    onChange={value => {
                      if (value) {
                        setSelectedLocation(value);
                        handleInputChange('locationId', value.value);
                      } else {
                        setSelectedLocation(null);
                      }
                    }}
                    loadOptions={loadLocationOptions}
                    required
                    disabled={disabled}
                  />
                )}
              </div>
              <FlatButton
                onClick={toggleLocationObfuscation}
                disabled={disabled}
              >
                {locationObfuscated ? <EyeOff /> : <Eye />}
              </FlatButton>
            </div>
          )}
        </div>
      </div>

      <div className='space-y-4'>
        <h3 className='text-lg font-medium border-b py-2'>Pricing</h3>
        <div className='flex space-x-2 p-2'>
          <Input
            label='Quoted'
            type='number'
            step='.01'
            required
            inlineLeftAddon={<PoundSterling size={16} />}
            value={editedQuoteOption.quoteValue}
            onChange={e => handleInputChange('quoteValue', e.target.value)}
            disabled={disabled}
          />
          <Input
            label='Trainer Cost'
            type='number'
            step='.01'
            required
            inlineLeftAddon={<PoundSterling size={16} />}
            value={editedQuoteOption.cost}
            onChange={e => handleInputChange('cost', e.target.value)}
            disabled={disabled}
          />
          <Input
            label='Trainer Discount'
            type='number'
            step='1'
            required
            inlineRightAddon={<Percent size={16} />}
            value={editedQuoteOption.discountPercentage ?? 0}
            onChange={e =>
              handleInputChange('discountPercentage', e.target.value)
            }
            disabled={disabled}
          />
        </div>
        <div className='flex space-x-2 p-2 rounded-md'>
          <Input
            label='Discount Value'
            type='number'
            step='.01'
            value={discountValue.toFixed(2)}
            disabled
            inlineLeftAddon={<PoundSterling size={16} />}
          />
          <Input
            label='Trainer Total'
            type='number'
            step='.01'
            value={total.toFixed(2)}
            disabled
            inlineLeftAddon={<PoundSterling size={16} />}
          />
          <Input
            label='Profit £'
            type='number'
            step='.01'
            value={profit.toFixed(2)}
            disabled
            inlineLeftAddon={<PoundSterling size={16} />}
          />
          <Input
            label='Profit %'
            type='number'
            step='.01'
            value={profitPct.toFixed(2)}
            disabled
            inlineLeftAddon={<Percent size={16} />}
          />
        </div>
      </div>
    </div>
  );
};

export default Details;
