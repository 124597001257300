import React, { useState, useEffect } from 'react';
import Task from '../../../models/Task';
import EntityTable from '../../../components/tables/EntityTable';
import { formatDate } from '../../../utilities/Formatting';
import { Pencil, Trash } from 'lucide-react';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import { UserProfilePicture } from 'core';
import TaskPopup from '../../../components/popups/tasks/TaskPopup';
import { useParams, useNavigate } from 'react-router-dom';

const Tasks = () => {
  //Set the page title
  document.title = 'Tasks | CRM | BOHSA LTD';
  const { '*': wildcard } = useParams();
  const navigate = useNavigate();
  const [task, setTask] = useState(null);

  const taskId = wildcard?.split('/')[0];

  useEffect(() => {
    const fetchTask = async () => {
      if (taskId) {
        const fetchedTask = await Task.getById(taskId);
        setTask(fetchedTask);
      } else {
        setTask(null);
      }
    };
    fetchTask();
  }, [taskId]);

  const menuItems = [
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => {
        navigate(`/tasks/${data.id}/details`);
      }
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  const handleRowClick = data => {
    navigate(`/tasks/${data.id}`);
  };

  const handlePopupClose = () => {
    navigate('/tasks');
  };

  return (
    <div className='p-4'>
      <EntityTable
        name='Tasks'
        filter={[
          { field: 'id', label: 'ID', type: 'text' },
          { field: 'title', label: 'Title', type: 'text' },
          {
            field: 'category.id',
            label: 'Category',
            type: 'select',
            options: [{ key: 155, value: 'Default' }]
          },
          { field: 'priority', label: 'Priority', type: 'text' },
          { field: 'status.name', label: 'Status', type: 'text' },
          { field: 'entityType', label: 'Entity Type', type: 'text' },
          { field: 'entityId', label: 'Entity ID', type: 'text' },
          { field: 'dueDate', label: 'Due Date', type: 'date' },
          { field: 'createdDate', label: 'Created Date', type: 'date' },
          { field: 'updatedDate', label: 'Updated Date', type: 'date' }
        ]}
        sort={[
          { value: 'title', label: 'Title' },
          { value: 'dueDate', label: 'Due Date' },
          { value: 'createdDate', label: 'Date Created' },
          { value: 'updatedDate', label: 'Last Modified' }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id'
          },
          { label: 'Title', field: 'title' },
          {
            label: 'Category',
            field: 'category',
            cell: value => value?.name || ''
          },
          { label: 'Priority', field: 'priority' },
          {
            label: 'Status',
            field: 'status',
            cell: value => value?.name || ''
          },
          {
            label: 'Due Date',
            field: 'dueDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Owner',
            field: 'owner',
            cell: value => (
              <span className='flex items-center'>
                <UserProfilePicture
                  firstName={value.firstName}
                  lastName={value.lastName}
                  profilePicture={value.profilePhoto}
                  size='sm'
                />
                <span className='ml-2'>
                  {value.firstName} {value.lastName}
                </span>
              </span>
            )
          }
        ]}
        menuItems={menuItems}
        model={Task}
        entityType={EntityTypeEnum.Task}
        onRowClick={handleRowClick}
        canCreate={true}
      />

      {taskId && task && <TaskPopup task={task} onClose={handlePopupClose} />}
    </div>
  );
};

export default Tasks;
