import React, { useState, useEffect } from 'react';
import {
  FilledButton,
  TextButton,
  TextEditor,
  Popup,
  Input,
  SoftButton,
  ColourPicker,
  IconPicker
} from 'core';
import { FileText, Phone, MessageCircle, Mail } from 'lucide-react';
import ActivityTemplate from '../../../models/ActivityTemplate';

const CreateTemplate = ({ onClose, activityType = 119, edit }) => {
  const [title, setTitle] = useState('');
  const [selectedActivityType, setActivityType] = useState(activityType);
  const [content, setContent] = useState('');
  const [selectedIcon, setSelectedIcon] = useState('');
  const [selectedColor, setSelectedColor] = useState('');

  useEffect(() => {
    if (edit) {
      setTitle(edit.title);
      setActivityType(edit.type);
      setContent(edit.content);
      setSelectedIcon(edit.icon);
      setSelectedColor(edit.colour);
    } else {
      setTitle('');
      setActivityType(activityType);
      setContent('');
      setSelectedIcon('');
      setSelectedColor('');
    }
  }, [edit]);

  const handleSubmit = async () => {
    const templateData = {
      title,
      type: selectedActivityType,
      content,
      icon: selectedIcon,
      colour: selectedColor
    };
    try {
      if (edit) {
        try {
          const template = await ActivityTemplate.getById(edit.id);
          await template.update(templateData);
        } catch (error) {
          console.error('Error updating template:', error);
        }
      } else {
        await new ActivityTemplate(templateData).insert();
      }
      onClose();
    } catch (error) {
      console.error('Error creating or updating template:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await new ActivityTemplate({ id: edit.id }).delete();
      onClose();
    } catch (error) {
      console.error('Error deleting template:', error);
    }
  };

  const activityTypes = [
    { type: 119, label: 'Note' },
    { type: 120, label: 'Email' },
    { type: 121, label: 'Text' },
    { type: 122, label: 'Call' }
  ];

  return (
    <Popup
      isOpen={true}
      onClose={onClose}
      title={edit ? 'Edit Template' : 'Create Template'}
      footer={
        <>
          <div className='flex justify-end'>
            {edit && (
              <TextButton
                onClick={handleDelete}
                colour='danger'
                size='md'
                className='ml-1'
              >
                Delete
              </TextButton>
            )}
            <div className='flex justify-end'>
              <FilledButton onClick={onClose} colour='base' className='mr-1'>
                Cancel
              </FilledButton>
              <FilledButton onClick={handleSubmit} colour='primary' size='md'>
                {edit ? 'Edit Template' : 'Create Template'}
              </FilledButton>
            </div>
          </div>
        </>
      }
    >
      <div className='mb-4'>
        <Input
          label='Title'
          id='title'
          type='text'
          value={title}
          onChange={e => setTitle(e.target.value)}
          placeholder='Enter template title'
          required
        />
      </div>
      <div className='mb-4'>
        <label className='block text-sm font-medium text-neutral-700 mb-1'>
          Type
        </label>
        <div className='flex space-x-2 mb-4'>
          {activityTypes.map(({ type, label }) => (
            <SoftButton
              key={label}
              onClick={() => setActivityType(type)}
              colour={selectedActivityType == type ? 'brand' : 'base'}
            >
              {type === 119 ? (
                <>
                  <FileText size={18} className='mr-2' />
                  <span>Note</span>
                </>
              ) : type === 120 ? (
                <>
                  <Mail size={18} className='mr-2' />
                  <span>Email</span>
                </>
              ) : type === 121 ? (
                <>
                  <MessageCircle size={18} className='mr-2' />
                  <span>Text</span>
                </>
              ) : type === 122 ? (
                <>
                  <Phone size={18} className='mr-2' />
                  <span>Call</span>
                </>
              ) : null}
            </SoftButton>
          ))}
        </div>
      </div>
      <div className='flex items-center gap-x-8 mb-4'>
        <div>
          <label className='block text-sm font-medium text-neutral-700 mb-1'>
            Icon
          </label>
          <IconPicker selectedIcon={selectedIcon} onChange={setSelectedIcon} />
        </div>
        <div>
          <label className='block text-sm font-medium text-neutral-700 mb-1'>
            Color
          </label>
          <ColourPicker
            colour={selectedColor}
            onChange={setSelectedColor}
            showShades={true}
          />
        </div>
      </div>
      <label className='block text-sm font-medium text-neutral-700 mb-1'>
        Content
      </label>
      <TextEditor
        label='Content'
        onChange={setContent}
        className='min-h-52'
        value={content}
      />
    </Popup>
  );
};

export default CreateTemplate;
