import React, { useState, useRef, useEffect } from 'react';

const ToggleGroup = ({ options, onChange, defaultOption }) => {
  const [selectedIndex, setSelectedIndex] = useState(() => {
    return options.findIndex(option => option.value === defaultOption) || 0;
  });
  const [buttonWidth, setButtonWidth] = useState(0);
  const buttonRefs = useRef([]);

  useEffect(() => {
    if (buttonRefs.current[0]) {
      setButtonWidth(buttonRefs.current[0].offsetWidth);
    }
  }, []);

  // useEffect(() => {
  //   const index = options.findIndex(option => option.value === defaultOption);
  //   console.log('useEffect index', index);
  //   if (index !== -1) {
  //     setSelectedIndex(index);
  //   }
  // }, [defaultOption, options]);

  const handleToggle = index => {
    setSelectedIndex(index);
    onChange?.(options[index].value);
  };

  return (
    <div
      className='inline-flex bg-base-200/70 rounded-md p-0.5 relative'
      role='group'
      dir='ltr'
    >
      <div
        className='absolute bg-white dark:bg-primary-700 rounded transition-transform duration-200 ease-in-out'
        style={{
          width: `${buttonWidth}px`,
          height: '28px',
          transform: `translateX(${selectedIndex * buttonWidth}px)`
        }}
      />
      {options.map((option, index) => (
        <button
          key={option.value}
          ref={el => (buttonRefs.current[index] = el)}
          type='button'
          role='radio'
          aria-checked={selectedIndex === index}
          className={`
            relative inline-flex items-center justify-center w-8 h-7 rounded text-sm font-medium z-10
            ${
              selectedIndex === index
                ? 'text-primary-700 dark:text-primary-400'
                : 'text-primary-500 dark:text-primary-200'
            }
            focus:outline-none
          `}
          onClick={() => handleToggle(index)}
        >
          <span className='flex items-center justify-center'>
            {option.icon && (
              <option.icon size={15} strokeWidth={2} aria-hidden='true' />
            )}
          </span>
          <span className='sr-only'>{option.label}</span>
        </button>
      ))}
    </div>
  );
};

export { ToggleGroup };
