import React, { useState, useContext } from 'react';
import { Calendar } from 'core';
import CreateMeeting from '../../popups/meetings/CreateMeeting';
import EditMeeting from '../../popups/meetings/EditMeeting';
import { UserProfileContext } from '../../../App';

const MeetingOverview = ({ meetings, entityType, entityId }) => {
  const [isCreateMeetingOpen, setIsCreateMeetingOpen] = useState(false);
  const [isEditMeetingOpen, setIsEditMeetingOpen] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const { userProfile: currentUser, setUserProfile } =
    useContext(UserProfileContext);

  const handleEditEvent = meeting => {
    setSelectedMeeting(meeting);
    setIsEditMeetingOpen(true);
  };

  const handleAddEvent = date => {
    setIsCreateMeetingOpen(true);
  };

  const handleViewChange = async view => {
    try {
      currentUser.crmPreferences = {
        ...currentUser.crmPreferences,
        calendarView: view
      };
      const newUser = await currentUser.update();
      setUserProfile(newUser);
    } catch (err) {
      console.error('Error updating calendar view preference:', err);
    }
  };

  const defaultView = currentUser.crmPreferences.calendarView || 'calendar';

  return (
    <div>
      <Calendar
        events={meetings.map(meeting => ({
          id: meeting.id,
          title: meeting.title,
          start: meeting.startDate,
          end: meeting.endDate,
          data: meeting
        }))}
        currentMonth={new Date()}
        onAddEvent={handleAddEvent}
        onEditEvent={handleEditEvent}
        defaultView={defaultView}
        onChange={handleViewChange}
      />
      {isCreateMeetingOpen && (
        <CreateMeeting
          isOpen={isCreateMeetingOpen}
          onClose={() => setIsCreateMeetingOpen(false)}
          entityType={entityType}
          entityId={entityId}
        />
      )}
      {isEditMeetingOpen && (
        <EditMeeting
          isOpen={isEditMeetingOpen}
          onClose={() => setIsEditMeetingOpen(false)}
          meeting={selectedMeeting.data}
        />
      )}
    </div>
  );
};

export default MeetingOverview;
