import { SoftButton, Input, DropdownInput, TextButton } from 'core';
import { Plus, X } from 'lucide-react';
import { useState, useEffect } from 'react';

const Page3 = ({
  entity,
  action,
  setEntity,
  setAction,
  handleTableChange,
  filters,
  tableColumns,
  exitFilters,
  setFilters,
  setRecheck,
  setExitFilters,
  setReady,
  showErrors
}) => {
  const [errors, setErrors] = useState({});
  const [startErrors, setStartErrors] = useState({});
  const [exitErrors, setExitErrors] = useState({});

  useEffect(() => {
    validateEntity(entity);
    if (action !== 221) {
      const isStartValid = validateStartFilters(filters);
      const isExitValid = validateExitFilters(exitFilters);
      setReady(isStartValid && isExitValid);
    }

    if (entity && action == 221) {
      setReady(true);
    }
  }, [entity, filters, exitFilters, action]);

  const validateStartFilters = filters => {
    const newErrors = {};
    filters.forEach((filter, index) => {
      if (!filter.key) {
        newErrors[index] = { ...newErrors[index], key: '* Required' };
      }
      if (!filter.condition) {
        newErrors[index] = {
          ...newErrors[index],
          condition: '* Required'
        };
      }
      if (!filter.value) {
        newErrors[index] = { ...newErrors[index], value: '* Required' };
      }
    });
    setStartErrors(newErrors);
    const isValid = Object.keys(newErrors).length === 0;
    return isValid;
  };

  const validateExitFilters = filters => {
    const newErrors = {};
    filters.forEach((filter, index) => {
      if (!filter.key) {
        newErrors[index] = { ...newErrors[index], key: '* Required' };
      }
      if (!filter.condition) {
        newErrors[index] = {
          ...newErrors[index],
          condition: '* Required'
        };
      }
      if (!filter.value) {
        newErrors[index] = { ...newErrors[index], value: '* Required' };
      }
    });
    setExitErrors(newErrors);
    const isValid = Object.keys(newErrors).length === 0;
    return isValid;
  };

  const validateEntity = entity => {
    const newErrors = { ...errors };
    if (!entity) {
      newErrors.entity = '* Required';
    } else {
      delete newErrors.entity;
    }
    setErrors(newErrors);
    return !newErrors.entity;
  };

  const handleAddFilter = (setFilterFunction, currentFilters) => {
    if (action !== 221) {
      const isStartValid = validateStartFilters(filters);
      const isExitValid = validateExitFilters(exitFilters);

      if (isStartValid && isExitValid) {
        setFilterFunction([
          ...currentFilters,
          { key: '', condition: '', value: '', options: [] }
        ]);
      }
    }
  };

  const handleFilterChange = (newFilters, setFilterFunction) => {
    setFilterFunction(newFilters);
    if (action !== 221) {
      const isStartValid = validateStartFilters(filters);
      const isExitValid = validateExitFilters(exitFilters);
      setReady(isStartValid && isExitValid);
    }
  };

  return (
    <div className='overflow-y-auto pr-4'>
      <div className='flex gap-4 mb-4'>
        <div className='flex flex-col w-full'>
          <DropdownInput
            label='Entity'
            value={entity}
            options={[
              { value: 2, label: 'Bookings' },
              { value: 4, label: 'Companies' },
              { value: 47, label: 'Agreed Rates' },
              { value: 19, label: 'Locations' },
              { value: 49, label: 'Imported Companies' },
              { value: 50, label: 'Imported Users' },
              { value: 48, label: 'Pipelines' },
              { value: 64, label: 'Pipeline Entities' },
              { value: 32, label: 'Tasks' },
              { value: 35, label: 'Meetings' },
              { value: 51, label: 'Tags' },
              { value: 60, label: 'Tagged Entities' },
              { value: 53, label: 'Invoices' },
              { value: 59, label: 'Invoice Lines' },
              { value: 12, label: 'Documents' },
              { value: 40, label: 'Quotes' }
            ]}
            onChange={e => {
              setEntity(e.target.value);
              handleTableChange(e);
              validateEntity(e.target.value);
              if (e.target.value && action == 221) {
                setReady(true);
              }
            }}
            className={showErrors && errors.entity ? 'border-red-500' : ''}
          />
          {showErrors && errors.entity && (
            <div className='text-red-500 text-sm mt-1'>{errors.entity}</div>
          )}
        </div>
        <DropdownInput
          label='Action'
          value={action}
          options={[
            { value: 221, label: 'Trigger' },
            { value: 233, label: 'Create' },
            ...(entity != 60 || entity != 64
              ? [{ value: 222, label: 'Update' }]
              : []),
            { value: 234, label: 'Delete' }
          ]}
          onChange={e => {
            setAction(e.target.value);
            if (entity && e.target.value == 221) {
              setReady(true);
            }
          }}
        />
      </div>
      {entity && action != 221 && (
        <>
          <h3 className='text-lg font-semibold mb-4'>Start Trigger</h3>
          {filters.map((filter, index) => (
            <div
              key={index}
              className='flex items-start justify-center mb-4 gap-2'
            >
              <div className='flex flex-col w-full'>
                <DropdownInput
                  label='Column'
                  options={tableColumns.map(column => ({
                    value: column.column_name,
                    label: column.column_name
                      .split('_')
                      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(' ')
                  }))}
                  value={filter.key}
                  onChange={e => {
                    const newFilters = [...filters];
                    newFilters[index].key = e.target.value;
                    newFilters[index].condition = '';
                    newFilters[index].options = [];
                    if (
                      tableColumns
                        .find(column => column.column_name == e.target.value)
                        ?.data_type.match(/timestamp/)
                    ) {
                      newFilters[index].functions = [
                        { value: 'now()', description: 'Current timestamp' },
                        { value: 'time()', description: 'Current time' },
                        { value: 'day()', description: 'Current day' },
                        { value: 'month()', description: 'Current month' },
                        { value: 'year()', description: 'Current year' }
                      ];
                    }
                    handleFilterChange(newFilters, setFilters);
                    setRecheck(true);
                  }}
                  className={
                    showErrors && startErrors[index]?.key
                      ? 'border-red-500'
                      : ''
                  }
                />
                {showErrors && startErrors[index]?.key && (
                  <div className='text-red-500 text-sm mt-1'>
                    {startErrors[index].key}
                  </div>
                )}
              </div>
              <div className='flex flex-col w-full'>
                <DropdownInput
                  label='Condition'
                  options={
                    tableColumns
                      .find(column => column.column_name === filter.key)
                      ?.data_type.match(/int|numeric/)
                      ? [
                          { value: '>', label: 'Greater than' },
                          { value: '<', label: 'Less than' },
                          { value: '=', label: 'Equal to' },
                          { value: '!=', label: "Doesn't equal" }
                        ]
                      : [
                          { value: 'contains', label: 'Contains' },
                          { value: '=', label: 'Equal to' },
                          { value: '!=', label: "Doesn't equal" },
                          { value: 'starts_with', label: 'Starts with' },
                          { value: 'ends_with', label: 'Ends with' }
                        ]
                  }
                  value={filter.condition}
                  onChange={e => {
                    const newFilters = [...filters];
                    newFilters[index].condition = e.target.value;
                    handleFilterChange(newFilters, setFilters);
                  }}
                  className={
                    showErrors && startErrors[index]?.condition
                      ? 'border-red-500'
                      : ''
                  }
                />
                {showErrors && startErrors[index]?.condition && (
                  <div className='text-red-500 text-sm mt-1'>
                    {startErrors[index].condition}
                  </div>
                )}
              </div>

              <div className='flex flex-col w-full'>
                {filter.options?.length > 0 ? (
                  <DropdownInput
                    label='Value'
                    options={filter.options}
                    value={filter.value}
                    onChange={e => {
                      const updatedFilters = filters.map((f, i) =>
                        i === index ? { ...f, value: e.target.value } : f
                      );
                      handleFilterChange(updatedFilters, setFilters);
                    }}
                    className={
                      showErrors && startErrors[index]?.value
                        ? 'border-red-500'
                        : ''
                    }
                  />
                ) : (
                  <Input
                    value={filter.value}
                    label='Value'
                    onChange={e => {
                      const updatedFilters = filters.map((f, i) =>
                        i === index ? { ...f, value: e.target.value } : f
                      );
                      handleFilterChange(updatedFilters, setFilters);
                    }}
                    className={
                      showErrors && startErrors[index]?.value
                        ? 'border-red-500'
                        : ''
                    }
                  />
                )}
                {showErrors && startErrors[index]?.value && (
                  <div className='text-red-500 text-sm mt-1'>
                    {startErrors[index].value}
                  </div>
                )}
              </div>

              {filter.functions?.length > 0 && (
                <div className='relative'>
                  <SoftButton
                    className='ml-2 mt-6'
                    colour='success'
                    onClick={() => {
                      const popup = document.getElementById(`popup-${index}`);
                      if (popup) {
                        popup.classList.toggle('hidden');
                      }
                    }}
                  >
                    Functions
                  </SoftButton>
                  <div
                    id={`popup-${index}`}
                    className='absolute bg-white border border-gray-300 rounded shadow-lg p-2 mt-1 hidden w-60 -right-[60px] max-h-40 overflow-y-auto'
                    style={{ zIndex: 10000 }} // Ensure popup is above all
                  >
                    {filter.functions.map((func, funcIndex) => (
                      <div
                        key={funcIndex}
                        className='cursor-pointer hover:bg-gray-100 p-1'
                        onClick={() => {
                          const updatedFilters = filters.map((f, i) =>
                            i === index ? { ...f, value: func.value } : f
                          );
                          handleFilterChange(updatedFilters, setFilters);
                          const popup = document.getElementById(
                            `popup-${index}`
                          );
                          if (popup) {
                            popup.classList.add('hidden');
                          }
                        }}
                      >
                        <div className='font-semibold'>{func.value}</div>
                        <div className='text-sm text-gray-600'>
                          {func.description}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <TextButton
                className='-mb-6'
                colour='danger'
                onClick={() => {
                  const newFilters = filters.filter((_, i) => i !== index);
                  handleFilterChange(newFilters, setFilters);
                }}
              >
                <X size={16} />
              </TextButton>
            </div>
          ))}
          <SoftButton
            leftIcon={<Plus size={16} />}
            onClick={() => handleAddFilter(setFilters, filters)}
            className='mt-2'
          >
            Add
          </SoftButton>
          <h3 className='text-lg font-semibold mb-4 mt-6'>Exit Trigger</h3>
          {exitFilters.map((filter, index) => (
            <div
              key={index}
              className='flex items-start justify-center mb-4 gap-2'
            >
              <div className='flex flex-col w-full'>
                <DropdownInput
                  label='Column'
                  options={tableColumns.map(column => ({
                    value: column.column_name,
                    label: column.column_name
                      .split('_')
                      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(' ')
                  }))}
                  value={filter.key}
                  onChange={e => {
                    const newFilters = [...exitFilters];
                    newFilters[index].key = e.target.value;
                    newFilters[index].condition = '';
                    newFilters[index].options = [];
                    if (
                      tableColumns
                        .find(column => column.column_name === e.target.value)
                        ?.data_type.match(/timestamp/)
                    ) {
                      newFilters[index].functions = [
                        { value: 'now()', description: 'Current timestamp' },
                        { value: 'time()', description: 'Current time' },
                        { value: 'day()', description: 'Current day' },
                        { value: 'month()', description: 'Current month' },
                        { value: 'year()', description: 'Current year' }
                      ];
                    }
                    handleFilterChange(newFilters, setExitFilters);
                    setRecheck(true);
                  }}
                  className={
                    showErrors && exitErrors[index]?.key ? 'border-red-500' : ''
                  }
                />
                {showErrors && exitErrors[index]?.key && (
                  <div className='text-red-500 text-sm mt-1'>
                    {exitErrors[index].key}
                  </div>
                )}
              </div>
              <div className='flex flex-col w-full'>
                <DropdownInput
                  label='Condition'
                  options={
                    tableColumns
                      .find(column => column.column_name === filter.key)
                      ?.data_type.match(/int|numeric/)
                      ? [
                          { value: '>', label: 'Greater than' },
                          { value: '<', label: 'Less than' },
                          { value: '=', label: 'Equal to' },
                          { value: '!=', label: "Doesn't equal" }
                        ]
                      : [
                          { value: 'contains', label: 'Contains' },
                          { value: '=', label: 'Equal to' },
                          { value: '!=', label: "Doesn't equal" },
                          { value: 'starts_with', label: 'Starts with' },
                          { value: 'ends_with', label: 'Ends with' }
                        ]
                  }
                  value={filter.condition}
                  onChange={e => {
                    const newFilters = [...exitFilters];
                    newFilters[index].condition = e.target.value;
                    handleFilterChange(newFilters, setExitFilters);
                  }}
                  className={
                    showErrors && errors[index]?.condition
                      ? 'border-red-500'
                      : ''
                  }
                />
                {showErrors && errors[index]?.condition && (
                  <div className='text-red-500 text-sm mt-1'>
                    {errors[index].condition}
                  </div>
                )}
              </div>
              <div className='flex flex-col w-full'>
                {filter.options?.length > 0 ? (
                  <DropdownInput
                    label='Value'
                    options={filter.options}
                    value={filter.value}
                    onChange={e => {
                      const updatedFilters = exitFilters.map((f, i) =>
                        i === index ? { ...f, value: e.target.value } : f
                      );
                      handleFilterChange(updatedFilters, setExitFilters);
                    }}
                    className={
                      showErrors && errors[index]?.value ? 'border-red-500' : ''
                    }
                  />
                ) : (
                  <Input
                    label='Value'
                    value={filter.value}
                    onChange={e => {
                      const updatedFilters = exitFilters.map((f, i) =>
                        i === index ? { ...f, value: e.target.value } : f
                      );
                      handleFilterChange(updatedFilters, setExitFilters);
                    }}
                    className={
                      showErrors && errors[index]?.value ? 'border-red-500' : ''
                    }
                  />
                )}
                {showErrors && errors[index]?.value && (
                  <div className='text-red-500 text-sm mt-1'>
                    {errors[index].value}
                  </div>
                )}
              </div>

              <TextButton
                className='-mb-6'
                colour='danger'
                onClick={() => {
                  const newFilters = exitFilters.filter((_, i) => i !== index);
                  handleFilterChange(newFilters, setExitFilters);
                }}
              >
                <X size={16} />
              </TextButton>
            </div>
          ))}
          <SoftButton
            leftIcon={<Plus size={16} />}
            onClick={() => handleAddFilter(setExitFilters, exitFilters)}
            className='mt-2'
          >
            Add
          </SoftButton>
        </>
      )}
    </div>
  );
};

export default Page3;
