import { useState, useEffect, useContext } from 'react';
import { Check, X, Pencil, Star } from 'lucide-react';
import {
  FilledButton,
  SoftButton,
  UserSelector,
  UserProfilePicture,
  Input,
  DropdownInput,
  ComboBox,
  DatePicker,
  MultiSelectBox,
  AutoComplete
} from 'core';
import { Link } from 'react-router-dom';

const Field = ({
  Icon,
  label,
  value,
  valueMetadata,
  valueResolver,
  editField,
  editObject,
  editObjectName,
  isEditable,
  onUpdate,
  dataType = 'text', // text, select, date, search, user-select, link, rating, multi-select, owner, checkbox, textarea
  options,
  loadOptions,
  extension,
  onlyExtension,
  inlineLabel = true,
  inlineEditor = true,
  userProfile,
  displayLink
}) => {
  const [editing, setEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);
  const [hoverValue, setHoverValue] = useState(null);
  const [resolvedValue, setResolvedValue] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (editing && dataType === 'multi-select' && resolvedValue) {
      setEditValue(resolvedValue);
    } else {
      setEditValue(value);
    }
  }, [value, editing, resolvedValue, dataType]);

  const handleUserSelect = selectedUser => {
    setEditValue(selectedUser);
  };

  useEffect(() => {
    const resolveValue = async () => {
      if (valueResolver && value) {
        const resolved = await valueResolver(value);
        setResolvedValue(resolved);
      }
    };

    resolveValue();
  }, [value, valueResolver]);

  const handleUpdate = () => {
    let valueToUpdate = editValue;

    // Handle different data types
    if (dataType === 'multi-select' && Array.isArray(editValue)) {
      // For multi-select, map array of objects to array of values
      valueToUpdate = editValue.map(item => item.value);
    } else if (
      (dataType === 'search' || dataType === 'select') &&
      typeof editValue === 'object' &&
      editValue?.value
    ) {
      // For single select/search, extract value from object
      valueToUpdate = editValue.value;
    }

    if (editObject) {
      // If editObject is provided, update the property using the field name
      const updatedObject = { ...editObject };
      updatedObject[editField] = valueToUpdate;
      onUpdate(editObjectName, updatedObject);
    } else {
      onUpdate(editField, valueToUpdate);
    }

    setEditing(false);
  };

  const renderEditInput = () => {
    switch (dataType) {
      case 'checkbox':
        return (
          <input
            type='checkbox'
            checked={editValue}
            onChange={e => setEditValue(e.target.checked)}
            className='size-5 rounded border-gray-300 text-brand-600 focus:ring-brand-600'
          />
        );
      case 'autocomplete':
        return (
          <AutoComplete
            value={editValue}
            onChange={value => setEditValue(value)}
            loadOptions={loadOptions}
            placeholder='Type to search...'
            className='flex-grow'
          />
        );
      case 'multi-select':
        return (
          <MultiSelectBox
            options={options}
            selectedOptions={resolvedValue ? resolvedValue : editValue}
            onChange={setEditValue}
            loadOptions={loadOptions}
            placeholder='Select options...'
            className='flex-grow'
          />
        );
      case 'user-select':
        return (
          <UserSelector
            users={options}
            selectedUser={editValue}
            onSelect={handleUserSelect}
          />
        );
      case 'select':
        return (
          <DropdownInput
            value={
              options.find(
                option =>
                  option.label === editValue || option.value === editValue
              )?.value || editValue
            }
            onChange={value => {
              console.log(
                `Changing ${editField} from ${editValue} to ${value.target.value}`
              );

              setEditValue(value.target.value);
            }}
            options={options}
            className='flex-grow'
            disablePlaceholder={true}
          />
        );
      case 'date':
        return (
          <DatePicker
            value={editValue}
            onChange={date => setEditValue(date)}
            placeholder='Select date'
            className='flex-grow'
          />
        );
      case 'search':
        return (
          <ComboBox
            options={options}
            loadOptions={loadOptions}
            value={editValue}
            onChange={setEditValue}
            placeholder='Search...'
            className='flex-grow'
          />
        );
      case 'rating':
        return (
          <div className='flex'>
            {[1, 2, 3, 4, 5].map(star => (
              <Star
                key={star}
                size={20}
                fill={star <= (hoverValue || editValue) ? '#FFD700' : 'none'}
                className={`cursor-pointer ${
                  star <= (hoverValue || editValue)
                    ? 'text-[#FFD700]'
                    : 'text-gray-300'
                }`}
                onClick={() => setEditValue(star)}
                onMouseEnter={() => setHoverValue(star)}
                onMouseLeave={() => setHoverValue(null)}
              />
            ))}
          </div>
        );
      case 'textarea':
        return (
          <textarea
            value={editValue}
            onChange={e => setEditValue(e.target.value)}
            className={`block w-full min-w-0 border-0 text-base-900 ring-1 ring-inset ring-gray-300 placeholder:text-base-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 px-2.5 py-1.5 text-sm sm:leading-6 rounded-md`}
            rows={4}
          />
        );
      case 'text':
      default:
        return (
          <Input
            type='text'
            value={editValue}
            onChange={e => setEditValue(e.target.value)}
            size='md'
            className='flex-grow'
          />
        );
    }
  };

  const renderLabelContent = () => (
    <>
      <Icon
        size={16}
        strokeWidth={1.8}
        className='text-base-500 mr-2 leading-6'
      />
      <span
        className={`text-sm font-medium text-base-950 ${
          inlineLabel ? `w-32` : 'flex-grow'
        }`}
      >
        {label}
      </span>
    </>
  );

  const renderFieldContent = () => (
    <div className='flex items-center w-full'>
      <span className='text-base-600 flex-grow whitespace-normal'>
        <span className='inline-block w-full text-sm'>
          {dataType === 'followers' ? (
            <div className='flex items-center'>
              <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 mr-2'>
                {value.length} followers
              </span>
              <SoftButton
                size='sm'
                onClick={() => {
                  const isFollowing = value.includes(userProfile.id);
                  const newFollowers = isFollowing
                    ? value.filter(id => id !== userProfile.id)
                    : [...value, userProfile.id];
                  onUpdate(editField, newFollowers);
                }}
                className={`transition-colors duration-200 ${
                  value.includes(userProfile.id)
                    ? 'hover:bg-danger-100 hover:text-danger-700'
                    : ''
                }`}
              >
                {value.includes(userProfile.id) ? 'Unfollow' : 'Follow'}
              </SoftButton>
            </div>
          ) : dataType === 'user-select' ? (
            <div className='flex items-center gap-x-1'>
              {value ? (
                <>
                  <div className='flex items-center'>
                    <UserProfilePicture
                      firstName={value?.firstName ?? ''}
                      lastName={value?.lastName ?? ''}
                      profilePicture={value?.profilePhoto ?? ''}
                      size='sm'
                    />
                    <span className='ml-2 text-sm'>
                      {value?.firstName} {value?.lastName}
                    </span>
                  </div>
                </>
              ) : (
                <span>N/A</span>
              )}
            </div>
          ) : dataType === 'owner' ? (
            <div className='flex items-center'>
              <UserProfilePicture
                firstName={value?.firstName ?? ''}
                lastName={value?.lastName ?? ''}
                profilePicture={value?.profilePhoto ?? ''}
                size='sm'
              />
              <Link
                to={`/user-profiles/${value?.displayId}`}
                className='text-brand-600 hover:underline'
              >
                <span className='ml-2 text-sm'>
                  {value?.firstName} {value?.lastName}
                </span>
              </Link>
            </div>
          ) : dataType === 'multi-select' && valueResolver ? (
            <div className='relative'>
              {resolvedValue && resolvedValue.length > 0 ? (
                <>
                  <span
                    className='text-sm cursor-pointer'
                    onMouseEnter={() => setShowPopup(true)}
                    onMouseLeave={() => setShowPopup(false)}
                  >
                    {resolvedValue.length > 2 ? (
                      <>
                        {resolvedValue[0].label}, {resolvedValue[1].label} +
                        {resolvedValue.length - 2} more
                      </>
                    ) : (
                      resolvedValue?.map((item, index) => (
                        <>
                          {item.label}
                          {index < resolvedValue.length - 1 && ', '}
                        </>
                      ))
                    )}
                  </span>
                  {showPopup && resolvedValue.length > 2 && (
                    <div className='absolute z-10 bg-white border border-neutral-200 rounded-md p-3 shadow-lg mt-1 left-0 w-72 break-words'>
                      <h4 className='text-sm font-medium mb-2'>{label}:</h4>
                      {resolvedValue.map((item, index) => (
                        <p key={index} className='text-xs mb-1'>
                          {item.label}
                        </p>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <span className='text-sm'>N/A</span>
              )}
            </div>
          ) : dataType === 'link' ? (
            <Link
              to={valueMetadata.url}
              className='text-brand-600 hover:underline'
            >
              {value}
            </Link>
          ) : dataType === 'currency' ? (
            `£${parseFloat(value).toFixed(2)}`
          ) : dataType === 'date' ? (
            value ? (
              new Date(value).toLocaleDateString()
            ) : (
              'Not set'
            )
          ) : dataType === 'rating' ? (
            <div className='flex'>
              {[1, 2, 3, 4, 5].map(star => (
                <Star
                  key={star}
                  size={20}
                  fill={star <= value ? '#FFD700' : 'none'}
                  className={`${
                    star <= value ? 'text-[#FFD700]' : 'text-gray-300'
                  }`}
                />
              ))}
            </div>
          ) : dataType === 'checkbox' ? (
            <input
              type='checkbox'
              checked={value}
              disabled
              className='size-5 rounded border-gray-300 text-brand-600 focus:ring-brand-600'
            />
          ) : typeof value === 'boolean' ? (
            valueResolver ? (
              valueResolver(value)
            ) : value == true ? (
              'Yes'
            ) : (
              'No'
            )
          ) : displayLink ? (
            <Link to={displayLink} className='hover:underline'>
              {value}
            </Link>
          ) : (
            value
          )}
        </span>
      </span>
    </div>
  );

  const renderButtons = () => (
    <div className='flex ml-2'>
      {extension && extension(value, editValue, setEditValue)}
      {editing && isEditable ? (
        <>
          <FilledButton
            onClick={handleUpdate}
            colour='base'
            size='sm'
            className='mr-2'
          >
            <Check size={18} />
          </FilledButton>
          <FilledButton
            onClick={() => {
              setEditValue(value);
              setEditing(false);
            }}
            colour='base'
            size='sm'
          >
            <X size={18} />
          </FilledButton>
        </>
      ) : (
        isEditable && (
          <SoftButton
            onClick={() => setEditing(true)}
            colour='base'
            size='sm'
            className='lg:opacity-0 lg:group-hover:opacity-100 transition-opacity duration-200'
          >
            <Pencil size={18} />
          </SoftButton>
        )
      )}
    </div>
  );

  if (onlyExtension && extension) {
    return (
      <div className='flex flex-col mb-5 group'>
        <div className='flex items-center'>
          {renderLabelContent()}
          {extension(value, editValue, setEditValue)}
        </div>
      </div>
    );
  }

  const renderContent = () => (
    <div className='flex flex-col mb-4 group'>
      {inlineLabel ? (
        // Inline layout - horizontal grid
        <div className='flex flex-col gap-2'>
          <div
            className='grid items-center gap-2'
            style={{ gridTemplateColumns: 'auto 1fr auto' }}
          >
            <div className='flex items-center'>{renderLabelContent()}</div>
            <div className='min-w-0'>{!editing && renderFieldContent()}</div>
            <div className='flex shrink-0'>{renderButtons()}</div>
          </div>
          {editing && !inlineEditor && (
            <div className='min-w-0'>{renderEditInput()}</div>
          )}
          {editing && inlineEditor && (
            <div className='min-w-0'>{renderEditInput()}</div>
          )}
        </div>
      ) : (
        // Stacked layout - vertical
        <div className='flex flex-col gap-2'>
          <div className='flex items-center justify-between'>
            <div className='flex items-center'>{renderLabelContent()}</div>
            <div className='flex shrink-0'>{renderButtons()}</div>
          </div>
          <div className='min-w-0'>
            {!editing ? renderFieldContent() : renderEditInput()}
          </div>
        </div>
      )}
    </div>
  );

  return renderContent();
};

export { Field };
