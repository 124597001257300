import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
/**
 * Tooltip Component
 *
 * @param {React.ReactNode} props.children - The element that triggers the tooltip
 * @param {string|React.ReactNode} props.content - The content to show in the tooltip
 * @param {string} [props.size='md'] - The size of the tooltip ('xs', 'sm', 'md', 'lg', 'xl')
 * @param {string} [props.className] - Additional CSS classes
 */
const Tooltip = ({
  children,
  content,
  size = 'md',
  position = 'right',
  className = ''
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [opacity, setOpacity] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const triggerRef = useRef(null);

  const sizeClasses = {
    xs: 'px-1.5 py-1 text-xs',
    sm: 'px-2 py-1 text-xs',
    md: 'px-3 py-2 text-sm',
    lg: 'px-3 py-2 text-base',
    xl: 'px-4 py-3 text-base'
  };

  useEffect(() => {
    let timeoutId;
    if (isVisible && triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      const positions = {
        top: {
          left: rect.left + rect.width / 2,
          top: rect.top - 8,
          transform: 'translateX(-50%) translateY(-100%)'
        },
        bottom: {
          left: rect.left + rect.width / 2,
          top: rect.bottom + 8,
          transform: 'translateX(-50%)'
        },
        left: {
          left: rect.left - 8,
          top: rect.top + rect.height / 2,
          transform: 'translateX(-100%) translateY(-50%)'
        },
        right: {
          left: rect.right + 8,
          top: rect.top + rect.height / 2,
          transform: 'translateY(-50%)'
        }
      };

      setTooltipPosition(positions[position]);
      timeoutId = setTimeout(() => setOpacity(1), 500);
    } else {
      setOpacity(0);
    }
    return () => clearTimeout(timeoutId);
  }, [isVisible, position]);

  return (
    <div className='relative inline-block'>
      <div
        ref={triggerRef}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>
      {isVisible &&
        ReactDOM.createPortal(
          <div
            className={`fixed z-[9999] font-medium text-white bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700 transition-opacity duration-300 ease-in-out ${sizeClasses[size]} ${className}`}
            style={{
              opacity,
              left: tooltipPosition.left,
              top: tooltipPosition.top,
              transform: tooltipPosition.transform
            }}
          >
            {content}
          </div>,
          document.body
        )}
    </div>
  );
};

export { Tooltip };
