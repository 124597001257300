import React from 'react';
import { useNavigate } from 'react-router-dom';
import ImportedCompany from '../../../../models/ImportedCompany';
import EntityTable from '../../../../components/tables/EntityTable';
import { formatDate } from '../../../../utilities/Formatting';
import { Link } from 'react-router-dom';
import { Pencil, Trash } from 'lucide-react';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import { UserProfilePicture } from 'core';

const ImportedCompanies = () => {
  //Set the page title
  document.title = 'Imported Companies | CRM | BOHSA LTD';
  const navigate = useNavigate();

  const menuItems = [
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => navigate(`/imports/companies/${data.id}`)
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  const handleRowClick = data => {
    navigate(`/imports/companies/${data.id}`);
  };

  const handleNewClick = () => {
    navigate('/imports/companies/upload');
  };

  return (
    <div className='p-4'>
      <EntityTable
        name='Imported Companies'
        filter={[
          {
            field: 'id',
            label: 'ID',
            type: 'text',
            operator: FilterOperatorEnum.EQUALS
          },
          {
            field: 'name',
            label: 'Company Name',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'user_profiles.first_name',
            label: 'Account Manager',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'on_account',
            label: 'On Account',
            type: 'boolean',
            operator: FilterOperatorEnum.EQUALS
          },
          {
            field: 'status',
            label: 'Status',
            type: 'text',
            operator: FilterOperatorEnum.EQUALS
          },
          {
            field: 'tradex',
            label: 'Tradex',
            type: 'boolean',
            operator: FilterOperatorEnum.EQUALS
          },
          {
            field: 'created_date_from',
            label: 'Created From',
            type: 'date',
            operator: FilterOperatorEnum.GREATER_THAN_OR_EQUAL
          },
          {
            field: 'created_date_to',
            label: 'Created To',
            type: 'date',
            operator: FilterOperatorEnum.LESS_THAN_OR_EQUAL
          }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id',
            cell: value => (
              <Link
                to={`/imports/companies/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          { label: 'Company Name', field: 'name' },
          {
            label: 'Account Manager',
            field: 'accountManager.name',
            foreignKey: { table: 'user_profiles', column: 'first_name' },
            cell: (value, data) =>
              data.accountManager ? (
                <span className='flex items-center'>
                  <UserProfilePicture
                    firstName={data.accountManager.firstName}
                    lastName={data.accountManager.lastName}
                    profilePicture={data.accountManager.profilePhoto}
                    size='sm'
                  />
                  <span className='ml-2'>
                    {data.accountManager.firstName}{' '}
                    {data.accountManager.lastName}
                  </span>
                </span>
              ) : (
                ''
              )
          },
          {
            label: 'On Account',
            field: 'onAccount',
            cell: value => (value ? 'Yes' : 'No')
          },
          {
            label: 'Status',
            field: 'status.name',
            foreignKey: { table: 'core_entity_types', column: 'type' }
          },
          {
            label: 'Tradex',
            field: 'tradex',
            cell: value => (value ? 'Yes' : 'No')
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Last Booked',
            field: 'lastBooked',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={ImportedCompany}
        entityType={EntityTypeEnum.ImportedCompany}
        onRowClick={handleRowClick}
        canCreate={true}
        onNewClick={handleNewClick}
      />
    </div>
  );
};

export default ImportedCompanies;
