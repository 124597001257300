import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Quote from '../../../models/Quote';
import EntityTable from '../../../components/tables/EntityTable';
import { Alert, UserProfilePicture } from 'core';
import { formatDate } from '../../../utilities/Formatting';
import { Link } from 'react-router-dom';
import { Pencil, Trash } from 'lucide-react';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import { useContext } from 'react';
import { UserProfileContext } from '../../../App';
import UserProfile from '../../../models/UserProfile';
import { PERMISSIONS } from '../../../utilities/Permissions';
import CoreEntityType from '../../../models/CoreEntityType';

const Quotes = () => {
  //Set the page title
  document.title = 'Quotes | CRM | BOHSA LTD';
  const navigate = useNavigate();
  const currentUser = new UserProfile(
    useContext(UserProfileContext).userProfile
  );
  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    const loadStatusOptions = async () => {
      try {
        const statuses = await CoreEntityType.getByEntityId(
          EntityTypeEnum.QuoteStatus
        );
        setStatusOptions(
          statuses
            .sort((a, b) => a.metadata?.order - b.metadata?.order)
            .map(status => ({
              key: status.id,
              value: status.type
            }))
        );
      } catch (error) {
        console.error('Error loading quote status options:', error);
      }
    };

    loadStatusOptions();
  }, []);

  const menuItems = [
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => navigate(`/quotes/${data.id}`)
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  const handleRowClick = data => {
    navigate(`/quotes/${data.id}`);
  };

  const handleNewClick = () => {
    navigate('/quotes/create');
  };

  //Test user permissions for view access
  if (
    !currentUser.hasPermission(
      EntityTypeEnum.Quote,
      PERMISSIONS[EntityTypeEnum.Quote].VIEW
    )
  ) {
    return (
      <Alert
        title='Permission Denied'
        description='You do not have permission to view quotes.'
        style='danger'
      />
    );
  }
  return (
    <div className='p-4'>
      <EntityTable
        name='Quotes'
        filter={[
          { field: 'id', label: 'ID', type: 'text' },
          {
            field: 'full_proposal',
            label: 'Quote Type',
            type: 'select',
            options: [
              { key: 'false', value: 'Price Check' },
              { key: 'true', value: 'Full Proposal' }
            ]
          },
          {
            field: 'status',
            label: 'Status',
            type: 'multiselect',
            options: statusOptions,
            isType: true,
            operator: FilterOperatorEnum.IN
          },
          {
            field: 'companies.name',
            label: 'Company',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'user_profiles.full_name',
            label: 'Owner',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          { field: 'course_name', label: 'Course', type: 'text' },
          {
            field: 'created_date',
            label: 'Created Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'requested_date',
            label: 'Requested Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          },
          {
            field: 'updated_date',
            label: 'Updated Date',
            type: 'daterange',
            operator: FilterOperatorEnum.BETWEEN
          }
        ]}
        sort={[
          { value: 'id', label: 'Quote ID' },
          { value: 'fullProposal', label: 'Type' },
          {
            value: 'status',
            label: 'Status'
          },
          { value: 'companies.name', label: 'Company Name' },
          { value: 'course_name', label: 'Course Name' },
          { value: 'created_date', label: 'Date Created' },
          { value: 'requested_date', label: 'Requested Date' },
          { value: 'updated_date', label: 'Updated Date' }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id',
            cell: value => (
              <Link
                to={`/quotes/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          {
            label: 'Quote Type',
            field: 'fullProposal',
            cell: value => (value ? 'Full Proposal' : 'Price Check')
          },
          {
            label: 'Status',
            field: 'status.name',
            foreignKey: { table: 'status', column: 'id' }
          },
          { label: 'Company', field: 'company.name' },
          {
            label: 'Owner',
            field: 'owner',
            cell: value =>
              value ? (
                <span className='flex items-center min-w-28'>
                  <UserProfilePicture
                    firstName={value.firstName}
                    lastName={value.lastName}
                    profilePicture={value.profilePhoto}
                    size='sm'
                  />
                  <span className='ml-2'>
                    {value.firstName} {value.lastName}
                  </span>
                </span>
              ) : null
          },
          { label: 'Course', field: 'courseName' },
          { label: 'Delegates', field: 'delegates' },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Requested Date',
            field: 'requestedDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={Quote}
        entityType={EntityTypeEnum.Quote}
        onRowClick={handleRowClick}
        onNewClick={handleNewClick}
        canCreate={true}
      />
    </div>
  );
};

export default Quotes;
