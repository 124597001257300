import React, { useEffect, useState } from 'react';
import { Pencil, SquareArrowOutUpRight, Trash } from 'lucide-react';
import Location from '../../../../models/Location';
import EntityTable from '../../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import CoreEntityType from '../../../../models/CoreEntityType';
import AddLocation from '../../../../components/popups/locations/AddLocation';
import EditLocation from '../../../../components/popups/locations/EditLocation';
import { Modal } from 'core';

const Locations = ({ trainerId }) => {
  const [isAddLocationOpen, setIsAddLocationOpen] = useState(false);
  const [isEditLocationOpen, setIsEditLocationOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationTypes, setLocationTypes] = useState([]);
  const [locationStatuses, setLocationStatuses] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState(null);

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const types = await CoreEntityType.getByEntityId(
          EntityTypeEnum.LocationType
        );
        const formattedTypes = types.map(type => ({
          value: type.type,
          key: type.id
        }));
        setLocationTypes(formattedTypes);

        const statuses = await CoreEntityType.getByEntityId(
          EntityTypeEnum.LocationStatus
        );
        const formattedStatuses = statuses.map(status => ({
          value: status.type,
          key: status.id
        }));
        setLocationStatuses(formattedStatuses);
      } catch (error) {
        console.error('Error fetching location data:', error);
      }
    };

    fetchLocationData();
  }, []);

  const handleDeleteLocation = async () => {
    try {
      const updatedLocation = new Location({
        ...(await locationToDelete.toDatabase()),
        status: { id: 114 } // Archived status
      });
      await updatedLocation.update();
      setIsDeleteModalOpen(false);
      setLocationToDelete(null);
    } catch (error) {
      console.error('Error archiving location:', error);
    }
  };

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/locations/${data.id}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => {
        setSelectedLocation(data);
        setIsEditLocationOpen(true);
      }
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => {
        setLocationToDelete(data);
        setIsDeleteModalOpen(true);
      }
    }
  ];

  const handleNewClick = () => {
    setIsAddLocationOpen(true);
  };

  const handleCloseAddLocation = () => {
    setIsAddLocationOpen(false);
  };

  const handleCloseEditLocation = () => {
    setIsEditLocationOpen(false);
    setSelectedLocation(null);
  };

  const handleLocationAdded = newLocation => {
    console.log('New location added:', newLocation);
  };

  const handleLocationUpdated = updatedLocation => {
    console.log('Location updated:', updatedLocation);
  };

  const handleRowClick = location => {
    setSelectedLocation(location);
    setIsEditLocationOpen(true);
  };

  return (
    <>
      <EntityTable
        parentContext={{ type: 'trainers' }}
        name='Locations'
        filter={[
          { field: 'id', label: 'ID', type: 'number' },
          {
            field: 'name',
            label: 'Name',
            type: 'text',
            operator: FilterOperatorEnum.TEXTSEARCH
          },
          {
            field: 'full_address',
            label: 'Address',
            type: 'text',
            operator: FilterOperatorEnum.TEXTSEARCH
          },
          {
            field: 'location_type',
            label: 'Location Type',
            type: 'select',
            operator: FilterOperatorEnum.EQUALS,
            options: locationTypes
          },
          {
            field: 'status',
            label: 'Status',
            type: 'select',
            operator: FilterOperatorEnum.EQUALS,
            options: locationStatuses
          },
          {
            field: 'distance',
            label: 'Distance Within',
            type: 'distance',
            operator: FilterOperatorEnum.LESS_THAN_OR_EQUAL
          }
        ]}
        columns={[
          { label: 'ID', field: 'id' },
          { label: 'Name', field: 'name' },
          { label: 'Address', field: 'fullAddress' },
          {
            label: 'Distance',
            field: 'distance',
            cell: value => <span>{value} miles</span>
          },
          { label: 'Type', field: 'locationType.type' },
          { label: 'Status', field: 'status.type' }
        ]}
        menuItems={menuItems}
        model={Location}
        entityType={EntityTypeEnum.Location}
        fromFunction={true}
        defaultFilters={{
          entity_type: { value: EntityTypeEnum.Trainer },
          entity_id: { value: trainerId }
        }}
        onNewClick={handleNewClick}
        onRowClick={handleRowClick}
        canCreate={true}
      />
      <AddLocation
        isOpen={isAddLocationOpen}
        onClose={handleCloseAddLocation}
        entityId={trainerId}
        entityType={EntityTypeEnum.Trainer}
        onLocationAdded={handleLocationAdded}
      />
      <EditLocation
        isOpen={isEditLocationOpen}
        onClose={handleCloseEditLocation}
        location={selectedLocation}
        onLocationUpdated={handleLocationUpdated}
      />
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title='Delete Location'
        style='danger'
        actions={[
          {
            label: 'Cancel',
            onClick: () => setIsDeleteModalOpen(false),
            style: 'base'
          },
          {
            label: 'Delete',
            onClick: handleDeleteLocation,
            style: 'danger'
          }
        ]}
      >
        <p className='text-sm text-base-600'>
          Are you sure you want to delete this location? This action cannot be
          undone.
        </p>
      </Modal>
    </>
  );
};

export default Locations;
