import { useState, useEffect, useMemo, useContext } from 'react';
import { supabase } from '../../../utilities/supabase';
import StatsCard from '../../../components/entity/stats/StatsCard';
import { LogoLoader } from 'core';
import { UserProfileContext } from '../../../App';
import Booking from '../../../models/Booking';
import Table from '../../../components/tables/Table';
import { Link, useNavigate } from 'react-router-dom';
import { FilterOperatorEnum } from '../../../utilities/Enumerables';

const Home = () => {
  //Set the page title
  document.title = 'Dashboard | CRM | BOHSA LTD';
  const { userProfile } = useContext(UserProfileContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [monthStats, setMonthStats] = useState(null);
  const [incompleteBookings, setIncompleteBookings] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get all monthly stats
        const { data: allStats, error: statsError } = await supabase.rpc(
          'get_all_monthly_stats',
          { p_date: new Date(Date.now()) }
        );
        if (statsError) throw statsError;

        // Get current date info
        const now = new Date();
        const currentMonth = now.getMonth() + 1; // JavaScript months are 0-based
        const currentYear = now.getFullYear();

        // Filter stats for current month, previous month, and last year
        const currentStats = allStats.find(
          stat => stat.month === currentMonth && stat.year === currentYear
        );
        const prevMonthStats = allStats.find(stat =>
          currentMonth === 1
            ? stat.month === 12 && stat.year === currentYear - 1
            : stat.month === currentMonth - 1 && stat.year === currentYear
        );
        const lastYearStats = allStats.find(
          stat => stat.month === currentMonth && stat.year === currentYear - 1
        );

        // Get previous 12 months of data for graphs
        const last12Months = allStats
          .sort((a, b) => {
            if (a.year !== b.year) return a.year - b.year;
            return a.month - b.month;
          })
          .slice(-12);

        // Calculate comparisons and format stats
        const stats = {
          current: currentStats || {},
          previous: prevMonthStats || {},
          lastYear: lastYearStats || {},
          history: last12Months,
          comparisons: {
            monthOnMonth: {
              booking_count: calculateChange(
                currentStats?.booking_count,
                prevMonthStats?.booking_count
              ),
              booking_value: calculateChange(
                currentStats?.booking_total_value,
                prevMonthStats?.booking_total_value
              ),
              quote_count: calculateChange(
                currentStats?.quote_count,
                prevMonthStats?.quote_count
              ),
              quote_value: calculateChange(
                currentStats?.quote_total_value,
                prevMonthStats?.quote_total_value
              ),
              conversion_rate: calculateChange(
                calculateConversionRate(currentStats),
                calculateConversionRate(prevMonthStats)
              )
            },
            yearOnYear: {
              booking_count: calculateChange(
                currentStats?.booking_count,
                lastYearStats?.booking_count
              ),
              booking_value: calculateChange(
                currentStats?.booking_total_value,
                lastYearStats?.booking_total_value
              ),
              quote_count: calculateChange(
                currentStats?.quote_count,
                lastYearStats?.quote_count
              ),
              quote_value: calculateChange(
                currentStats?.quote_total_value,
                lastYearStats?.quote_total_value
              ),
              conversion_rate: calculateChange(
                calculateConversionRate(currentStats),
                calculateConversionRate(lastYearStats)
              )
            }
          }
        };

        setMonthStats(stats);

        const { data: bookings, error: bookingsError } = await Booking.getAll({
          booking_items_incomplete: {
            operator: FilterOperatorEnum.GREATER_THAN,
            value: 0
          }
        });
        if (bookingsError) throw bookingsError;
        setIncompleteBookings(bookings);
      } catch (error) {
        setError(error.message);
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const calculateConversionRate = stats => {
    if (!stats || !stats.quote_count || stats.quote_count === 0) return 0;
    return stats.converted_bookings / stats.quote_count;
  };

  const calculateChange = (current, previous) => {
    if (!current && !previous) return 0;
    if (!current) return -1; // 100% decrease
    if (!previous) return 1; // 100% increase
    return (current - previous) / previous;
  };

  const formatChange = value =>
    value === null ? '0%' : `${(value * 100).toFixed(1)}%`;

  const formatCurrency = value =>
    new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value || 0);

  const getChangeType = value =>
    value === null || value === 0
      ? 'neutral'
      : value > 0
      ? 'positive'
      : 'negative';

  const formatMonthLabel = (month, year) => {
    const date = new Date(year, month - 1);
    return date.toLocaleString('default', { month: 'short' });
  };

  const last30DaysStats = useMemo(
    () =>
      monthStats
        ? [
            {
              label: 'Quotes',
              value: (monthStats.current?.quote_count || 0).toLocaleString(),
              change: formatChange(
                monthStats.comparisons.monthOnMonth.quote_count
              ),
              changeType: getChangeType(
                monthStats.comparisons.monthOnMonth.quote_count
              ),
              previousValues: monthStats.history.map(stat => ({
                label: formatMonthLabel(stat.month, stat.year),
                value: stat.quote_count || 0
              }))
            },
            {
              label: 'Quote Value',
              value: formatCurrency(monthStats.current?.quote_total_value),
              change: formatChange(
                monthStats.comparisons.monthOnMonth.quote_value
              ),
              changeType: getChangeType(
                monthStats.comparisons.monthOnMonth.quote_value
              ),
              previousValues: monthStats.history.map(stat => ({
                label: formatMonthLabel(stat.month, stat.year),
                value: stat.quote_total_value || 0
              }))
            },
            {
              label: 'Bookings',
              value: (monthStats.current?.booking_count || 0).toLocaleString(),
              change: formatChange(
                monthStats.comparisons.monthOnMonth.booking_count
              ),
              changeType: getChangeType(
                monthStats.comparisons.monthOnMonth.booking_count
              ),
              previousValues: monthStats.history.map(stat => ({
                label: formatMonthLabel(stat.month, stat.year),
                value: stat.booking_count || 0
              }))
            },
            {
              label: 'Booking Value',
              value: formatCurrency(monthStats.current?.booking_total_value),
              change: formatChange(
                monthStats.comparisons.monthOnMonth.booking_value
              ),
              changeType: getChangeType(
                monthStats.comparisons.monthOnMonth.booking_value
              ),
              previousValues: monthStats.history.map(stat => ({
                label: formatMonthLabel(stat.month, stat.year),
                value: stat.booking_total_value || 0
              }))
            },
            {
              label: 'Conversion Rate',
              value: `${(
                calculateConversionRate(monthStats.current) * 100
              ).toFixed(2)}%`,
              change: formatChange(
                monthStats.comparisons.monthOnMonth.conversion_rate
              ),
              changeType: getChangeType(
                monthStats.comparisons.monthOnMonth.conversion_rate
              ),
              previousValues: monthStats.history.map(stat => ({
                label: formatMonthLabel(stat.month, stat.year),
                value: calculateConversionRate(stat) * 100
              }))
            }
          ]
        : [],
    [monthStats]
  );

  const actionItemsStats = [
    {
      label: 'Incomplete Bookings',
      value: incompleteBookings.length.toString(),
      onClick: () => {
        navigate('/bookings?filter=incomplete');
      }
    }
  ];

  if (loading) return <LogoLoader logo={'/logo-star.png'} />;
  if (error) return <div className='text-center text-danger-700'>{error}</div>;

  return (
    <div className='p-4'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
        <h1 className='text-3xl font-bold text-neutral-800 mb-6'>
          Welcome {userProfile?.firstName || 'User'}.
        </h1>

        <div className='p-6 mb-6'>
          <h2 className='text-xl font-semibold text-neutral-800 mb-4'>
            Statistics{' '}
            <span className='text-sm font-normal text-neutral-500'>
              (this month)
            </span>
          </h2>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
            {last30DaysStats.map((stat, index) => (
              <StatsCard key={index} {...stat} />
            ))}
          </div>
        </div>

        <div className='p-6 mb-6'>
          <h2 className='text-xl font-semibold text-neutral-800 mb-4'>
            Action Items
          </h2>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
            <p>coming soon</p>
            {/* {actionItemsStats.map((stat, index) => (
              <div
                key={index}
                onClick={stat.onClick}
                className='cursor-pointer'
              >
                <StatsCard {...stat} />
              </div>
            ))} */}
          </div>
        </div>

        <div className='p-6'>
          <h2 className='text-xl font-semibold text-neutral-800 mb-4'>
            Overdue Tasks
          </h2>
          <p>coming soon</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
