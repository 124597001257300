import React, { useState, useEffect } from 'react';
import { Pencil, SquareArrowOutUpRight, Trash } from 'lucide-react';
import Booking from '../../../../models/Booking';
import EntityTable from '../../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import { formatDate } from '../../../../utilities/Formatting';
import CoreEntityType from '../../../../models/CoreEntityType';
import { useNavigate } from 'react-router-dom';

const Bookings = ({ companyId }) => {
  const navigate = useNavigate();
  const [statusOptions, setStatusOptions] = useState([]);
  const [subStatusOptions, setSubStatusOptions] = useState([]);

  useEffect(() => {
    const fetchStatusOptions = async () => {
      try {
        const { data: statuses } = await CoreEntityType.getAll({
          entity_id: { operator: 'eq', value: EntityTypeEnum.BookingStatus }
        });
        const { data: subStatuses } = await CoreEntityType.getAll({
          entity_id: { operator: 'eq', value: EntityTypeEnum.BookingSubStatus }
        });
        setStatusOptions(
          statuses.map(status => ({ key: status.id, value: status.type }))
        );
        setSubStatusOptions(
          subStatuses.map(status => ({ key: status.id, value: status.type }))
        );
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    fetchStatusOptions();
  }, []);

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/bookings/${data.id}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => (window.location.href = `/bookings/${data.id}`)
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  const handleNewClick = () => {
    navigate(`/bookings/create?company=${companyId}`);
  };

  const handleCloseAddBooking = () => {
    setIsAddBookingOpen(false);
  };

  const handleBookingAdded = newBooking => {
    console.log('New booking added:', newBooking);
  };

  return (
    <>
      <EntityTable
        parentContext={{ type: 'company' }}
        name='Bookings'
        filter={[
          { field: 'id', label: 'ID', type: 'number' },
          {
            field: 'start_date',
            label: 'Start Date',
            type: 'date',
            operator: FilterOperatorEnum.GREATER_THAN_OR_EQUAL
          },
          {
            field: 'end_date',
            label: 'End Date',
            type: 'date',
            operator: FilterOperatorEnum.LESS_THAN_OR_EQUAL
          },
          {
            field: 'status',
            label: 'Status',
            type: 'select',
            options: statusOptions,
            operator: FilterOperatorEnum.EQUALS
          },
          {
            field: 'subStatus',
            label: 'Sub Status',
            type: 'select',
            options: subStatusOptions,
            operator: FilterOperatorEnum.EQUALS
          }
        ]}
        columns={[
          { label: 'ID', field: 'id' },
          { label: 'Course', field: 'course.name' },
          { label: 'Trainer', field: 'trainer.name' },
          { label: 'Delivery', field: 'deliveryType.name' },
          {
            label: 'Booking Date',
            field: 'bookingDate',
            cell: value => formatDate(value)
          },
          { label: 'Status', field: 'status.name' },
          { label: 'Sub Status', field: 'subStatus.name' }
        ]}
        menuItems={menuItems}
        model={Booking}
        entityType={EntityTypeEnum.Booking}
        defaultFilters={{
          company_id: { value: companyId }
        }}
        onNewClick={handleNewClick}
        canCreate={true}
      />
    </>
  );
};

export default Bookings;
