import React from 'react';

const LogoLoader = ({ logo, size = 'lg', selfContained = true }) => {
  const containerClasses = selfContained
    ? 'h-screen bg-neutral-100 flex justify-center items-center'
    : 'h-full w-full flex justify-center items-center';

  const sizeClasses = {
    xs: 'w-6 h-6',
    sm: 'w-10 h-10',
    md: 'w-16 h-16',
    lg: 'w-20 h-20',
    xl: 'w-24 h-24'
  };

  return (
    <div className={containerClasses}>
      <div className={`${sizeClasses[size]} animate-spin`}>
        <img
          src={logo}
          alt='Loading'
          className='w-full h-full'
          width={size === 'small' ? 10 : 20}
          height={size === 'small' ? 10 : 20}
        />
      </div>
    </div>
  );
};

export { LogoLoader };
