import { Settings } from 'lucide-react';
import { FilledButton, TextButton, UserProfilePicture } from 'core';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import { PERMISSIONS } from '../../../utilities/Permissions';

const PipelineHeader = ({
  selectedPipeline,
  currentUser,
  setShowEditPopup,
  setShowAddItemPopup
}) => {
  return (
    <div className='relative border-b border-gray-200 pb-5 sm:pb-0 mb-4'>
      <div className='flex items-center justify-between'>
        <h3 className='text-xl font-semibold leading-6 text-gray-900 dark:text-gray-100'>
          {selectedPipeline ? selectedPipeline.title : 'Select a Pipeline'}
          {selectedPipeline && (
            <div className='flex items-center text-sm text-gray-400 dark:text-gray-300'>
              <span className='text-xs font-base pr-2'>Created by</span>{' '}
              <span className='flex items-center'>
                <UserProfilePicture
                  firstName={selectedPipeline.owner.first_name}
                  lastName={selectedPipeline.owner.last_name}
                  profilePicture={selectedPipeline.owner.profile_photo}
                  size='xs'
                />
                <span className='ml-2'>
                  {selectedPipeline.owner.first_name}{' '}
                  {selectedPipeline.owner.last_name}
                </span>
              </span>
            </div>
          )}
        </h3>

        {selectedPipeline ? (
          <div className='flex mb-3'>
            {currentUser.hasPermission(
              EntityTypeEnum.Pipelines,
              PERMISSIONS[EntityTypeEnum.Pipelines].EDIT
            ) && (
              <TextButton
                className='mr-3'
                onClick={() => setShowEditPopup(true)}
                disabled={currentUser.id !== selectedPipeline.owner.id}
                title={
                  currentUser.id !== selectedPipeline.owner.id
                    ? 'Only the pipeline owner can edit'
                    : ''
                }
              >
                <Settings />
              </TextButton>
            )}
            <FilledButton onClick={() => setShowAddItemPopup(true)}>
              Add Item
            </FilledButton>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default PipelineHeader;
