import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Filter, Plus } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Badge, FilledButton, SoftButton, DropdownMenu } from 'core';
import Pagination from '../navigation/Pagination';
import Table from './Table';
import Filters from '../filters/FiltersMenu';
import ActiveFilters from '../filters/ActiveFilters';
import { realtime } from '../../utilities/supabase';
import { UserProfileContext } from '../../App';
import { PERMISSIONS } from '../../utilities/Permissions';
import CoreEntityType from '../../models/CoreEntityType';

const EntityTable = ({
  name,
  filter,
  columns,
  model,
  entityType,
  fromFunction = false,
  menuItems,
  onNewClick,
  defaultFilters = {},
  onRowClick,
  canCreate,
  sortDefault = { field: 'id', order: 'asc' },
  parentContext = null
}) => {
  const { userProfile: currentUser, setUserProfile } =
    useContext(UserProfileContext);
  const [loading, setLoading] = useState(true);
  const [entities, setEntities] = useState([]);
  const [filters, setFilters] = useState({});
  const [sortBy, setSortBy] = useState(sortDefault.field);
  const [sortOrder, setSortOrder] = useState(sortDefault.order);
  const [isError, setIsError] = useState(null);
  const [totalEntities, setTotalEntities] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const getCacheKey = () => {
    if (!parentContext) return entityType;
    return `${entityType}_${parentContext.type}`;
  };

  // Update initialization to use contextual cache key
  const [itemsPerPage, setItemsPerPage] = useState(() => {
    const cacheKey = getCacheKey();
    return currentUser.crmPreferences.items_per_page?.[cacheKey] || 10;
  });

  const pinnedFilters = useMemo(() => {
    const cacheKey = getCacheKey();
    return currentUser?.crmPreferences?.pinned_filters?.[cacheKey] || [];
  }, [currentUser, getCacheKey]); // Recompute when user profile or cache key changes

  const location = useLocation();
  const navigate = useNavigate();

  const fetchData = async (
    currentFilters,
    page = 1,
    currentSortBy = sortBy,
    currentSortOrder = sortOrder,
    loading = true,
    perPage = itemsPerPage
  ) => {
    try {
      if (loading) setLoading(true);

      const searchParams = new URLSearchParams(location.search);
      const showArchived = searchParams.get('showArchived') === 'is.true';

      // Check if user has permission to view deleted items
      if (
        showArchived &&
        PERMISSIONS[entityType]?.VIEW_DELETED &&
        !currentUser.hasPermission(
          entityType,
          PERMISSIONS[entityType].VIEW_DELETED
        )
      ) {
        // Force a navigate to remove the archive url param
        searchParams.delete('showArchived');
        navigate(`${location.pathname}?${searchParams.toString()}`);
      }

      // Create a copy of defaultFilters, removing status if showArchived is true
      const defaultFiltersCopy = { ...defaultFilters };
      if (showArchived && 'status' in defaultFiltersCopy) {
        delete defaultFiltersCopy.status;
      }

      const filterParams = {
        ...defaultFiltersCopy,
        ...Object.fromEntries(
          Object.entries(currentFilters).filter(
            ([key]) => key !== 'showArchived'
          )
        )
      };

      // Add lat/lng to filterParams if distance filter exists
      if (currentFilters.distance?.metadata) {
        filterParams.lat = currentFilters.distance.metadata.lat;
        filterParams.lng = currentFilters.distance.metadata.lng;
      }

      if (fromFunction) {
        const { data, count, total } = await model.getAllByFunction(
          filterParams,
          page,
          perPage,
          currentSortBy,
          currentSortOrder
        );
        setEntities(data);
        setTotalEntities(count);
        setCurrentPage(page);
        setTotalPages(total);
      } else {
        const { data, count, total } = await model.getAll(
          filterParams,
          page,
          perPage,
          currentSortBy,
          currentSortOrder
        );
        setEntities(data);
        setTotalEntities(count);
        setCurrentPage(page);
        setTotalPages(total);
      }
      setIsError(false);
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      if (loading) setLoading(false);
    }
  };

  // Handle realtime subscription
  useEffect(() => {
    const channel = realtime('*', model.table, () => {
      // Pass current filters, page, sort settings when reloading data
      fetchData(filters, currentPage, sortBy, sortOrder, false);
    });

    return () => channel.unsubscribe();
  }, [filters, currentPage, sortBy, sortOrder]); // Add dependencies to re-subscribe when these change

  // Initializes filters and sorting from URL parameters or cached preferences
  // - If URL has no search params, loads filters and sorting from crmPreferences
  // - If URL has search params, parses them to set initial filters
  // - Handles special cases for distance filters (lat/lng) and entity type filters
  // - Updates URL parameters and fetches data with the initialized filters
  // - Manages sorting preferences with fallbacks to cached values or defaults
  useEffect(() => {
    const initializeFilters = async () => {
      const searchParams = new URLSearchParams(location.search);
      let initialFilters = {};
      const cacheKey = getCacheKey();

      // If no search params exist, check for cached filters and sorting
      if ([...searchParams.entries()].length === 0) {
        const cachedFilters =
          currentUser.crmPreferences.cached_filters?.[cacheKey] || {};
        const cachedSorting =
          currentUser.crmPreferences.cached_sorting?.[cacheKey] || {};
        initialFilters = cachedFilters;
        setSortBy(cachedSorting.sortBy || sortDefault.field);
        setSortOrder(cachedSorting.sortOrder || sortDefault.order);
      } else {
        // Parse filters from URL params
        const filterPromises = filter.map(async filterItem => {
          const urlValue = searchParams.get(filterItem.field);
          if (urlValue) {
            if (filterItem.field === 'distance') {
              const [operator, value] = urlValue.split('|');
              const lat = searchParams.get('lat');
              const lng = searchParams.get('lng');
              return [
                filterItem.field,
                {
                  value: value,
                  label: filterItem.label,
                  operator: operator,
                  metadata: {
                    lat: parseFloat(lat) ?? 53.00630079443634,
                    lng: parseFloat(lng) ?? -2.1651132351130746
                  }
                }
              ];
            } else {
              const [operator, value] = urlValue.split('|');
              const processedValue =
                operator === 'in' ? value.split(',') : value || urlValue;

              let friendlyValue = processedValue;
              if (filterItem.isType || operator === 'in') {
                const { data: types } = await CoreEntityType.getAll({
                  id: { value: processedValue, operator: operator }
                });
                friendlyValue = types.map(type => type.type).filter(Boolean);
              }

              return [
                filterItem.field,
                {
                  value: processedValue,
                  label: filterItem.label,
                  operator: operator || 'eq',
                  isType: filterItem.isType,
                  friendlyValue: friendlyValue
                }
              ];
            }
          }
          return null;
        });

        // Wait for all filter promises to resolve
        const resolvedFilters = await Promise.all(filterPromises);
        initialFilters = Object.fromEntries(
          resolvedFilters.filter(filter => filter !== null)
        );
      }

      setFilters(initialFilters);

      const cachedSorting =
        currentUser.crmPreferences.cached_sorting?.[entityType] || {};
      const urlSortBy = searchParams.get('sortBy');
      const urlSortOrder = searchParams.get('sortOrder');

      let initialSortBy;
      let initialSortOrder;

      if (!urlSortBy) {
        // Use cached sorting if no sortBy in URL or if sortBy=id
        initialSortBy = cachedSorting.sortBy || sortDefault.field;
        initialSortOrder = cachedSorting.sortOrder || sortDefault.order;
      } else {
        // Use URL params and update cache
        initialSortBy = urlSortBy;
        initialSortOrder = urlSortOrder || sortDefault.order;

        // Update cached sorting preferences
        currentUser.crmPreferences.cached_sorting = {
          ...currentUser.crmPreferences.cached_sorting,
          [entityType]: {
            sortBy: initialSortBy,
            sortOrder: initialSortOrder
          }
        };
        const newUser = await currentUser.update();
        setUserProfile(newUser);
      }

      setSortBy(initialSortBy);
      setSortOrder(initialSortOrder);
      updateUrlParams(initialFilters, initialSortBy, initialSortOrder);
      await fetchData(initialFilters, 1, initialSortBy, initialSortOrder);
    };

    initializeFilters();
  }, [location.search]);

  const applyFilters = newFilters => {
    setFilters(newFilters);
    setCurrentPage(1);
    updateUrlParams(newFilters, sortBy, sortOrder);
    fetchData(newFilters, 1, sortBy, sortOrder);
  };

  const removeFilter = async key => {
    const newFilters = { ...filters };
    delete newFilters[key];

    // Update cached filters
    if (currentUser.crmPreferences.cached_filters?.[entityType]) {
      delete currentUser.crmPreferences.cached_filters[entityType][key];
      const newUser = await currentUser.update();
      setUserProfile(newUser);
    }

    setFilters(newFilters);
    updateUrlParams(newFilters, sortBy, sortOrder);
    fetchData(newFilters, 1, sortBy, sortOrder);
  };

  const removeAllFilters = async () => {
    // Clear cached filters for this entity type
    if (currentUser.crmPreferences.cached_filters) {
      currentUser.crmPreferences.cached_filters[entityType] = {};
      const newUser = await currentUser.update();
      setUserProfile(newUser);
    }

    setFilters({});
    updateUrlParams({}, sortBy, sortOrder);
    fetchData({}, 1, sortBy, sortOrder);
  };

  const handlePinnedFilterClick = async filter => {
    // Apply the filter's data
    const appliedFilters = filter.filterData;

    // Update cached filters
    currentUser.crmPreferences.cached_filters = {
      ...currentUser.crmPreferences.cached_filters,
      [entityType]: appliedFilters
    };
    const newUser = await currentUser.update();
    setUserProfile(newUser);

    // Update URL and state
    updateUrlParams(appliedFilters, sortBy, sortOrder);
    setFilters(appliedFilters);
    fetchData(appliedFilters, 1, sortBy, sortOrder);
  };

  const updateUrlParams = (newFilters, newSortBy, newSortOrder) => {
    const searchParams = new URLSearchParams();

    if (newSortBy) {
      searchParams.set('sortBy', newSortBy);
    }

    if (newSortOrder && newSortOrder !== 'asc') {
      searchParams.set('sortOrder', newSortOrder);
    }

    // Add filters to URL params
    Object.entries(newFilters).forEach(([key, filter]) => {
      if (key === 'distance' && filter.metadata) {
        searchParams.set(key, `${filter.operator}|${filter.value}`);
        searchParams.set('lat', filter.metadata.lat);
        searchParams.set('lng', filter.metadata.lng);
      } else if (filter.operator && filter.value) {
        if (Array.isArray(filter.value)) {
          searchParams.set(key, `${filter.operator}|${filter.value.join(',')}`);
        } else {
          searchParams.set(key, `${filter.operator}|${filter.value}`);
        }
      }
    });

    const queryString = searchParams.toString();
    navigate(
      queryString ? `${location.pathname}?${queryString}` : location.pathname,
      { replace: true }
    );
  };

  const handleSort = async field => {
    const cacheKey = getCacheKey();
    const column = columns.find(col => col.field === field);
    const sortField = column.foreignKey
      ? `${column.foreignKey.table}.${column.foreignKey.column}`
      : field;
    const newSortOrder =
      sortField === sortBy && sortOrder === 'asc' ? 'desc' : 'asc';

    // Update cached sorting preferences
    if (!currentUser.crmPreferences.cached_sorting) {
      currentUser.crmPreferences.cached_sorting = {};
    }
    currentUser.crmPreferences.cached_sorting[cacheKey] = {
      sortBy: sortField,
      sortOrder: newSortOrder
    };
    const newUser = await currentUser.update();
    setUserProfile(newUser);

    setSortBy(sortField);
    setSortOrder(newSortOrder);
    updateUrlParams(filters, sortField, newSortOrder);
    fetchData(filters, currentPage, sortField, newSortOrder);
  };

  const handleItemsPerPageChange = async newPerPage => {
    const cacheKey = getCacheKey();
    if (!currentUser.crmPreferences.items_per_page) {
      currentUser.crmPreferences.items_per_page = {};
    }
    currentUser.crmPreferences.items_per_page[cacheKey] = newPerPage;
    const newUser = await currentUser.update();
    setUserProfile(newUser);
    setItemsPerPage(newPerPage);
    fetchData(filters, 1, sortBy, sortOrder, true, newPerPage);
  };

  return (
    <>
      <div className='flex flex-col mb-3 w-full'>
        <div className='flex justify-between items-center'>
          <div className='flex space-x-4 items-center'>
            {canCreate && (
              <FilledButton
                colour='primary'
                leftIcon={<Plus size={18} />}
                onClick={onNewClick}
              >
                New
              </FilledButton>
            )}
            <SoftButton
              colour='primary'
              leftIcon={<Filter size={18} />}
              onClick={() => setIsFiltersOpen(!isFiltersOpen)}
            >
              Filter
            </SoftButton>
            {pinnedFilters.length > 0 && (
              <div className='border-l-2'>
                <div className='hidden lg:flex flex-row space-x-2 text-sm ml-2'>
                  {pinnedFilters.map((filter, index) => {
                    const isApplied = Object.keys(filters).some(
                      key =>
                        filters[key].value === filter.filterData[key]?.value
                    );
                    return (
                      <Badge
                        shape='pill'
                        colour={isApplied ? 'info' : 'primary'}
                        key={`${filter.field}-${index}`}
                        size='sm'
                        onClick={() => handlePinnedFilterClick(filter)}
                        className='cursor-pointer'
                      >
                        {filter.name}
                      </Badge>
                    );
                  })}
                </div>
                <div className='lg:hidden ml-2'>
                  <DropdownMenu
                    options={pinnedFilters.map((filter, index) => ({
                      label: filter.name,
                      value: index
                    }))}
                    onChange={index =>
                      handlePinnedFilterClick(pinnedFilters[index])
                    }
                    placeholder='Pinned Filters'
                    size='sm'
                  />
                </div>
              </div>
            )}
          </div>
          <div className='ml-auto'>
            <p className='text-neutral-600 text-sm px-2'>
              Total {name}: <span className='font-medium'>{totalEntities}</span>
            </p>
          </div>
        </div>
        <ActiveFilters
          filters={filters}
          removeFilter={removeFilter}
          removeAllFilters={removeAllFilters}
        />
      </div>
      <div className=''>
        <Table
          columns={columns.map(column => ({
            ...column,
            sortable: column.sortable ?? true,
            sortField: column.foreignKey
              ? `${column.foreignKey.table}.${column.foreignKey.column}`
              : column.field
          }))}
          data={entities}
          isLoading={loading}
          isError={isError}
          menuItems={menuItems}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onSort={handleSort}
          onRowClick={onRowClick}
        />
      </div>
      <div className='mt-6'>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={newPage => {
            setCurrentPage(newPage);
            fetchData(filters, newPage, sortBy, sortOrder);
          }}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      </div>
      <Filters
        isOpen={isFiltersOpen}
        onClose={() => setIsFiltersOpen(!isFiltersOpen)}
        onApply={applyFilters}
        filter={filter}
        filters={filters}
        setFilters={setFilters}
        entityType={entityType}
        parentContext={parentContext}
      />
    </>
  );
};

export default EntityTable;
