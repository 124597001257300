import { useState, useEffect, createContext } from 'react';
import { RouterProvider, Navigate } from 'react-router-dom';
import { supabase } from './utilities/supabase';
import { LogoLoader } from 'core';
import Dashboard from './pages/dashboard/Dashboard';
import Login from './pages/auth/login/Login';
import Signup from './pages/auth/signup/Signup';
import ForgotPassword from './pages/auth/forgotPassword/ForgotPassword';
import ResetPassword from './pages/auth/resetPassword/ResetPassword';
import Loading from './pages/auth/loading/Loading';
import StylePages from './pages/styles/Styles';
import Error from './pages/error/Error';
import UserProfile from './models/UserProfile';
import NotificationProvider from '../core/src/components/providers/NotificationProvider';
import MaintenancePage from './pages/error/Maintenance';
import {
  initializeSentry,
  Sentry,
  sentryCreateBrowserRouter
} from './utilities/sentry';
import MFA from './pages/auth/2fa/2FA';
import XeroCallback from './pages/dashboard/settings/tabs/integrations/XeroCallback';
import SignupConnect from './pages/auth/signup/SignupConnect';

export const UserProfileContext = createContext(null);
export const ThemeContext = createContext(null);

const App = () => {
  const [sessionState, setSession] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showLoadingScreen, setShowLoadingScreen] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [theme, setTheme] = useState('light');
  const [isBackendAvailable, setIsBackendAvailable] = useState(true);
  const [showMFA, setShowMFA] = useState(false);
  const [passwordRecovery, setPasswordRecovery] = useState(false);

  useEffect(() => {
    // Load Google Maps JavaScript API
    const script = document.createElement('script');
    script.text = `(g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=\`https://maps.\${c}apis.com/maps/api/js?\`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
      key: "${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}",
      v: "weekly"
    });`;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const checkBackendServices = async () => {
    try {
      const { error } = await supabase
        .from('core_entities')
        .select('id')
        .limit(1);
      setIsBackendAvailable(!error);
    } catch (error) {
      console.error('Backend services are unavailable:', error);
      setIsBackendAvailable(false);
    }
  };

  const handleAuthChange = (event, session) => {
    //console.log(event);
    if (
      event === 'SIGNED_IN' ||
      event === 'SIGNED_OUT' ||
      event === 'MFA_CHALLENGE_VERIFIED' ||
      event === 'PASSWORD_RECOVERY'
    ) {
      setSession(session);
      if (event === 'SIGNED_IN' || event === 'MFA_CHALLENGE_VERIFIED') {
        if (
          !sessionState ||
          session.access_token != sessionState.access_token
        ) {
          UserProfile.getByAuthId(session.user.id).then(
            async ({ data, error }) => {
              if (data) {
                const activeProfile =
                  data.find(profile => profile.active) || data[0];
                setUserProfile(activeProfile);
                Sentry.setUser({
                  email: activeProfile.email,
                  fullName: activeProfile.fullName
                });
                const {
                  data: { currentLevel, nextLevel }
                } = await supabase.auth.mfa.getAuthenticatorAssuranceLevel();

                if (currentLevel === 'aal1' && nextLevel === 'aal2') {
                  // User has MFA enrolled but needs to verify
                  setShowMFA(true);
                } else if (currentLevel === 'aal2' && nextLevel === 'aal2')
                  setShowMFA(false);

                //redirect('/');
              } else if (error) {
                console.error('Error fetching user profile:', error);
              }
            }
          );
        }
      } else if (event === 'PASSWORD_RECOVERY') {
        setPasswordRecovery(true);
      } else {
        setUserProfile(null);
        //redirect('/login');
      }
    }
  };

  // Subscribe to auth state changes and handle them via handleAuthChange callback
  // Unsubscribes when component unmounts
  useEffect(() => {
    const {
      data: { subscription }
    } = supabase.auth.onAuthStateChange(handleAuthChange);
    return () => subscription.unsubscribe();
  }, [handleAuthChange]);

  // Check and initialize session on app load
  // - Verifies backend services are available
  // - Gets current session if it exists
  // - Loads user profile data and preferences
  // - Updates Sentry user context
  // - Sets initial theme
  useEffect(() => {
    const checkSession = async () => {
      // First check if backend is available
      await checkBackendServices();
      if (!isBackendAvailable) return;

      // Get current session
      const {
        data: { session }
      } = await supabase.auth.getSession();
      setSession(session);

      // If session exists, load user profile data
      if (session) {
        const { data, error } = await UserProfile.getByAuthId(session.user.id);
        if (data) {
          // Get active profile or fallback to first profile
          const activeProfile = data.find(profile => profile.active) || data[0];
          setUserProfile(activeProfile);

          // Set up Sentry user tracking
          Sentry.setUser({
            email: activeProfile.email,
            fullName: activeProfile.fullName
          });

          // Set theme from user preferences or default to light
          setTheme(activeProfile?.crmPreferences?.theme || 'light');
        } else if (error) {
          console.error('Error fetching user profile:', error);
        }
      }

      setIsLoading(false);
    };

    checkSession();
  }, [isBackendAvailable]);

  if (isLoading) return <LogoLoader logo={'/logo-star.png'} />;
  if (showLoadingScreen) return <Loading />;
  if (!isBackendAvailable) return <MaintenancePage />;
  if (passwordRecovery) return <Navigate to='/resetPassword' />;

  if (window.location.hostname.includes('portal.bohsa.co.uk')) {
    initializeSentry({
      enableFeedback: true,
      enableReplays: false,
      enableTracing: true
    });
  } else {
    //Uncomment to enable sentry!
    // initializeSentry({
    //   enableFeedback: true
    // });
  }

  const router = sentryCreateBrowserRouter([
    {
      path: '/',
      errorElement: <Error />,
      children: [
        {
          path: 'connect-xero',
          element: sessionState ? <XeroCallback /> : <Navigate to='/login' />
        },
        {
          index: true,
          element: !sessionState ? (
            <Navigate to='/login' />
          ) : showMFA ? (
            <Navigate to='/mfa' />
          ) : (
            <Dashboard />
          )
        },
        {
          path: 'login',
          element: !sessionState ? (
            <Login />
          ) : showMFA ? (
            <Navigate to='/mfa' />
          ) : (
            <Navigate to='/' />
          )
        },
        {
          path: 'mfa',
          element: !sessionState ? (
            <Navigate to='/login' />
          ) : !showMFA ? (
            <Navigate to='/' />
          ) : (
            <MFA />
          )
        },
        {
          path: 'password',
          element: !sessionState ? <ForgotPassword /> : <Navigate to='/' />
        },
        {
          path: 'resetPassword',
          element: !sessionState ? <ResetPassword /> : <Navigate to='/' />
        },
        {
          path: 'signup',
          element: !sessionState ? <Signup /> : <Navigate to='/' />
        },
        {
          path: 'signup/:id',
          element: <SignupConnect session={sessionState} />
        },
        {
          path: 'styles',
          element: !sessionState ? (
            <Navigate to='/login' />
          ) : showMFA ? (
            <Navigate to='/mfa' />
          ) : (
            <StylePages />
          )
        },
        {
          path: '/*',
          element: !sessionState ? (
            <Navigate to='/login' />
          ) : showMFA ? (
            <Navigate to='/mfa' />
          ) : (
            <Dashboard />
          )
        }
      ]
    }
  ]);

  return (
    <UserProfileContext.Provider value={{ userProfile, setUserProfile }}>
      <NotificationProvider>
        <ThemeContext.Provider value={{ theme, setTheme }}>
          <RouterProvider router={router} />
        </ThemeContext.Provider>
      </NotificationProvider>
    </UserProfileContext.Provider>
  );
};

export default App;
