import React, { useState, lazy, Suspense } from 'react';
import { ChevronRight } from 'lucide-react';

// Lazy load script components
const ImportQuotes = lazy(() =>
  import('../../../../utilities/scripts/import-quotes')
);
const ConvertQuotes = lazy(() =>
  import('../../../../utilities/scripts/convert-quotes')
);
const ImportQuoteOptions = lazy(() =>
  import('../../../../utilities/scripts/import-quote-options')
);
const ImportSJALocations = lazy(() =>
  import('../../../../utilities/scripts/import-sja-locations')
);

const Scripts = () => {
  const [selectedScript, setSelectedScript] = useState(null);

  const scripts = [
    {
      id: 'import-quotes',
      name: 'Import Quotes',
      description: 'Import quotes from CSV file',
      component: ImportQuotes
    },
    {
      id: 'convert-quotes',
      name: 'Convert Quotes',
      description: 'Convert quotes from old format',
      component: ConvertQuotes
    },
    {
      id: 'import-quote-options',
      name: 'Import Quote Options',
      description: 'Import quote options from CSV file',
      component: ImportQuoteOptions
    },
    {
      id: 'import-sja-locations',
      name: 'Import SJA Locations',
      description: 'Import SJA locations from CSV file',
      component: ImportSJALocations
    }
  ];

  return (
    <div className='flex h-full'>
      {/* Left sidebar */}
      <div className='w-80 border-r border-gray-200 bg-white overflow-y-auto'>
        <div className='p-6'>
          <h2 className='text-lg font-medium text-gray-900'>Admin Scripts</h2>
          <p className='mt-1 text-sm text-gray-500'>Select a script to run</p>
        </div>
        <nav className='space-y-1 px-3 pb-3'>
          {scripts.map(script => (
            <button
              key={script.id}
              onClick={() => setSelectedScript(script)}
              className={`w-full flex items-center px-3 py-2 text-sm font-medium rounded-md ${
                selectedScript?.id === script.id
                  ? 'bg-gray-100 text-gray-900'
                  : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
              }`}
            >
              <span className='flex-1 text-left'>
                <div className='font-medium'>{script.name}</div>
                <div className='text-xs text-gray-500'>
                  {script.description}
                </div>
              </span>
              <ChevronRight className='h-5 w-5' />
            </button>
          ))}
        </nav>
      </div>

      {/* Right content area */}
      <div className='flex-1 overflow-y-auto'>
        {selectedScript ? (
          <Suspense
            fallback={
              <div className='h-full flex items-center justify-center'>
                <div className='animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900'></div>
              </div>
            }
          >
            <selectedScript.component />
          </Suspense>
        ) : (
          <div className='h-full flex items-center justify-center text-gray-500'>
            Select a script to get started
          </div>
        )}
      </div>
    </div>
  );
};

export default Scripts;
