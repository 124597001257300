import React, { useEffect, useRef, useState } from 'react';

const Map = ({
  locations = [],
  zoom = 6,
  width = '100%',
  height = '200px',
  onPolygonUpdate,
  focusZoneLabel,
  drawing = false
}) => {
  const mapRef = useRef(null);
  const googleMapRef = useRef(null);
  const markersRef = useRef([]);
  const circlesRef = useRef([]);
  const polygonsRef = useRef([]);
  const labelsRef = useRef([]);
  const drawingPointsRef = useRef([]);
  const [coordinates, setCoordinates] = useState({
    lat: 53.00709710294123,
    lng: -2.165005429504804
  });
  const [isDrawing, setIsDrawing] = useState(false);

  useEffect(() => {
    let clickListener = null;
    const initMap = async () => {
      if (!window.google?.maps) {
        return;
      }

      const { Map: GoogleMap } = await google.maps.importLibrary('maps');
      const { AdvancedMarkerElement } = await google.maps.importLibrary(
        'marker'
      );
      const { Geocoder } = await google.maps.importLibrary('geocoding');

      // Set initial map configuration
      const initialConfig = {
        center: coordinates,
        zoom: locations.length ? zoom : 5, // Use zoom 5 for UK view if no locations
        mapId: '8f348c3c6cf90b9b',
        gestureHandling: 'cooperative'
      };

      // Only create map if it doesn't exist yet
      if (!googleMapRef.current) {
        googleMapRef.current = new GoogleMap(mapRef.current, initialConfig);
      }

      // Clear existing markers, circles, polygons and labels
      markersRef.current.forEach(marker => marker.setMap(null));
      markersRef.current = [];
      circlesRef.current.forEach(circle => circle.setMap(null));
      circlesRef.current = [];
      polygonsRef.current.forEach(polygon => polygon.setMap(null));
      polygonsRef.current = [];
      labelsRef.current.forEach(label => label.setMap(null));
      labelsRef.current = [];

      // Create bounds object to track marker positions
      const bounds = new google.maps.LatLngBounds();
      let hasValidMarkers = false;

      // Remove any existing click listeners
      if (googleMapRef.current) {
        google.maps.event.clearListeners(googleMapRef.current, 'click');
      }

      // Add click listener for drawing mode
      clickListener = googleMapRef.current.addListener('click', e => {
        if (isDrawing) {
          const clickedPoint = { lat: e.latLng.lat(), lng: e.latLng.lng() };
          // Check if clicking near the first point to close polygon
          if (drawingPointsRef.current.length >= 3) {
            const firstPoint = drawingPointsRef.current[0];
            const distance =
              google.maps.geometry.spherical.computeDistanceBetween(
                new google.maps.LatLng(firstPoint),
                new google.maps.LatLng(clickedPoint)
              );

            if (distance < 100) {
              // Within 100 meters of first point
              // Close the polygon
              onPolygonUpdate?.({
                type: 'polygon',
                points: [...drawingPointsRef.current]
              });
              cleanupDrawing();
              setIsDrawing(false);
              return;
            }
          }

          // Add new point
          drawingPointsRef.current.push(clickedPoint);

          // Update temporary drawing polygon
          if (polygonsRef.current.length > 0) {
            const tempPolygon = polygonsRef.current.find(p => p.temporary);
            if (tempPolygon) {
              tempPolygon.setMap(null);
              polygonsRef.current = polygonsRef.current.filter(
                p => !p.temporary
              );
            }
          }

          if (drawingPointsRef.current.length > 1) {
            const tempPolygon = new google.maps.Polygon({
              paths: drawingPointsRef.current,
              map: googleMapRef.current,
              fillColor: '#FF0000',
              fillOpacity: 0.2,
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              temporary: true
            });
            polygonsRef.current.push(tempPolygon);

            // Update the parent component with current points
            onPolygonUpdate?.({
              type: 'polygon',
              points: [...drawingPointsRef.current]
            });
          }
        }
      });

      if (locations.length > 0) {
        const geocoder = new Geocoder();
        const markerPromises = [];

        // Process each location
        for (const location of locations) {
          if (location.type === 'polygon' && location.points) {
            // Handle polygons directly since they contain their own coordinates
            markerPromises.push(
              Promise.resolve({
                type: location.type,
                points: location.points,
                label: location.label,
                colour: location.colour,
                editable: location.editable
              })
            );
          } else if (location.latitude && location.longitude) {
            // If we have coordinates, create marker directly
            const position = {
              lat: location.latitude,
              lng: location.longitude
            };

            markerPromises.push(
              Promise.resolve({
                position,
                type: location.type,
                radius: location.radius,
                label: location.label,
                colour: location.colour
              })
            );
          } else if (location.address && location.address != ', , , ,') {
            // If we only have address, geocode it
            const geocodePromise = new Promise((resolve, reject) => {
              geocoder.geocode(
                { address: location.address },
                (results, status) => {
                  if (status === 'OK' && results[0]) {
                    resolve({
                      position: results[0].geometry.location.toJSON(),
                      type: location.type,
                      radius: location.radius,
                      label: location.label,
                      color: location.color
                    });
                  } else {
                    reject(status);
                  }
                }
              );
            }).catch(error => {
              console.error('Geocoding failed:', error);
              return null;
            });
            markerPromises.push(geocodePromise);
          }
        }

        // Wait for all markers to be processed
        const markerResults = await Promise.all(markerPromises);

        // Create markers only after all coordinates are resolved
        markerResults.forEach(result => {
          if (result) {
            const type = result.type || 'marker';
            const color = result.colour || '#FF0000';
            if (type === 'circle' && result.radius) {
              // Create circle if type is circle and radius exists
              const circle = new google.maps.Circle({
                center: result.position,
                radius: result.radius * 1609.34, // Convert miles to meters
                map: googleMapRef.current,
                fillColor: color,
                fillOpacity: 0.2,
                strokeColor: color,
                strokeOpacity: 0.8,
                strokeWeight: 2
              });
              circlesRef.current.push(circle);
              bounds.extend(result.position);
              // Extend bounds to include circle radius
              bounds.union(circle.getBounds());
              hasValidMarkers = true;
              // Create label for circle if provided
              if (result.label) {
                const labelContent = document.createElement('div');
                labelContent.className = 'rounded-lg text-white text-sm p-1';
                labelContent.style.backgroundColor = color;
                labelContent.textContent = result.label;
                const label = new AdvancedMarkerElement({
                  position: result.position,
                  map: googleMapRef.current,
                  content: labelContent
                });
                labelsRef.current.push(label);
              }
            } else if (type === 'polygon' && result.points.length > 0) {
              // Create polygon if type is polygon and points exist
              const polygonPath = result.points;
              const polygon = new google.maps.Polygon({
                paths: polygonPath,
                map: googleMapRef.current,
                fillColor: color,
                fillOpacity: 0.2,
                strokeColor: color,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                editable: result.editable || false,
                draggable: result.editable || false
              });
              polygonsRef.current.push(polygon);

              if (result.editable && onPolygonUpdate) {
                // Listen for path changes (vertex moves)
                google.maps.event.addListener(polygon, 'mouseup', () => {
                  const updatedPoints = polygon
                    .getPath()
                    .getArray()
                    .map(point => ({
                      lat: point.lat(),
                      lng: point.lng()
                    }));
                  onPolygonUpdate({
                    ...result,
                    points: updatedPoints
                  });
                });

                // Listen for new points being added
                google.maps.event.addListener(
                  polygon.getPath(),
                  'insert_at',
                  () => {
                    const updatedPoints = polygon
                      .getPath()
                      .getArray()
                      .map(point => ({
                        lat: point.lat(),
                        lng: point.lng()
                      }));
                    onPolygonUpdate({
                      ...result,
                      points: updatedPoints
                    });
                  }
                );

                // Add right-click listener for vertex removal
                google.maps.event.addListener(polygon, 'rightclick', e => {
                  // Only handle right clicks on vertices
                  if (e.vertex != null) {
                    const path = polygon.getPath();
                    // Remove the vertex
                    path.removeAt(e.vertex);
                    // Update the parent component
                    const updatedPoints = path.getArray().map(point => ({
                      lat: point.lat(),
                      lng: point.lng()
                    }));
                    onPolygonUpdate({
                      ...result,
                      points: updatedPoints
                    });
                  }
                });
              }

              // Calculate polygon center for label
              if (polygonPath.length > 0) {
                const polygonBounds = new google.maps.LatLngBounds();
                polygonPath.forEach(point => polygonBounds.extend(point));
                const center = polygonBounds.getCenter();

                // Create label if provided
                if (result.label) {
                  const labelContent = document.createElement('div');
                  labelContent.className = 'rounded-lg text-white text-sm p-1';
                  labelContent.style.backgroundColor = color;
                  labelContent.textContent = result.label;
                  const label = new AdvancedMarkerElement({
                    position: center,
                    map: googleMapRef.current,
                    content: labelContent
                  });
                  // const label = new google.maps.AdvancedMarkerElement({
                  //   position: center,
                  //   map: googleMapRef.current,
                  //   label: {
                  //     text: result.label,
                  //     color: '#000000',
                  //     fontSize: '14px',
                  //     fontWeight: 'semibold'
                  //   },
                  //   icon: {
                  //     path: google.maps.SymbolPath.CIRCLE,
                  //     scale: 0
                  //   }
                  // });
                  labelsRef.current.push(label);
                }

                // Extend bounds to include all polygon points
                polygonPath.forEach(point => bounds.extend(point));
                hasValidMarkers = true;
              }
            } else if (type === 'marker' || !type) {
              // Create marker for default or explicit marker type
              const marker = new AdvancedMarkerElement({
                position: result.position,
                map: googleMapRef.current
              });
              markersRef.current.push(marker);
              bounds.extend(result.position);
              hasValidMarkers = true;
            }
          }
        });

        // Update map bounds only after all markers are added
        if (hasValidMarkers) {
          if (!onPolygonUpdate) {
            googleMapRef.current.fitBounds(bounds);
            // Add a small padding to ensure markers aren't right at the edges
            const listener = googleMapRef.current.addListener('idle', () => {
              google.maps.event.removeListener(listener);
              googleMapRef.current.setZoom(
                Math.min(googleMapRef.current.getZoom(), 15)
              );
            });
          }
        }
      }
    };

    initMap();
    // Clean up function to remove listener when component updates
    return () => {
      if (clickListener) {
        google.maps.event.removeListener(clickListener);
      }
    };
  }, [locations, zoom, onPolygonUpdate, isDrawing]);

  useEffect(() => {
    if (focusZoneLabel && googleMapRef.current) {
      const zone = locations.find(loc => loc.label === focusZoneLabel);
      if (zone?.points?.length) {
        const bounds = new google.maps.LatLngBounds();
        zone.points.forEach(point => bounds.extend(point));
        googleMapRef.current.fitBounds(bounds, {
          padding: 50 // Add some padding around the zone
        });
      }
    }
  }, [focusZoneLabel, locations]);

  useEffect(() => {
    setIsDrawing(drawing);
    if (!drawing) {
      cleanupDrawing();
    }
  }, [drawing]);

  // Function to clean up temporary drawing elements
  const cleanupDrawing = () => {
    drawingPointsRef.current = [];
    // Remove temporary polygons
    polygonsRef.current.forEach(polygon => {
      if (polygon.temporary) {
        // Add a flag for temporary polygons
        polygon.setMap(null);
      }
    });
    polygonsRef.current = polygonsRef.current.filter(
      polygon => !polygon.temporary
    );
  };

  if (!window.google?.maps) {
    return (
      <div
        className='relative flex items-center justify-center bg-gray-100 rounded-lg shadow-md'
        style={{
          width,
          height,
          cursor: isDrawing ? 'cursor-crosshair' : 'cursor-default'
        }}
      >
        <div className='text-gray-500 text-center p-4'>
          <div className='text-lg font-semibold'>Map Unavailable</div>
          <div className='text-sm'>Google Maps API not loaded</div>
        </div>
      </div>
    );
  }

  return (
    <div className='relative' style={{ width, height }}>
      <div
        ref={mapRef}
        style={{ width: '100%', height: '100%' }}
        className='rounded-lg shadow-md'
      />
    </div>
  );
};

export { Map };
