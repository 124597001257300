import React, { useState, useEffect } from 'react';
import { FilledButton, Input, ComboBox, Popup, DropdownInput } from 'core';
import UserProfile from '../../../models/UserProfile';
import { supabase } from '../../../utilities/supabase';
import { v4 as uuidv4 } from 'uuid';

const CreateAdmin = ({ isOpen, onClose, onSuccess }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    role: ''
  });
  const [inviteLink, setInviteLink] = useState('');
  const [permissionGroups, setPermissionGroups] = useState([]);

  useEffect(() => {
    const loadPermissionGroups = async () => {
      try {
        const { data, error } = await supabase
          .from('permission_groups')
          .select('*')
          .order('hierarchy');

        if (error) throw error;

        const groups = data.map(group => ({
          value: group.id,
          label: group.label
        }));
        setPermissionGroups(groups);
      } catch (err) {
        console.error('Failed to load permission groups:', err);
      }
    };

    loadPermissionGroups();
  }, []);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    try {
      // Generate auth code and expiry
      const authCode = uuidv4();
      const expiryTime = Math.floor(Date.now() / 1000) + 3600; // 1 hour from now

      // Create user profile
      const userProfile = new UserProfile({
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        bohsaAdmin: true,
        role: { id: formData.role },
        metrics: {
          signup: {
            code: authCode,
            expiry: expiryTime.toString()
          }
        }
      });

      const newProfile = await userProfile.insert();

      // Generate invite link
      const inviteLink = `${window.location.origin}/signup/${newProfile.id}?auth_code=${authCode}`;
      setInviteLink(inviteLink);

      if (onSuccess) {
        onSuccess(newProfile);
      }
    } catch (err) {
      console.error('Failed to create admin:', err);
    }
  };

  const footer = (
    <div className='flex justify-between items-center'>
      <FilledButton colour='base' onClick={onClose} className='mr-2'>
        Cancel
      </FilledButton>
      {!inviteLink ? (
        <FilledButton colour='primary' onClick={handleSubmit}>
          Create Admin
        </FilledButton>
      ) : (
        <FilledButton
          colour='primary'
          onClick={() => navigator.clipboard.writeText(inviteLink)}
        >
          Copy Invite Link
        </FilledButton>
      )}
    </div>
  );

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      title='Create New Admin'
      size='sm'
      footer={footer}
    >
      <div className='space-y-4'>
        {!inviteLink ? (
          <>
            <div className='flex flex-row space-x-4'>
              <Input
                label='First Name'
                name='firstName'
                value={formData.firstName}
                onChange={handleChange}
                required
              />

              <Input
                label='Last Name'
                name='lastName'
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>

            <Input
              label='Email'
              name='email'
              type='email'
              value={formData.email}
              onChange={handleChange}
              required
            />

            <DropdownInput
              label='Role'
              options={permissionGroups}
              value={formData.role}
              onChange={e =>
                handleChange({
                  target: {
                    name: 'role',
                    value: e.target.value
                  }
                })
              }
              placeholder='Select a role...'
              required
            />
          </>
        ) : (
          <div className='text-center'>
            <p className='mb-4 text-gray-600'>
              Admin user created successfully! Copy the invite link below:
            </p>
            <div className='p-4 bg-gray-50 rounded-lg break-all'>
              {inviteLink}
            </div>
          </div>
        )}
      </div>
    </Popup>
  );
};

export default CreateAdmin;
