import BaseModel from './BaseModel';
import { supabase } from '../utilities/supabase';

/**
 * Represents a Location in the system.
 * @extends BaseModel
 */
class Location extends BaseModel {
  /** @type {string} The name of the database table for Locations */
  static table = 'locations';

  /** @type {string} The SQL query to select Location data */
  static selectQuery =
    '*,location_type:core_entity_types!location_type(id, type),status:core_entity_types!status(id,type),full_address,geometry';

  /**
   * Creates a Location instance from database format data
   * @param {Object} data - The database format location data
   * @returns {Location} A new Location instance
   */
  static fromDatabase(data = {}) {
    const location = new Location();
    location.id = data.id;
    location.createdDate = data.created_date;
    location.updatedDate = data.updated_date;
    location.name = data.name;
    location.address1 = data.address_1;
    location.address2 = data.address_2;
    location.city = data.city;
    location.county = data.county;
    location.postcode = data.postcode;
    location.fullAddress = data.full_address;
    location.geometry = data.geometry;
    location.locationType = data.location_type
      ? {
          id: data.location_type.id || data.location_type,
          type: data.location_type?.type || data.location_type_name
        }
      : null;
    location.entityType = data.entity_type;
    location.entityId = data.entity_id;
    location.status = data.status
      ? {
          id: data.status.id || data.status,
          type: data.status?.type || data.status_name
        }
      : null;
    location.distance = data.distance;
    location.metadata = data.metadata || {};
    location.locationSubTypes = data.location_sub_types || [];
    return location;
  }

  /**
   * Creates an instance of Location.
   * @param {Object} data - The location data.
   */
  constructor(data = {}) {
    super(data);
    this.name = data.name ?? this.name ?? null;
    this.address1 = data.address1 ?? this.address1 ?? null;
    this.address2 = data.address2 ?? this.address2 ?? null;
    this.city = data.city ?? this.city ?? null;
    this.county = data.county ?? this.county ?? null;
    this.postcode = data.postcode ?? this.postcode ?? null;
    this.fullAddress = data.fullAddress ?? this.fullAddress ?? null;
    this.locationType = data.locationType ?? this.locationType ?? null;
    this.entityType = data.entityType ?? this.entityType ?? null;
    this.entityId = data.entityId ?? this.entityId ?? null;
    this.status = data.status ?? this.status ?? null;
    this.distance = data.distance ?? this.distance ?? null;
    this.metadata = data.metadata ?? this.metadata ?? {};
    this.locationSubTypes =
      data.locationSubTypes ?? this.locationSubTypes ?? [];
  }

  /**
   * Converts the Location instance to a database-friendly format.
   * @returns {Object} The location data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      name: this.name,
      address_1: this.address1,
      address_2: this.address2,
      city: this.city,
      county: this.county,
      postcode: this.postcode,
      location_type: this.locationType.id,
      entity_type: this.entityType,
      entity_id: this.entityId,
      status: this.status.id,
      metadata: this.metadata,
      location_sub_types: this.locationSubTypes
    };
  }

  /**
   * Get all records using a specific function with filtering, sorting, and pagination.
   * @param {Object} filters - The filters to apply.
   * @param {number} page - The page number.
   * @param {number} size - The page size.
   * @param {string} sortBy - The field to sort by.
   * @param {string} sortType - The sort type ('asc' or 'desc').
   * @returns {Promise<Object>} A promise that resolves to an object containing the data and pagination info.
   */
  static async getAllByFunction(
    filters = {},
    page = 1,
    size = 10,
    sortBy = 'id',
    sortType = 'asc'
  ) {
    try {
      const { lat, lng, courseId, ...remainingFilters } = filters;
      delete remainingFilters.lat;
      delete remainingFilters.lng;
      delete remainingFilters.courseId;

      let query = supabase.rpc(
        'all_locations_with_distance',
        {
          p_latitude: parseFloat(lat) ?? 53.0062031,
          p_longitude: parseFloat(lng) ?? -2.1650482,
          ...(courseId && { p_course_id: courseId })
        },
        { count: 'exact' }
      );

      query = this.filters(query, remainingFilters);
      query = this.sorting(query, sortBy, sortType);
      query = this.range(query, page, size);

      const { data, error, count } = await query;

      if (error) throw error;

      return {
        data: data.map(item => Location.fromDatabase(item)),
        count: count,
        page: page,
        size: size,
        total: Math.ceil(count / size)
      };
    } catch (error) {
      console.error(`[Error] Fetching All by Function ${this.name}: `, error);
      throw error;
    }
  }
}

export default Location;
