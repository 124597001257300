import React, { useState, useEffect } from 'react';
import { FilledButton, TextButton, Popup, Modal } from 'core';
import TabView from '../../navigation/TabView';
import Details from './Details';
import Comments from './Comments';
import { useNavigate, useParams } from 'react-router-dom';

const TaskPopup = ({ task: initialTask, onClose }) => {
  const [task, setTask] = useState(initialTask);
  const [editedTask, setEditedTask] = useState({ ...initialTask });
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [hasChildren, setHasChildren] = useState(false);
  const navigate = useNavigate();
  const { '*': wildcard } = useParams();

  useEffect(() => {
    if (!wildcard) {
      navigate(`details`, { replace: true });
    }
  }, [wildcard, navigate]);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const taskData = {
        ...editedTask,
        assignees: editedTask.assignees.map(assignee => assignee.value)
      };
      const updatedTask = await task.update(taskData);
      setTask(updatedTask);
      onClose();
    } catch (error) {
      console.error('Error updating task:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    // Check if task has children before showing delete modal
    try {
      const children = await task.getChildren();
      setHasChildren(children != null);
      setShowDeleteModal(true);
    } catch (error) {
      console.error('Error checking for child tasks:', error);
    }
  };

  const confirmDelete = async () => {
    try {
      await task.delete();
      setShowDeleteModal(false);
      onClose();
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const tabs = [
    {
      label: 'Details',
      path: `${task.id}/details`,
      content: <Details editedTask={editedTask} setEditedTask={setEditedTask} />
    },
    {
      label: 'Comments',
      labelBadge: task.comments > 0 ? task.comments : null,
      path: `${task.id}/comments`,
      content: <Comments taskId={task.id} />
    }
  ];

  const footer = (
    <div className='flex justify-end space-x-2'>
      <TextButton colour='danger' onClick={handleDelete}>
        Delete Task
      </TextButton>
      <TextButton colour='base' onClick={onClose}>
        Cancel
      </TextButton>
      <FilledButton colour='primary' onClick={handleSave} disabled={isLoading}>
        {isLoading ? 'Saving...' : 'Save'}
      </FilledButton>
    </div>
  );

  return (
    <>
      <Popup
        title={editedTask.title}
        onClose={onClose}
        isOpen={true}
        size='md'
        footer={footer}
      >
        <TabView
          tabs={tabs}
          baseUrl={`${task.id}`}
          fullWidth={true}
          default={'details'}
        />
      </Popup>

      <Modal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title='Delete Task'
        style='danger'
        actions={[
          {
            label: 'Cancel',
            onClick: () => setShowDeleteModal(false),
            style: 'base'
          },
          {
            label: 'Delete',
            onClick: confirmDelete,
            style: 'danger'
          }
        ]}
      >
        <p className='text-sm text-neutral-600'>
          {hasChildren
            ? 'This task has subtasks that will also be deleted. Are you sure you want to continue?'
            : 'Are you sure you want to delete this task?'}
        </p>
      </Modal>
    </>
  );
};

export default TaskPopup;
