import React, { useState, useEffect } from 'react';
import { supabase } from '../../../utilities/supabase';
import Popup from '../Popup';
import { LogoLoader } from 'core';
import StageOne from './StageOne';
import StageTwo from './StageTwo';

const SendSMS = ({ onClose }) => {
  const [stage, setStage] = useState(1);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchTemplates();
    fetchCategories();
  }, []);

  const fetchTemplates = async () => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase.functions.invoke(
        '/audience/sms/templates'
      );
      if (error) throw error;
      setTemplates(data);
    } catch (error) {
      console.error('Error fetching templates:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const { data, error } = await supabase.functions.invoke(
        'audience/sms/categories'
      );
      if (error) throw error;
      setCategories(
        data.map(category => ({ value: category.id, label: category.name }))
      );
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleTemplateSelect = async template => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase.functions.invoke(
        '/audience/sms/template',
        {
          body: {
            templateId: template.id
          }
        }
      );
      if (error) throw error;
      const selectedTemplate = data[0];
      setSelectedTemplate({ ...template, message: selectedTemplate.message });
      setStage(2);
    } catch (error) {
      console.error('Error fetching template message:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewSMS = () => {
    const defaultTemplate = templates.find(template => template.default);
    if (defaultTemplate) {
      handleTemplateSelect(defaultTemplate);
    }
  };

  const handleBack = () => {
    setStage(1);
    fetchTemplates();
  };

  const renderContent = () => (
    <>
      {isLoading ? (
        <div className='flex justify-center items-center h-96'>
          <LogoLoader logo={'/logo-star.png'} size='sm' selfContained={false} />
        </div>
      ) : (
        <>
          {stage === 1 && (
            <StageOne
              templates={templates}
              categories={categories}
              onTemplateSelect={handleTemplateSelect}
              onNewSMS={handleNewSMS}
            />
          )}

          {stage === 2 && selectedTemplate && (
            <StageTwo
              selectedTemplate={selectedTemplate}
              onBack={handleBack}
              onClose={onClose}
            />
          )}
        </>
      )}
    </>
  );

  return (
    <Popup
      onClose={onClose}
      title={stage === 1 ? 'Select SMS Template' : 'Compose SMS'}
    >
      {renderContent()}
    </Popup>
  );
};

export default SendSMS;
