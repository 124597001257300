import React from 'react';

const ProgressBar = ({
  progress,
  steps,
  colour = 'primary',
  type = 'continuous'
}) => {
  const progressPercentage =
    type === 'continuous' ? (progress / steps.length) * 100 : null;

  const colourClasses = {
    brand: 'bg-brand-600',
    primary: 'bg-primary-600',
    info: 'bg-info-600',
    success: 'bg-success-600',
    warning: 'bg-warning-600',
    danger: 'bg-danger-600'
  };

  const textColourClasses = {
    brand: 'text-brand-600',
    primary: 'text-primary-600',
    info: 'text-info-600',
    success: 'text-success-600',
    warning: 'text-warning-600',
    danger: 'text-danger-600'
  };

  const getColourClass = colour => {
    return colourClasses[colour] || `bg-${colour}-600`;
  };

  const getTextColourClass = colour => {
    return textColourClasses[colour] || `text-${colour}-600`;
  };

  return (
    <div>
      <div aria-hidden='true'>
        <div className='overflow-hidden rounded-full'>
          {type === 'continuous' ? (
            <div className='bg-base-200'>
              <div
                style={{ width: `${progressPercentage}%` }}
                className={`h-2 rounded-full ${getColourClass(colour)}`}
              />
            </div>
          ) : (
            <div
              className='grid h-2'
              style={{
                gridTemplateColumns: `repeat(${steps.length}, minmax(0, 1fr))`
              }}
            >
              {steps.map((step, index) => {
                const isComplete = progress[index];
                return (
                  <div
                    key={index}
                    className={`${
                      isComplete ? getColourClass(colour) : 'bg-base-300'
                    } rounded-full mx-4`}
                  />
                );
              })}
            </div>
          )}
        </div>
        <div className='mt-2'>
          <div
            className='hidden md:grid text-sm font-medium text-base-600 text-center'
            style={{
              gridTemplateColumns: `repeat(${steps.length}, minmax(0, 1fr))`
            }}
          >
            {steps.map((step, index) => (
              <div
                key={index}
                className={`text-center ${
                  type === 'continuous'
                    ? index < progress
                      ? getTextColourClass(colour)
                      : ''
                    : progress[index]
                    ? getTextColourClass(colour)
                    : ''
                }`}
              >
                {step.label}
              </div>
            ))}
          </div>
          <div className='md:hidden text-sm font-medium text-base-600 text-center'>
            <div className={getTextColourClass(colour)}>
              {type === 'continuous' && steps[progress - 1]
                ? steps[progress - 1].label
                : ''}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ProgressBar };
