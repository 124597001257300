import React, { useEffect, useState } from 'react';
import { ComboBox, MultiSelectBox } from 'core';
import Company from '../../../../models/Company';
import UserProfile from '../../../../models/UserProfile';
import { FilterOperatorEnum } from '../../../../utilities/Enumerables';

const QuoteStep1 = ({ quoteData, handleInputChange, companyId }) => {
  const loadCompanyOptions = async inputValue => {
    try {
      const { data: companies } = await Company.getAll({
        name: {
          operator: FilterOperatorEnum.ILIKE,
          value: inputValue
        }
      });

      return companies.map(company => ({
        value: company.id,
        label: company.name
      }));
    } catch (error) {
      console.error('Error loading companies:', error);
      return [];
    }
  };

  const loadBookerOptions = async searchQuery => {
    if (!quoteData.company?.value) return [];

    try {
      const { data: bookers } = await UserProfile.getAll({
        company_id: {
          operator: 'eq',
          value: quoteData.company.value
        },
        fts: {
          operator: FilterOperatorEnum.TEXTSEARCH,
          value: searchQuery
        }
      });

      return bookers.map(booker => ({
        value: booker.id,
        label: `${booker.firstName} ${booker.lastName}`,
        secondaryLabel: booker.email
      }));
    } catch (error) {
      console.error('Error loading bookers:', error);
      return [];
    }
  };

  return (
    <div className='space-y-6'>
      <div>
        <ComboBox
          value={quoteData.company}
          onChange={value => handleInputChange('company', value)}
          loadOptions={loadCompanyOptions}
          disabled={!!companyId}
          placeholder='Select or search for a company...'
          className='w-full'
          required
          label='Company'
        />
      </div>

      {quoteData.company && (
        <div>
          <MultiSelectBox
            loadOptions={loadBookerOptions}
            onChange={value => handleInputChange('bookers', value)}
            selectedOptions={quoteData.bookers}
            disabled={!quoteData.company}
            required
            placeholder='Search for bookers...'
            className='w-full'
            label='Bookers'
            hint='Select the contacts who can book this quote'
          />
        </div>
      )}
    </div>
  );
};

export default QuoteStep1;
