import React, { useState } from 'react';
import { Pencil, SquareArrowOutUpRight, Trash, Shield } from 'lucide-react';
import UserProfile from '../../../../models/UserProfile';
import EntityTable from '../../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import { formatDate } from '../../../../utilities/Formatting';
import CreateUser from '../../../../components/popups/user/CreateUser';
import { Tooltip } from 'core';

const Users = ({ companyId }) => {
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/user-profiles/${data.displayId}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data =>
        (window.location.href = `/user-profiles/${data.displayId}`)
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  const handleNewClick = () => {
    setIsAddUserOpen(true);
  };

  const handleCloseAddUser = () => {
    setIsAddUserOpen(false);
  };

  const handleUserAdded = newUser => {
    console.log('New user added:', newUser);
  };

  return (
    <>
      <EntityTable
        name='Users'
        filter={[
          { field: 'display_id', label: 'ID', type: 'number' },
          {
            field: 'first_name',
            label: 'First Name',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'last_name',
            label: 'Last Name',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'email',
            label: 'Email',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'displayId',
            cell: (value, row) => {
              return (
                <div className='flex items-center gap-2'>
                  {value}
                  {row.companyAdmin && (
                    <Tooltip content='Company Administrator' size='sm'>
                      <Shield className='w-4 h-4 text-info-500' />
                    </Tooltip>
                  )}
                </div>
              );
            }
          },
          { label: 'First Name', field: 'firstName' },
          { label: 'Last Name', field: 'lastName' },
          { label: 'Email', field: 'email' },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={UserProfile}
        entityType={EntityTypeEnum.User}
        parentContext={{ type: 'company' }}
        sortDefault={{ field: 'display_id', order: 'asc' }}
        defaultFilters={{
          company_id: { value: companyId }
        }}
        onNewClick={handleNewClick}
        canCreate={true}
      />
      <CreateUser
        isOpen={isAddUserOpen}
        onClose={handleCloseAddUser}
        onSuccess={handleUserAdded}
        companyId={companyId}
      />
    </>
  );
};

export default Users;
