import React, { useCallback } from 'react';
import { UserRound } from 'lucide-react';

/**
 * UserProfilePicture Component
 *
 * @param {string} [props.firstName] - The user's first name
 * @param {string} [props.lastName] - The user's last name
 * @param {string} [props.profilePicture] - URL of the user's profile picture
 * @param {string} [props.size='md'] - The size of the profile picture ('xs', 'sm', 'md', 'lg', 'xl')
 * @returns {React.ReactElement} An image or icon representing the user's profile picture
 */
const UserProfilePicture = ({
  firstName,
  lastName,
  profilePicture,
  size = 'md'
}) => {
  const sizeClasses = {
    xs: 'h-4 w-4 text-[8px]',
    sm: 'h-5 w-5 text-[10px]',
    md: 'h-6 w-6 text-[12px]',
    lg: 'h-8 w-8 text-sm',
    xl: 'h-10 w-10 text-base'
  };

  const iconSizes = {
    xs: 10,
    sm: 12,
    md: 14,
    lg: 16,
    xl: 20
  };

  const getRandomColor = useCallback(() => {
    const colors = [
      'bg-blue-100',
      'bg-green-100',
      'bg-yellow-100',
      'bg-red-100',
      'bg-purple-100',
      'bg-pink-100',
      'bg-indigo-100'
    ];
    // Use a hash of the name to get a consistent color for each user
    const nameStr = `${firstName || ''}${lastName || ''}`;
    const hash = nameStr.split('').reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
    return colors[Math.abs(hash) % colors.length];
  }, []);

  const getSizeClass = useCallback(
    () => sizeClasses[size] || sizeClasses.md,
    [size]
  );

  const renderProfilePicture = useCallback(() => {
    if (profilePicture) {
      return (
        <img
          src={profilePicture}
          alt={`${firstName} ${lastName}`}
          className={`${getSizeClass()} rounded-full flex-none bg-neutral-100`}
        />
      );
    }

    if (firstName && lastName) {
      const initials = (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
      return (
        <div
          className={`${getSizeClass()} ${getRandomColor()} rounded-full flex items-center justify-center font-medium text-base-600 p-0.5`}
        >
          {initials}
        </div>
      );
    }

    return (
      <div
        className={`${getSizeClass()} ${getRandomColor()} rounded-full flex items-center justify-center`}
      >
        <UserRound size={iconSizes[size]} className='text-base-600' />
      </div>
    );
  }, [profilePicture, firstName, lastName, getSizeClass, getRandomColor, size]);

  return renderProfilePicture();
};

export { UserProfilePicture };
