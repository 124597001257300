import React, { useState, useEffect } from 'react';
import { Input } from 'core';
import {
  determineLinkDisplay,
  determineLinkType
} from '../../../../utilities/Formatting';
import Company from '../../../../models/Company';
import { FilterOperatorEnum } from '../../../../utilities/Enumerables';
import { TriangleAlert, CheckCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import { supabase } from '../../../../utilities/supabase';

const BasicDetails = ({ companyData, handleInputChange }) => {
  const [matchingCompanies, setMatchingCompanies] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    const searchCompanies = async () => {
      if (companyData.name && companyData.name.length >= 3) {
        setIsSearching(true);
        try {
          const { data: results } = await supabase.rpc(
            'search_similar_companies',
            {
              p_name: companyData.name,
              p_threshold: 0.2
            }
          );
          setMatchingCompanies(results);
        } catch (error) {
          console.error('Error searching companies:', error);
        }
        setIsSearching(false);
      } else {
        setMatchingCompanies([]);
      }
    };

    const debounceTimer = setTimeout(searchCompanies, 300);
    return () => clearTimeout(debounceTimer);
  }, [companyData.name]);

  const handleLinkChange = (field, value) => {
    // Format links array for company model
    const existingLinks = Array.isArray(companyData.links)
      ? companyData.links
      : [];
    const linkIndex = existingLinks.findIndex(
      link => link.type === field.charAt(0).toUpperCase() + field.slice(1)
    );

    const fullUrl = value.startsWith('http') ? value : `https://${value}`;
    const newLink = {
      id: Date.now(),
      url: fullUrl,
      display: determineLinkDisplay(fullUrl),
      type: determineLinkType(fullUrl)
    };

    let updatedLinks;
    if (linkIndex >= 0) {
      updatedLinks = [
        ...existingLinks.slice(0, linkIndex),
        newLink,
        ...existingLinks.slice(linkIndex + 1)
      ];
    } else {
      updatedLinks = [...existingLinks, newLink];
    }

    handleInputChange('links', updatedLinks);
  };

  const getLinkByType = type => {
    if (!Array.isArray(companyData.links)) return '';
    const link = companyData.links.find(l => l.type === type);
    return link ? link.url : '';
  };

  return (
    <div className='space-y-4'>
      <div>
        <Input
          label='Company Name'
          value={companyData.name}
          onChange={e => handleInputChange('name', e.target.value)}
          required
          placeholder='New Company Ltd'
        />
        {isSearching && (
          <div className='mt-2 text-base-500'>Searching for matches...</div>
        )}
        {matchingCompanies.length > 0 ? (
          <div className='mt-4 p-5 bg-warning-50/80 border-l-4 border-warning-400 rounded-lg shadow-sm'>
            <div className='flex items-center gap-2 mb-3'>
              <TriangleAlert className='h-5 w-5 text-warning-500' />
              <h4 className='font-semibold text-warning-800'>
                Possible duplicate companies found
              </h4>
            </div>
            <p className='text-warning-600 text-sm mb-4'>
              We found existing companies that are similar to the company name
              you are trying to create. Please ensure you are not creating a
              duplicate company record.
            </p>
            <ul className='space-y-2 pl-7'>
              {matchingCompanies.map(company => (
                <Link
                  key={company.id}
                  to={`/companies/${company.id}`}
                  className='text-warning-700 text-sm hover:text-warning-800 transition-colors duration-200 cursor-pointer flex items-center gap-2'
                >
                  <span className='w-1.5 h-1.5 rounded-full bg-warning-400'></span>
                  {company.name} (#{company.id})
                </Link>
              ))}
            </ul>
          </div>
        ) : (
          companyData.name && (
            <div className='mt-4 p-5 bg-success-50/80 border-l-4 border-success-400 rounded-lg shadow-sm'>
              <div className='flex items-center gap-2 mb-3'>
                <CheckCircle className='h-5 w-5 text-success-500' />
                <h4 className='font-semibold text-success-800'>
                  No duplicate companies found
                </h4>
              </div>
              <p className='text-success-600 text-sm'>
                You can proceed with creating this company. No similar company
                names were found.
              </p>
            </div>
          )
        )}
      </div>
      <Input
        label='Website'
        value={getLinkByType('Website')}
        onChange={e => handleLinkChange('website', e.target.value)}
        placeholder='https://www.example.com'
      />
      <Input
        label='LinkedIn'
        value={getLinkByType('Linkedin')}
        onChange={e => handleLinkChange('linkedin', e.target.value)}
        placeholder='https://www.linkedin.com/company/example'
      />
      <Input
        label='X'
        value={getLinkByType('X')}
        onChange={e => handleLinkChange('x', e.target.value)}
        placeholder='https://x.com/example'
      />
      <Input
        label='Facebook'
        value={getLinkByType('Facebook')}
        onChange={e => handleLinkChange('facebook', e.target.value)}
        placeholder='https://facebook.com/example'
      />
    </div>
  );
};

export default BasicDetails;
