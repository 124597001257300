import React, { useState, useEffect, useContext } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { UserProfileContext } from '../../App';

const Accordion = ({
  items,
  data,
  showTopBorder = true,
  singleOpen = false,
  renderContent,
  onUpdate
}) => {
  const { userProfile: currentUser } = useContext(UserProfileContext);
  const accordionKey = `accordion-${items.map(item => item.title).join('-')}`;
  const [openIndexes, setOpenIndexes] = useState(() => {
    return currentUser.crmPreferences?.accordions?.[accordionKey] || [];
  });

  useEffect(() => {
    const updatePreferences = async () => {
      try {
        const updatedPreferences = {
          ...currentUser.crmPreferences,
          accordions: {
            ...currentUser.crmPreferences?.accordions,
            [accordionKey]: openIndexes
          }
        };

        await currentUser.update({ crmPreferences: updatedPreferences });
      } catch (error) {
        console.error('Error updating user preferences:', error);
      }
    };

    updatePreferences();
  }, [openIndexes, accordionKey, currentUser]);

  const toggleItem = index => {
    if (singleOpen) {
      setOpenIndexes(openIndexes.includes(index) ? [] : [index]);
    } else {
      setOpenIndexes(prevIndexes =>
        prevIndexes.includes(index)
          ? prevIndexes.filter(i => i !== index)
          : [...prevIndexes, index]
      );
    }
  };

  return (
    <div className={`${showTopBorder ? 'border-t border-gray-200' : ''}`}>
      {items.map((item, index) => (
        <div key={index} className='border-b border-gray-200'>
          <button
            className='flex justify-between items-center w-full py-2 px-4 text-left'
            onClick={() => toggleItem(index)}
          >
            <span className='font-medium'>{item.title}</span>
            {openIndexes.includes(index) ? (
              <ChevronUp size={20} />
            ) : (
              <ChevronDown size={20} />
            )}
          </button>
          {openIndexes.includes(index) && (
            <div className='py-2 px-4'>
              {renderContent
                ? renderContent(item, onUpdate)
                : typeof item.content === 'function'
                ? item.content({ data, onUpdate })
                : item.content}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
