import React, { useState, useEffect } from 'react';
import { supabase } from '../../utilities/supabase';
import UserProfile from '../../models/UserProfile';
import { UserRound, Save, Trash, Upload } from 'lucide-react';
import { LogoLoader, FilledButton, OutlineButton, TextButton } from 'core';
import { PrimaryInput, DropdownInput } from '../../components/inputs/Forms';
import { useNotification } from 'core';
import axios from 'axios';

const Personal = () => {
  const [profile, setProfile] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phones, setPhones] = useState([]);
  const [profileImage, setProfileImage] = useState(null);
  const { addNotification } = useNotification();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const {
          data: { user }
        } = await supabase.auth.getUser();
        if (user) {
          const { data } = await UserProfile.getByAuthId(user.id, true);
          const userProfile = data[0];
          setProfile(userProfile);
          setFirstName(userProfile.firstName || '');
          setLastName(userProfile.lastName || '');
          setPhones(userProfile.phones || []);
          setProfileImage(userProfile.profilePhoto || null);
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
        addNotification({
          type: 'error',
          title: 'Error',
          description: 'Failed to fetch profile'
        });
      }
    };

    fetchUserProfile();
  }, [addNotification]);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const updatedProfile = new UserProfile({
        ...profile,
        firstName,
        lastName,
        phones
      });

      await updatedProfile.update();

      addNotification({
        type: 'success',
        title: 'Profile updated',
        description: 'Changes saved successfully'
      });
    } catch (error) {
      console.error('Error updating profile:', error);
      addNotification({
        type: 'error',
        title: 'Error',
        description: 'Failed to update profile'
      });
    }
  };

  const handlePhoneChange = async (index, field, value) => {
    try {
      const updatedPhones = phones.map((phone, i) =>
        i === index ? { ...phone, [field]: value } : phone
      );
      setPhones(updatedPhones);

      const updatedProfile = new UserProfile({
        ...profile,
        phones: updatedPhones
      });
      await updatedProfile.update();
    } catch (error) {
      console.error('Error updating phones:', error);
      addNotification({
        type: 'error',
        title: 'Error',
        description: 'Failed to update phone numbers'
      });
    }
  };

  const addPhone = () => {
    setPhones([...phones, { type: '', number: '', country_code: '' }]);
  };

  const removePhone = async index => {
    try {
      const updatedPhones = phones.filter((_, i) => i !== index);
      setPhones(updatedPhones);

      const updatedProfile = new UserProfile({
        ...profile,
        phones: updatedPhones
      });
      await updatedProfile.update();
    } catch (error) {
      console.error('Error removing phone:', error);
      addNotification({
        type: 'error',
        title: 'Error',
        description: 'Failed to remove phone number'
      });
    }
  };

  const handleImageUpload = async e => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 1024 * 1024) {
        // 1MB in bytes
        addNotification({
          title: 'Error',
          description: 'Image file size must be less than 1MB',
          type: 'error'
        });
        return;
      }

      const uploadNotificationId = addNotification({
        title: 'Uploading profile photo...',
        description: '0%',
        type: 'info',
        duration: null
      });

      try {
        const { data: signedUrlData, error: signedUrlError } =
          await supabase.functions.invoke('storage/getSigned/upload', {
            body: {
              fileName: `profile-${profile.id}`,
              originalFileName: file.name,
              destinationFolder: 'profile-photos'
            }
          });

        if (signedUrlError) throw signedUrlError;

        await axios.put(signedUrlData.url, file, {
          headers: {
            'Content-Type': file.type
          },
          onUploadProgress: progressEvent => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            addNotification({
              id: uploadNotificationId,
              title: 'Uploading profile photo...',
              description: `${percentCompleted}%`,
              type: 'info',
              duration: null
            });
          }
        });

        const STORAGE_BASE_URL = 'https://storage.googleapis.com/bohsa-docs/';
        const newPhotoUrl = STORAGE_BASE_URL + signedUrlData.file.key;
        setProfileImage(newPhotoUrl);

        const updatedProfile = new UserProfile({
          ...profile,
          profilePhoto: newPhotoUrl
        });
        await updatedProfile.update();

        addNotification({
          id: uploadNotificationId,
          title: 'Success',
          description: 'Profile photo uploaded successfully',
          type: 'success'
        });
      } catch (error) {
        console.error('Error uploading profile photo:', error);
        addNotification({
          id: uploadNotificationId,
          title: 'Error',
          description: 'Failed to upload profile photo',
          type: 'error'
        });
      }
    }
  };

  const handleRemovePhoto = async () => {
    try {
      const updatedProfile = new UserProfile({
        ...profile,
        profilePhoto: null
      });
      await updatedProfile.update();
      setProfileImage(null);

      addNotification({
        title: 'Success',
        description: 'Profile photo removed successfully',
        type: 'success'
      });
    } catch (error) {
      console.error('Error removing profile photo:', error);
      addNotification({
        title: 'Error',
        description: 'Failed to remove profile photo',
        type: 'error'
      });
    }
  };

  if (!profile) {
    return <LogoLoader logo={'/logo-star.png'} />;
  }

  const phoneTypeOptions = [
    { value: 'mobile', label: 'Mobile' },
    { value: 'home', label: 'Home' },
    { value: 'work', label: 'Work' },
    { value: 'other', label: 'Other' }
  ];

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className='bg-white shadow-sm ring-1 ring-gray-900/5 rounded-xl md:col-span-2'
      >
        <div className='px-4 py-6 sm:p-8'>
          <div className='grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10'>
            <div className='col-span-full'>
              <label
                htmlFor='photo'
                className='block text-sm/6 font-medium text-gray-900'
              >
                Photo
              </label>
              <div className='mt-2 flex items-center gap-x-8'>
                <div className='relative w-24 h-24 rounded-full overflow-hidden'>
                  {profileImage ? (
                    <img
                      src={profileImage}
                      alt='Profile'
                      className='w-full h-full object-cover'
                    />
                  ) : (
                    <div className='w-full h-full bg-gray-200 flex items-center justify-center'>
                      <Upload size={24} className='text-gray-400' />
                    </div>
                  )}
                </div>
                <div className='flex flex-col gap-2'>
                  <TextButton
                    onClick={() =>
                      document.querySelector('input[type="file"]').click()
                    }
                    colour='base'
                    className='text-sm'
                  >
                    <input
                      type='file'
                      accept='image/*'
                      className='hidden'
                      onChange={handleImageUpload}
                    />
                    Change Photo
                  </TextButton>
                  {profileImage && (
                    <TextButton
                      onClick={handleRemovePhoto}
                      className='text-sm'
                      colour='base'
                    >
                      Remove Photo
                    </TextButton>
                  )}
                </div>
              </div>
            </div>

            <div className='grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2'>
              <div className='col-span-full flex gap-x-6'>
                <div className='flex-1'>
                  <label
                    htmlFor='firstName'
                    className='block text-sm/6 font-medium text-gray-900'
                  >
                    First name
                  </label>
                  <div className='mt-2'>
                    <PrimaryInput
                      id='firstName'
                      name='firstName'
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                    />
                  </div>
                </div>

                <div className='flex-1'>
                  <label
                    htmlFor='lastName'
                    className='block text-sm/6 font-medium text-gray-900'
                  >
                    Last name
                  </label>
                  <div className='mt-2'>
                    <PrimaryInput
                      id='lastName'
                      name='lastName'
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='col-span-full'>
              <h3 className='text-sm font-medium text-gray-900 mb-4'>
                Phone Numbers
              </h3>
              <div className='space-y-4'>
                {phones.map((phone, index) => (
                  <div key={index} className='flex items-center gap-x-4'>
                    <DropdownInput
                      id={`phoneType-${index}`}
                      name={`phoneType-${index}`}
                      value={phone.type}
                      onChange={e =>
                        handlePhoneChange(index, 'type', e.target.value)
                      }
                      options={phoneTypeOptions}
                      className='min-w-24'
                    />
                    <PrimaryInput
                      id={`phoneNumber-${index}`}
                      name={`phoneNumber-${index}`}
                      value={phone.number}
                      onChange={e =>
                        handlePhoneChange(index, 'number', e.target.value)
                      }
                    />
                    <TextButton
                      colour='danger'
                      size='lg'
                      onClick={() => removePhone(index)}
                    >
                      <Trash />
                    </TextButton>
                  </div>
                ))}
                <OutlineButton colour='base' size='md' onClick={addPhone}>
                  Add Phone
                </OutlineButton>
              </div>
            </div>
          </div>
        </div>

        <div className='px-4 py-4 sm:px-8 flex justify-end border-t border-gray-900/10'>
          <FilledButton type='submit' leftIcon={<Save />}>
            Save Changes
          </FilledButton>
        </div>
      </form>
    </>
  );
};

export default Personal;
