import React, { useState, useEffect } from 'react';
import { Popup, FilledButton, TextSkeleton, LogoLoader } from 'core';
import QuoteOption from '../../../models/QuoteOption';
import CoreEntityType from '../../../models/CoreEntityType';
import Trainer from '../../../models/Trainer';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import Location from '../../../models/Location';
import { useParams, useNavigate } from 'react-router-dom';
import Quote from '../../../models/Quote';
import { supabase } from '../../../utilities/supabase';
import TabView from '../../navigation/TabView';
import Details from './Details';
import Comments from './Comments';

const EditQuoteOption = ({ isOpen, onClose, quoteOptionId }) => {
  const { id: quoteId } = useParams();
  const navigate = useNavigate();
  const { '*': wildcard } = useParams();
  const [quoteOption, setQuoteOption] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [optionStatuses, setOptionStatuses] = useState([]);
  const [selectedTrainer, setSelectedTrainer] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [parentQuote, setParentQuote] = useState(null);
  const [isBooked, setIsBooked] = useState(false);

  const [discountValue, setDiscountValue] = useState(0);
  const [total, setTotal] = useState(0);
  const [profit, setProfit] = useState(0);
  const [profitPct, setProfitPct] = useState(0);

  const [formData, setFormData] = useState({
    trainerId: null,
    courseDelivery: null,
    locationId: null,
    quoteValue: null,
    cost: null,
    discountPercentage: 0,
    status: null,
    courseId: null,
    courseDuration: null,
    obfuscatedLocation: ''
  });

  useEffect(() => {
    if (!wildcard) {
      navigate(`details`, { replace: true });
    }
  }, [wildcard, navigate]);

  useEffect(() => {
    if (!isOpen) return;

    const loadData = async () => {
      try {
        setLoading(true);

        // Load all data in parallel using Promise.all
        const [
          deliveryTypeData,
          statusOptionsData,
          parentQuoteData,
          quoteOptionData
        ] = await Promise.all([
          // Load delivery types
          CoreEntityType.getAll({
            entity_id: { operator: 'eq', value: EntityTypeEnum.CourseDelivery }
          }),
          // Load status options
          CoreEntityType.getAll({
            entity_id: { value: EntityTypeEnum.QuoteOptionStatus }
          }),
          // Load the parent quote
          Quote.getById(quoteId),
          // Load quote option if editing
          quoteOptionId ? QuoteOption.getById(quoteOptionId) : null
        ]);

        // Set delivery types
        setDeliveryTypes(
          deliveryTypeData.data.map(type => ({
            value: type.id,
            label: type.type
          }))
        );

        // Set status options
        setOptionStatuses(
          statusOptionsData.data
            .sort((a, b) => a.metadata?.order - b.metadata?.order)
            .map(status => ({
              value: status.id,
              label: status.type
            }))
        );

        // Set parent quote
        setParentQuote(parentQuoteData);

        if (quoteOptionId && quoteOptionData) {
          // Set quote option data
          setQuoteOption(quoteOptionData);
          setIsBooked(quoteOptionData.status?.id === 256);

          // Set trainer
          setSelectedTrainer({
            value: quoteOptionData.trainer.id,
            label: quoteOptionData.trainer.name
          });

          // Set location if exists
          if (quoteOptionData.location) {
            setSelectedLocation({
              value: quoteOptionData.location.id,
              label: quoteOptionData.location.name,
              secondaryLabel: quoteOptionData.location.fullAddress
            });
          }

          // Set course if exists
          if (quoteOptionData.course) {
            setSelectedCourse({
              value: quoteOptionData.course.id,
              label: quoteOptionData.course.name
            });
          }

          // Set form data
          const initialFormData = {
            trainerId: quoteOptionData.trainerId || null,
            courseDelivery: quoteOptionData.courseDelivery?.id || null,
            locationId: quoteOptionData.location?.id || null,
            clientPo: quoteOptionData.clientPo || null,
            quoteValue: quoteOptionData.quoteValue || 0,
            cost: quoteOptionData.cost || 0,
            discountPercentage: quoteOptionData.discountPercentage || 0,
            status: quoteOptionData.status?.id || 202,
            quoteId: quoteOptionData.quoteId || quoteId,
            courseId: quoteOptionData.course?.id || null,
            courseDuration: quoteOptionData.courseDuration || null,
            obfuscatedLocation: quoteOptionData.obfuscatedLocation || ''
          };
          setFormData(initialFormData);
          calculateValues(initialFormData);
        } else {
          // Set initial state for new quote option
          setQuoteOption(null);
          setSelectedTrainer(null);
          setSelectedLocation(null);
          setSelectedCourse(null);
          setFormData({
            trainerId: null,
            courseDelivery: null,
            locationId: null,
            clientPo: '',
            discountPercentage: 0,
            status: null,
            quoteId,
            courseId: null,
            courseDuration: null,
            obfuscatedLocation: ''
          });
          setDiscountValue(0);
          setTotal(0);
          setProfit(0);
          setIsBooked(false);
        }
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [quoteOptionId, isOpen, quoteId]);

  const loadTrainerOptions = async (searchTerm, page = 1) => {
    const { data: trainers } = await Trainer.getAll(
      {
        fts: {
          operator: FilterOperatorEnum.TEXTSEARCH,
          value: searchTerm
        }
      },
      page
    );

    return trainers.map(trainer => ({
      value: trainer.id,
      label: trainer.name
    }));
  };

  const loadLocationOptions = async (searchTerm, page = 1) => {
    const { data: locations } = await Location.getAll(
      {
        fts: {
          operator: FilterOperatorEnum.TEXTSEARCH,
          value: searchTerm
        },
        entity_type: { value: EntityTypeEnum.Trainer },
        entity_id: { value: quoteOption?.trainerId ?? formData?.trainerId }
      },
      page
    );

    return locations.map(location => ({
      value: location.id,
      label: location.name ?? location.address1,
      secondaryLabel: location.fullAddress
    }));
  };

  const loadCourseOptions = async (searchTerm, page = 1) => {
    if (searchTerm == '') {
      searchTerm = parentQuote.courseName || '';
    }
    const { data: courses } = await supabase.rpc('fuzzy_search_courses', {
      p_name: searchTerm.replaceAll(' ', '+'),
      p_threshold: 0.1
    });

    return courses.map(course => ({
      value: course.id,
      label: course.name
    }));
  };

  const calculateValues = newFormData => {
    const quoted = parseFloat(newFormData.quoteValue) || 0;
    const cost = parseFloat(newFormData.cost) || 0;
    const discountPercent = parseFloat(newFormData.discountPercentage) || 0;

    const newDiscountValue = cost * (discountPercent / 100);
    const newTotal = cost - newDiscountValue;
    const newProfit = quoted - newTotal;
    const newProfitPct = ((quoted - newTotal) / quoted) * 100;

    setDiscountValue(newDiscountValue);
    setTotal(newTotal);
    setProfit(newProfit);
    setProfitPct(newProfitPct);
  };

  const handleSave = async () => {
    try {
      const updatedQuoteOption = new QuoteOption({
        id: quoteOptionId,
        status: { id: formData.status },
        courseDelivery: { id: formData.courseDelivery },
        trainerId: formData.trainerId,
        quoteValue: parseFloat(formData.quoteValue),
        cost: parseFloat(formData.cost),
        discountPercentage: parseFloat(formData.discountPercentage),
        discountValue: parseFloat(discountValue),
        total: parseFloat(total),
        quoteId: quoteId,
        locationId: formData.locationId,
        courseId: formData.courseId,
        courseDuration: formData.courseDuration,
        obfuscatedLocation: formData.obfuscatedLocation
      });

      if (quoteOptionId) {
        await updatedQuoteOption.update();
      } else {
        await updatedQuoteOption.insert();
      }

      setFormData({
        trainerId: '',
        courseDelivery: '',
        locationId: '',
        clientPo: '',
        quoteValue: 0,
        cost: 0,
        discountPercentage: 0,
        status: '',
        courseId: '',
        courseDuration: '',
        obfuscatedLocation: ''
      });
      onClose();
    } catch (error) {
      console.error('Error saving quote option:', error);
    }
  };

  const tabs = [
    {
      label: 'Details',
      path: `${quoteOptionId}/details`,
      content: (
        <Details
          editedQuoteOption={formData}
          setEditedQuoteOption={setFormData}
          loading={loading}
          deliveryTypes={deliveryTypes}
          optionStatuses={optionStatuses}
          selectedTrainer={selectedTrainer}
          setSelectedTrainer={setSelectedTrainer}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          selectedCourse={selectedCourse}
          setSelectedCourse={setSelectedCourse}
          discountValue={discountValue}
          total={total}
          profit={profit}
          profitPct={profitPct}
          loadTrainerOptions={loadTrainerOptions}
          loadLocationOptions={loadLocationOptions}
          loadCourseOptions={loadCourseOptions}
          calculateValues={calculateValues}
          disabled={isBooked}
        />
      )
    },
    {
      label: 'Comments',
      labelBadge: quoteOption?.comments != null ? quoteOption?.comments : null,
      path: `${quoteOptionId}/comments`,
      content: <Comments quoteOptionId={quoteOptionId} />
    }
  ];

  const footer = (
    <div className='flex justify-end gap-2'>
      <FilledButton onClick={onClose} colour='base'>
        Cancel
      </FilledButton>
      {!isBooked && (
        <FilledButton
          onClick={handleSave}
          colour='primary'
          disabled={
            !formData.status ||
            !formData.trainerId ||
            !formData.courseDelivery ||
            !formData.quoteValue ||
            !formData.cost ||
            !formData.discountPercentage ||
            !formData.courseId ||
            !formData.courseDuration
          }
        >
          Save Changes
        </FilledButton>
      )}
    </div>
  );

  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      title={quoteOptionId ? 'Edit Quote Option' : 'New Quote Option'}
      footer={footer}
    >
      {loading ? (
        <div className=''>
          <LogoLoader logo={'/logo-star.png'} selfContained={false} size='sm' />
        </div>
      ) : (
        <TabView
          tabs={tabs}
          baseUrl={`${quoteOptionId}`}
          fullWidth={true}
          default={'details'}
        />
      )}
    </Popup>
  );
};

export default EditQuoteOption;
